import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Helmet from "react-helmet";
import {
  Divider as MuiDivider,
  Grid,
  Typography,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { spacing } from "@material-ui/system";
import { useSelector, useDispatch } from "react-redux";
import TabPanel from "../../components/TabPanel/TabPanel";

import RangeDetail from "../../components/Ranges/RangeDetail";
import RangeBalance from "../../components/Ranges/RangeBalance";
import { fetchRange } from "../../redux/actions/rangeActions";
import { fetchRangeProducts } from "../../redux/actions/rangeProductActions";
import { fetchProducts } from "../../redux/actions/productActions";
import RangeDashboard from "../../components/Ranges/RangeDashboard";
import RangeProductsRealMode from "../../components/Products/RangeProductsRealMode";
import PreviewRange from "../../components/Ranges/PreviewRange";

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: "24px",
    marginBottom: "24px",
  },
  tabBar: {
    backgroundColor: "White",
    color: "Black",
  },
  tabContainer: {
    maxWidth: "1600px",
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  widthContainerTable: {},
  rangeFeedback: {
    textAlign: "center",
    "& .MuiCardContent-root": {
      padding: "22px",
    },
    "& textarea": {
      border: "none",
      borderTop: "solid 2px",
      borderBottom: "solid 2px",
    },
    "& .MuiToolbar-root": {
      backgroundColor: "white",
    },
    "& .MuiIconButton-root": {
      borderRadius: "0px",
    },
  },
}));

const ShowRange = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { rangeId } = useParams();
  const auth = useSelector((state) => state.firebase.auth);
  const currentProject = useSelector((state) => state.projects.currentProject);
  const user = auth.stsTokenManager;
  const dispatch = useDispatch();
  const range = useSelector((state) => state.ranges.range);

  const [valueTab, setValueTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    dispatch(fetchRange(rangeId, user.accessToken));
    dispatch(fetchRangeProducts(rangeId, user.accessToken));
    dispatch(fetchProducts(currentProject.id, user.accessToken));
  }, [rangeId]);
  const [showPreviewMode, setShowPreviewMode] = useState(false);
  useEffect(() => {
    if (range) {
      setShowPreviewMode(!range.real_mode);
    }
  }, [range.real_mode]);
  const getRangeName = (name) => `${t("Gamme")}: ${name}`;

  return (
    <>
      <Helmet>
        <title>{t("Gamme")}</title>
      </Helmet>
      <Typography variant="h3" gutterBottom display="inline">
        {getRangeName(range.name)}
        &nbsp;
        {`(mode ${range.real_mode ? t("Réel") : t("Prévisionnel")})`}
      </Typography>
      <Divider my={6} className={classes.margin} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <RangeDetail range={range} />
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom display="inline">
        <Trans i18nKey="dashboard">Tableau de bord</Trans>
      </Typography>
      <Grid container spacing={1} className={classes.widthContainerTable}>
        <Grid item xs={12}>
          <RangeDashboard />
          <Divider my={6} />
          {showPreviewMode ? <PreviewRange /> : <></>}
          <AppBar position="static" className={classes.tabBar}>
            <Tabs value={valueTab} onChange={handleTabChange} aria-label="tabs">
              <Tab
                label={<Trans i18nKey="">Produits de la Gamme</Trans>}
                id="tab-1"
                aria-controls="tabpanel-1"
              />
              <Tab
                label={<Trans i18nKey="">Equilibre de la Gamme</Trans>}
                id="tab-2"
                aria-controls="tabpanel-2"
              />
            </Tabs>
          </AppBar>
          <TabPanel value={valueTab} className={classes.tabContainer} index={0}>
            <RangeProductsRealMode />
          </TabPanel>
          <TabPanel value={valueTab} className={classes.tabContainer} index={1}>
            <RangeBalance range={range} />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default ShowRange;
