/*
    ExternalCostModel defines an external cost
*/

class ExternalCostModel {
  constructor(
    id,
    line_name,
    indicator,
    indicator_percentage,
    indicator_percentage_fixed_part,
    indicator_amount_fixed_part
  ) {
    this.id = id;
    this.line_name = line_name;
    this.indicator = indicator;
    this.indicator_percentage = indicator_percentage;
    this.indicator_percentage_fixed_part = indicator_percentage_fixed_part;
    this.indicator_amount_fixed_part = indicator_amount_fixed_part;
  }
}

export default ExternalCostModel;
