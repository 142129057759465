// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import {
  Divider,
  Typography,
  makeStyles,
  Grid,
  Card as MuiCard,
  CardContent,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { spacing } from "@material-ui/system";

import FieldWithSuffix from "../../components/ElementsField/FieldWithSuffix";

import {
  fetchExternalCosts,
  fetchSig,
  fetchWorkforceCosts,
} from "../../redux/actions/sigActions";
import {
  getConsumedMaterials,
  getConsumedMaterialsDevisePartFixe,
} from "../../services/sig/SigCalculService";
import {
  getConsumptionOfRawMaterials,
  getConsumptionOfRawMaterialsAmountVariableExpenses,
  getConsumptionOfRawMaterialsPercentageVariableExpenses,
  getConsumptionOfRawMaterialsPercentageFixedExpenses,
  getExternalCostsPercentageVariableExpenses,
  getExternalCostsAmountVariableExpenses,
  getExternalCostsPercentageFixedExpenses,
  getWorkforceCostPercentageVariableExpenses,
  getWorkforceCostAmountVariableExpenses,
  getWorkforceCostPercentageFixedExpenses,
  getFinancialExpensesTaxes,
  getFinancialExpensesAmountVariableExpenses,
  getFinancialExpensesTaxesPercentageVariableExpenses,
  getFinancialExpensesPercentageFixedExpenses,
  getFinancialExpensesTaxesAmountFixedExpenses,
} from "../../services/sig/LoadAnalysisMethodService";
import {
  getExternalCostTotal,
  getExternalCostTotalFixedPart,
} from "../../services/external_costs/external_costs_summary";

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  margin: {
    marginTop: "24px",
    marginBottom: "24px",
  },
  tableEqualWidth: {
    "& th": {},
  },
  disableField: {
    "& td div": {
      color: "black!important",
    },
  },
}));

const LoadAnalysis = () => {
  const { t } = useTranslation();
  const { register, control, errors } = useForm();

  const classes = useStyles();
  const dispatch = useDispatch();
  const workforce_costs = useSelector((state) => state.sig.workforce_costs);

  const currentProject = useSelector((state) => state.projects.currentProject);
  const sig = useSelector((state) => state.sig.sig);
  const external_costs = useSelector((state) => state.sig.external_costs);
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;

  useEffect(() => {
    dispatch(fetchSig(currentProject.id, user.accessToken));
    dispatch(
      fetchExternalCosts(currentProject.id, user.accessToken, "EXTERNAL")
    );
    dispatch(fetchWorkforceCosts(currentProject.id, user.accessToken, "STAFF"));
  }, [currentProject]);
  return (
    <>
      <Helmet>
        <title>{t("Analyse des charges")}</title>
      </Helmet>

      <Typography variant="h3" gutterBottom display="inline">
        <Trans i18nKey="Load analysis">Analyse des charges</Trans>
      </Typography>

      <Divider my={6} mr={24} className={classes.margin} />
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Card mb={12} className={classes.componentSig}>
            <CardContent className={classes.card}>
              <TableContainer component={Paper}>
                <Table
                  className={
                    ([classes.table, classes.tableEqualWidth],
                    classes.disableField)
                  }
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow className={[classes.noPaddingTh]}>
                      <TableCell />
                      <TableCell />
                      <TableCell colspan={2} align="center">
                        <Trans i18nKey="Variable expenses">
                          Charges Variables
                        </Trans>
                      </TableCell>
                      <TableCell colspan={2} align="center">
                        <Trans i18nKey="Variable fix">Charges Fixes</Trans>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow className={[classes.noPaddingTh]}>
                      <TableCell align="center">
                        <Trans i18nKey="Variable">Charges</Trans>
                      </TableCell>
                      <TableCell align="center">
                        <Trans i18nKey="Amount before tax">
                          Montant HT (en €)
                        </Trans>
                      </TableCell>
                      <TableCell align="center">
                        <Trans i18nKey="%">%</Trans>
                      </TableCell>
                      <TableCell align="center">
                        <Trans i18nKey="Amount before tax">
                          Montant HT (en €)
                        </Trans>
                      </TableCell>
                      <TableCell align="center">
                        <Trans i18nKey="%">%</Trans>
                      </TableCell>
                      <TableCell align="center">
                        <Trans i18nKey="Amount before tax">
                          Montant HT (en €)
                        </Trans>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.paddingThTd}>
                    <TableRow key="consumption_raw_materials">
                      <TableCell component="th" scope="row" align="right">
                        <Typography variant="h9" gutterBottom display="inline">
                          <Trans
                            i18nKey="Consumption of raw materials (Purchases +
                            Variation of stock-production consumed)"
                          >
                            Consommations de Matières premières (Achats +
                            Variation stock-production consommée)
                          </Trans>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="consumptionOfRawMaterials"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={getConsumptionOfRawMaterials(
                            getConsumedMaterials(sig),
                            sig.production_used
                          )}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="consumptionOfRawMaterialsPercentageVariableExpenses"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={getConsumptionOfRawMaterialsPercentageVariableExpenses(
                            getConsumptionOfRawMaterials(
                              getConsumedMaterials(sig),
                              sig.production_used
                            ),
                            getConsumptionOfRawMaterialsAmountVariableExpenses(
                              getConsumptionOfRawMaterials(
                                getConsumedMaterials(sig),
                                sig.production_used
                              ),
                              getConsumedMaterialsDevisePartFixe(sig)
                            )
                          )}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="consumptionOfRawMaterialsAmountVariableExpenses"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={getConsumptionOfRawMaterialsAmountVariableExpenses(
                            getConsumptionOfRawMaterials(
                              getConsumedMaterials(sig),
                              sig.production_used
                            ),
                            getConsumedMaterialsDevisePartFixe(sig)
                          )}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="consumptionOfRawMaterialsPercentageFixedExpenses"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={getConsumptionOfRawMaterialsPercentageFixedExpenses(
                            getConsumptionOfRawMaterials(
                              getConsumedMaterials(sig),
                              sig.production_used
                            ),
                            getConsumedMaterialsDevisePartFixe(sig)
                          )}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="consumptionOfRawMaterialsAmountFixedExpenses"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={getConsumedMaterialsDevisePartFixe(sig)}
                          register={register}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="personnel_costs">
                      <TableCell component="th" scope="row" align="right">
                        <Typography variant="h9" gutterBottom display="inline">
                          <Trans i18nKey="Personnel costs (Salaries + Social charges)">
                            Charges de personnel (Salaires + Charges sociales)
                          </Trans>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="externalCostTotal"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={getExternalCostTotal(workforce_costs)}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="workforceCostPercentageVariableExpenses"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={getWorkforceCostPercentageVariableExpenses(
                            getExternalCostTotal(workforce_costs),
                            getWorkforceCostAmountVariableExpenses(
                              getExternalCostTotal(workforce_costs),
                              getExternalCostTotalFixedPart(workforce_costs)
                            )
                          )}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="workforceCostAmountVariableExpenses"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={getWorkforceCostAmountVariableExpenses(
                            getExternalCostTotal(workforce_costs),
                            getExternalCostTotalFixedPart(workforce_costs)
                          )}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="workforceCostPercentageFixedExpenses"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={getWorkforceCostPercentageFixedExpenses(
                            getExternalCostTotal(workforce_costs),
                            getExternalCostTotalFixedPart(workforce_costs)
                          )}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="externalCostTotalFixedPart"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={getExternalCostTotalFixedPart(workforce_costs)}
                          register={register}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="other_purchases">
                      <TableCell component="th" scope="row" align="right">
                        <Typography variant="h9" gutterBottom display="inline">
                          <Trans i18nKey="Other purchases and external expenses">
                            Autres achats et charges externes
                          </Trans>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="external_cost_total"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={getExternalCostTotal(external_costs)}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="consumptionOfRawMaterialsPercentageVariableExpenses"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={getExternalCostsPercentageVariableExpenses(
                            getExternalCostTotal(external_costs),
                            getExternalCostsAmountVariableExpenses(
                              getExternalCostTotal(external_costs),
                              getExternalCostTotalFixedPart(external_costs)
                            )
                          )}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="consumptionOfRawMaterialsAmountVariableExpenses"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={getExternalCostsAmountVariableExpenses(
                            getExternalCostTotal(external_costs),
                            getExternalCostTotalFixedPart(external_costs)
                          )}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="consumptionOfRawMaterialsPercentageFixedExpenses"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={getExternalCostsPercentageFixedExpenses(
                            getExternalCostTotal(external_costs),
                            getExternalCostTotalFixedPart(external_costs)
                          )}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="external_cost_total_fixed_part"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={getExternalCostTotalFixedPart(external_costs)}
                          register={register}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="financial_expenses">
                      <TableCell component="th" scope="row" align="right">
                        <Typography variant="h9" gutterBottom display="inline">
                          <Trans i18nKey="Financial expenses taxes (excluding IS or IR)">
                            Charges financières impôts (hors IS ou IR)
                          </Trans>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="financialExpensesTaxes"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={getFinancialExpensesTaxes(
                            sig.taxes_duties,
                            sig.other_operating_expenses,
                            sig.financial_result
                          )}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="financialExpensesPercentageVariableExpenses"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={getFinancialExpensesTaxesPercentageVariableExpenses(
                            getFinancialExpensesTaxes(
                              sig.taxes_duties,
                              sig.other_operating_expenses,
                              sig.financial_result
                            ),
                            getFinancialExpensesAmountVariableExpenses(
                              getFinancialExpensesTaxes(
                                sig.taxes_duties,
                                sig.other_operating_expenses,
                                sig.financial_result
                              ),
                              getFinancialExpensesTaxesAmountFixedExpenses(
                                sig.taxes_duties_devise_part_fixe,
                                sig.other_operating_expenses_devise_part_fixe,
                                sig.financial_result_devise_part_fixe
                              )
                            )
                          )}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="financialExpensesAmountVariableExpenses"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={getFinancialExpensesAmountVariableExpenses(
                            getFinancialExpensesTaxes(
                              sig.taxes_duties,
                              sig.other_operating_expenses,
                              sig.financial_result
                            ),
                            getFinancialExpensesTaxesAmountFixedExpenses(
                              sig.taxes_duties_devise_part_fixe,
                              sig.other_operating_expenses_devise_part_fixe,
                              sig.financial_result_devise_part_fixe
                            )
                          )}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="financialExpensesPercentageFixedExpenses"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={getFinancialExpensesPercentageFixedExpenses(
                            getFinancialExpensesTaxes(
                              sig.taxes_duties,
                              sig.other_operating_expenses,
                              sig.financial_result
                            ),
                            getFinancialExpensesTaxesAmountFixedExpenses(
                              sig.taxes_duties_devise_part_fixe,
                              sig.other_operating_expenses_devise_part_fixe,
                              sig.financial_result_devise_part_fixe
                            )
                          )}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="financialExpensesTaxesAmountFixedExpenses"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={getFinancialExpensesTaxesAmountFixedExpenses(
                            sig.taxes_duties_devise_part_fixe,
                            sig.other_operating_expenses_devise_part_fixe,
                            sig.financial_result_devise_part_fixe
                          )}
                          register={register}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="total">
                      <TableCell component="th" scope="row" align="right">
                        <Typography variant="h9" gutterBottom display="inline">
                          <Trans i18nKey="TOTAL">TOTAL</Trans>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="total"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            getConsumptionOfRawMaterials(
                              getConsumedMaterials(sig),
                              sig.production_used
                            ) +
                            getExternalCostTotal(workforce_costs) +
                            getExternalCostTotal(external_costs) +
                            getFinancialExpensesTaxes(
                              sig.taxes_duties,
                              sig.other_operating_expenses,
                              sig.financial_result
                            )
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right" />
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="financialExpensesAmountVariableExpenses"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            getConsumptionOfRawMaterialsAmountVariableExpenses(
                              getConsumptionOfRawMaterials(
                                getConsumedMaterials(sig),
                                sig.production_used
                              ),
                              getConsumedMaterialsDevisePartFixe(sig)
                            ) +
                            getWorkforceCostAmountVariableExpenses(
                              getExternalCostTotal(workforce_costs),
                              getExternalCostTotalFixedPart(workforce_costs)
                            ) +
                            getExternalCostsAmountVariableExpenses(
                              getExternalCostTotal(external_costs),
                              getExternalCostTotalFixedPart(external_costs)
                            ) +
                            getFinancialExpensesAmountVariableExpenses(
                              getFinancialExpensesTaxes(
                                sig.taxes_duties,
                                sig.other_operating_expenses,
                                sig.financial_result
                              ),
                              getFinancialExpensesTaxesAmountFixedExpenses(
                                sig.taxes_duties_devise_part_fixe,
                                sig.other_operating_expenses_devise_part_fixe,
                                sig.financial_result_devise_part_fixe
                              )
                            )
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="right" />
                      <TableCell align="right">
                        <FieldWithSuffix
                          disabled
                          name="financialExpensesTaxesAmountFixedExpenses"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            getConsumedMaterialsDevisePartFixe(sig) +
                            getExternalCostTotalFixedPart(workforce_costs) +
                            getExternalCostTotalFixedPart(external_costs) +
                            getFinancialExpensesTaxesAmountFixedExpenses(
                              sig.taxes_duties_devise_part_fixe,
                              sig.other_operating_expenses_devise_part_fixe,
                              sig.financial_result_devise_part_fixe
                            )
                          }
                          register={register}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default LoadAnalysis;
