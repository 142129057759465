import {
  FETCH_CSER,
  FETCH_CSER_ERROR,
  PUT_CSER,
  PUT_CSER_ERROR,
  UPDATE_COMPUTED_CSER,
  UPDATE_COMPUTED_CSER_ERROR,
  CLEAN_CSER,
} from "../types/cserActionTypes";
/*
cser_data => Any data coming from or to the API (stored values)
computed_cser_* => Any computed value (on the fly, not stored) matching any part of the cser (adjusted_imt, profitability_threshold, ser_growth_turnover, ser_forecast, ser_coeff)
cser_k: The last coefficient of the  CSER (represented with an arrow on original excel format)
*/
const initialState = {
  cser_data: {
    id: 0,
    uuid: "",
    project: 0,
    simulation_adjusted_imt_loan_repayments: 0.0,
    simulation_profitability_threshold_loan_repayments: 0.0,
    simulation_ser_growth_turnover_loan_repayments: 0.0,
    simulation_ser_growth_turnover_net_surplus: 0.0,
    simulation_ser_forecast_percentage_of_change_in_revenues: 0.0,
    simulation_ser_forecast_percentage_of_variable_load_rate: 0.0,
    simulation_ser_forecast_margin_on_variable_costs: 0.0,
    simulation_ser_forecast_fixed_costs: 0.0,
    simulation_ser_forecast_loan_repayments: 0.0,
    simulation_ser_coeff_loan_repayments: 0.0,
    percentage_reduction_material_consumption: 0.0,
    indicators_ser_growth_turnover_average_price_increase: 0.0,
    indicators_ser_forecast_average_price_increase: 0.0,
    indicators_ser_coeff_coefficient: 0.0,
  },
  computed_cser: {
    adjusted_imt: {
      simulation_turnover_before_tax: 0.0,
      simulation_turnover_before_tax_percentage: 0.0,
      simulation_variable_costs: 0.0,
      simulation_variable_costs_percentage: 0.0,
      simulation_margin_on_variable_costs: 0.0,
      simulation_margin_on_variable_costs_percentage: 0.0,
      simulation_fixed_costs: 0.0,
      simulation_fixed_costs_percentage: 0.0,
      simulation_current_income_before_taxes: 0.0,
      simulation_corporate_taxes: 0.0,
      simulation_net_profit_after_tax: 0.0,
      simulation_cash_flow: 0.0,
      simulation_cash_flow_percentage: 0.0,
      simulation_loan_repayments: 0.0,
      simulation_loan_repayments_percentage: 0.0,
      simulation_net_surplus: 0.0,
      simulation_net_surplus_percentage: 0.0,
      indicators_number_of_customers_by_day: 0,
      indicators_fill_rate: 0.0,
      indicators_average_price_in_euros: 0.0,
      indicators_turnover_day: 0.0,
      indicators_number_of_customers_by_week: 0,
      indicators_gross_margin_in_euros: 0.0,
      indicators_turnover_week: 0.0,
      indicators_coefficients: 0.0,
      indicators_ratio_of_materials: 0.0,
      indicators_gross_margin_in_euros_excluding_consumed_production: 0.0,
    },
    profitability_threshold: {
      simulation_turnover_before_tax: 0.0,
      simulation_variable_costs: 0.0,
      simulation_margin_on_variable_costs: 0.0,
      simulation_fixed_costs: 0.0,
      simulation_current_income_before_taxes: 0.0,
      simulation_corporate_taxes: 0.0,
      simulation_net_profit_after_tax: 0.0,
      simulation_cash_flow: 0.0,
      simulation_loan_repayments: 0.0,
      simulation_net_surplus: 0.0,
      indicators_number_of_customers_by_day: 0,
      indicators_fill_rate: 0.0,
      indicators_average_price_in_euros: 0.0,
      indicators_turnover_day: 0.0,
      indicators_number_of_customers_by_week: 0,
      indicators_gross_margin_in_euros: 0.0,
      indicators_turnover_week: 0.0,
      indicators_coefficients: 0.0,
      indicators_ratio_of_materials: 0.0,
      indicators_gross_margin_in_euros_excluding_consumed_production: 0.0,
    },
    ser_growth_turnover: {
      simulation_turnover_before_tax: 0.0,
      simulation_percentage_of_change_in_revenues: 0.0,
      simulation_variable_costs: 0.0,
      simulation_margin_on_variable_costs: 0.0,
      simulation_fixed_costs: 0.0,
      simulation_current_income_before_taxes: 0.0,
      simulation_corporate_taxes: 0.0,
      simulation_net_profit_after_tax: 0.0,
      simulation_cash_flow: 0.0,
      simulation_loan_repayments: 0.0,
      simulation_net_surplus: 0.0,
      indicators_turnover_day: 0.0,
      indicators_turnover_week: 0.0,
      average_price_increase: {
        price_increase: 0.0,
        indicators_number_of_customers_by_day: 0,
        indicators_fill_rate: 0.0,
        indicators_average_price_in_euros: 0.0,
        indicators_number_of_customers_by_week: 0,
        indicators_gross_margin_in_euros: 0.0,
      },
      average_price_stable: {
        indicators_number_of_customers_by_day: 0,
        indicators_fill_rate: 0.0,
        indicators_average_price_in_euros: 0.0,
        indicators_number_of_customers_by_week: 0,
        indicators_gross_margin_in_euros: 0.0,
      },
      occupancy_rate_stable: {
        indicators_number_of_customers_by_day: 0,
        indicators_fill_rate: 0.0,
        indicators_average_price_in_euros: 0.0,
        indicators_number_of_customers_by_week: 0,
        indicators_gross_margin_in_euros: 0.0,
      },
      indicators_coefficients: 0.0,
      indicators_ratio_of_materials: 0.0,
      indicators_gross_margin_in_euros_excluding_consumed_production: 0.0,
    },
    ser_forecast: {
      percentage_reduction_material_consumption: 0.0,
      new_variable_costs_rate: 0.0,
      simulation_turnover_before_tax: 0.0,
      simulation_percentage_of_change_in_revenues: 0.0,
      simulation_variable_costs: 0.0,
      simulation_percentage_of_variable_load_rate: 0.0,
      simulation_margin_on_variable_costs: 0.0,
      simulation_margin_on_variable_costs_percentage: 0.0,
      simulation_fixed_costs: 0.0,
      simulation_fixed_costs_variation_in_euros: 0.0,
      simulation_current_income_before_taxes: 0.0,
      simulation_corporate_taxes: 0.0,
      simulation_net_profit_after_tax: 0.0,
      simulation_cash_flow: 0.0,
      simulation_loan_repayments: 0.0,
      simulation_net_surplus: 0.0,
      indicators_turnover_day: 0.0,
      indicators_turnover_week: 0.0,
      average_price_increase: {
        price_increase: 0.0,
        indicators_number_of_customers_by_day: 0,
        indicators_fill_rate: 0.0,
        indicators_average_price_in_euros: 0.0,
        indicators_number_of_customers_by_week: 0,
        indicators_gross_margin_in_euros: 0.0,
      },
      average_price_stable: {
        indicators_number_of_customers_by_day: 0,
        indicators_fill_rate: 0.0,
        indicators_average_price_in_euros: 0.0,
        indicators_number_of_customers_by_week: 0,
        indicators_gross_margin_in_euros: 0.0,
      },
      occupancy_rate_stable: {
        indicators_number_of_customers_by_day: 0,
        indicators_fill_rate: 0.0,
        indicators_average_price_in_euros: 0.0,
        indicators_number_of_customers_by_week: 0,
        indicators_gross_margin_in_euros: 0.0,
      },
      indicators_coefficients: 0.0,
      indicators_ratio_of_materials: 0.0,
      indicators_gross_margin_in_euros_excluding_consumed_production: 0.0,
    },
    ser_coeff: {
      simulation_turnover_before_tax: 0.0,
      simulation_variable_costs: 0.0,
      simulation_margin_on_variable_costs: 0.0,
      simulation_fixed_costs: 0.0,
      simulation_current_income_before_taxes: 0.0,
      simulation_corporate_taxes: 0.0,
      simulation_net_profit_after_tax: 0.0,
      simulation_cash_flow: 0.0,
      simulation_loan_repayments: 0.0,
      simulation_net_surplus: 0.0,
      indicators_coefficients: 0.0,
      indicators_ratio_of_materials: 0.0,
      indicators_gross_margin_in_euros_excluding_consumed_production: 0.0,
    },
    cser_k: 0.0,
  },
  loading: false,
  errors: [],
};

const cserReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CSER:
      return {
        ...state,
        cser_data: action.payload,
        loading: false,
      };
    case FETCH_CSER_ERROR:
      return {
        ...state,
        loading: false,
        errors: [...action.payload],
      };
    case PUT_CSER:
      return {
        ...state,
        cser_data: action.payload,
        loading: false,
      };
    case PUT_CSER_ERROR:
      return {
        ...state,
        loading: false,
        errors: [...state.errors, action.payload],
      };
    case UPDATE_COMPUTED_CSER:
      return {
        ...state,
        computed_cser: {
          ...state.computed_cser,
          ...action.payload,
        },
        loading: false,
      };
    case UPDATE_COMPUTED_CSER_ERROR:
      return {
        ...state,
        loading: false,
        errors: [...action.payload],
      };
    case CLEAN_CSER:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default cserReducer;
