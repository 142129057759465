/* eslint-disable import/no-useless-path-segments */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import FirstOmnesPrinciple from "../../components/Omnes/FirstOmnesPrinciple";
import SecondOmnesPrinciple from "../../components/Omnes/SecondOmnesPrinciple";
import ThirdOmnesPrinciple from "../../components/Omnes/ThirdOmnesPrinciple";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  marginBox: {
    margin: "12px",
  },
  margin: {
    marginTop: "16px",
    marginBottom: "16px",
  },
}));

function RangeBalance({ range }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <FirstOmnesPrinciple range={range} />
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <SecondOmnesPrinciple range={range} />
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <ThirdOmnesPrinciple range={range} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

RangeBalance.propTypes = {
  range: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    products: PropTypes.shape({}),
    sales_revenue_TTC: PropTypes.number,
    sales_revenue_HT: PropTypes.number,
    material_consumption_HT: PropTypes.number,
    gross_margin: PropTypes.number,
    average_price_offered_TTC: PropTypes.number,
    average_material_ratio: PropTypes.number,
    range_multiplier_coefficient: PropTypes.number,
    coefficient_k: PropTypes.number,
    cser_range: PropTypes.string,
    average_price_requested_TTC: PropTypes.number,
    variation_popularity: PropTypes.number,
    variation_rentability: PropTypes.number,
    price_TTC: PropTypes.number,
    quantity_sold: PropTypes.number,
    quantity_check: PropTypes.number,
    number_days_presentation: PropTypes.number,
    number_check_total: PropTypes.number,
    number_option_check_range: PropTypes.number,
    number_day_by_year: PropTypes.number,
    number_custom_by_day: PropTypes.number,
    number_custom_by_year_number_check_total: PropTypes.number,
    number_custom_by_year_number_option_check_range: PropTypes.number,
    tva_rate: PropTypes.number,
  }),
};

RangeBalance.defaultProps = {
  range: {},
};

export default RangeBalance;
