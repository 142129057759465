import { OPEN_SNACKBAR, CLEAR_SNACKBAR } from "../types/snackbarActionTypes";

export const openSnackbar = (message, typeMessage) => async (dispatch) => {
  dispatch({
    type: OPEN_SNACKBAR,
    messageSnackbar: message,
    typeMessageSnackbar: typeMessage,
  });
};
export const clearSnackbar = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SNACKBAR,
  });
};

export default openSnackbar;
