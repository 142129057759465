import {
  FETCH_PROJECTS,
  ADD_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  ADD_CURRENT_PROJECT,
} from "../types/projectActionTypes";

const initialState = {
  projects: [],
  currentProject: { id: -999, name: "" },
  loading: true,
};

const projectsReducer = (state = initialState, action) => {
  const copyProjects = [];

  switch (action.type) {
    case FETCH_PROJECTS:
      return {
        ...state,
        projects: action.payload,
        loading: false,
      };
    case ADD_PROJECT:
      return {
        ...state,
        projects: state.projects.concat(action.payload),
        loading: false,
      };
    case UPDATE_PROJECT:
      if (action.payload.id === state.currentProject.id) {
        return {
          ...state,
          projects: copyProjects.concat(
            state.projects.slice(
              0,
              state.projects.findIndex((x) => x.id === action.payload.id)
            ),
            action.payload,
            state.projects.slice(
              state.projects.findIndex((x) => x.id === action.payload.id) + 1
            )
          ),
          currentProject: { id: action.payload.id, name: action.payload.name },
          loading: false,
        };
      }
      return {
        ...state,
        projects: copyProjects.concat(
          state.projects.slice(
            0,
            state.projects.findIndex((x) => x.id === action.payload.id)
          ),
          action.payload,
          state.projects.slice(
            state.projects.findIndex((x) => x.id === action.payload.id) + 1
          )
        ),
        loading: false,
      };

    case DELETE_PROJECT:
      if (action.payload.id === state.currentProject.id) {
        return {
          ...state,
          projects: copyProjects.concat(
            state.projects.slice(
              0,
              state.projects.findIndex((x) => x.id === action.payload.id)
            ),
            state.projects.slice(
              state.projects.findIndex((x) => x.id === action.payload.id) + 1
            )
          ),
          currentProject: { id: -999, name: "" },
          loading: false,
        };
      }
      return {
        ...state,
        projects: copyProjects.concat(
          state.projects.slice(
            0,
            state.projects.findIndex((x) => x.id === action.payload.id)
          ),
          state.projects.slice(
            state.projects.findIndex((x) => x.id === action.payload.id) + 1
          )
        ),
        loading: false,
      };
    case ADD_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default projectsReducer;
