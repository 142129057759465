import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { resetStoreAction } from "../../redux/actions/userActions";

const ProtectedRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const dispatch = useDispatch();
  dispatch(resetStoreAction());
  const auth = useSelector((state) => state.firebase.auth);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoaded(auth) && !isEmpty(auth)) {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        }
        return <Redirect to="/auth/sign-in" />;
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
};

export default ProtectedRoute;
