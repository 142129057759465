import {
  FETCH_RANGE,
  FETCH_RANGE_ERROR,
  FETCH_RANGES_SUCCESS,
  FETCH_RANGES_ERROR,
  UPDATE_RANGE_ERROR,
  UPDATE_RANGE_SUCCESS,
  CLEAR_RANGE,
  CLEAR_RANGES,
} from "../types/rangeActionTypes";
import apiClient from "../../services/api/apiClient";
import { RANGE_URL } from "../../services/constants/urls";

export const fetchRange = (rangeId, accessToken) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_URL}${rangeId}`;
  try {
    const res = await apiClient({ method: "get", url, headers });
    dispatch({
      type: FETCH_RANGE,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: FETCH_RANGE_ERROR,
      payload: { error: e },
    });
  }
};

export const fetchProjectRanges = (projectId, accessToken) => async (
  dispatch
) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_URL}?project_id=${projectId}`;
  try {
    const res = await apiClient({ method: "get", url, headers });
    dispatch({
      type: FETCH_RANGES_SUCCESS,
      payload: res.data.results,
    });
  } catch (e) {
    dispatch({
      type: FETCH_RANGES_ERROR,
      payload: { error: e },
    });
  }
};

export const clearRanges = () => async (dispatch) => {
  dispatch({
    type: CLEAR_RANGE,
    payload: {},
  });
  dispatch({
    type: CLEAR_RANGES,
    payload: [],
  });
};

export const updateRangeAction = (data, rangeId, accessToken) => async (
  dispatch
) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_URL}${rangeId}`;
  try {
    const res = await apiClient({ method: "patch", url, headers, data });
    dispatch({
      type: UPDATE_RANGE_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: UPDATE_RANGE_ERROR,
      payload: { error: e },
    });
  }
};
