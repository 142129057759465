import axios from "../../services/api/apiClient";
import { RANGE_PRODUCTS } from "../../services/constants/urls";
import {
  FETCH_RANGE_PRODUCTS,
  ERROR_FETCHING_RANGE_PRODUCTS,
  DELETE_RANGE_PRODUCT_ERROR,
  DELETE_RANGE_PRODUCT_SUCCESS,
  ADD_PRODUCT_TO_RANGE_SUCCESS,
  ADD_PRODUCT_TO_RANGE_ERROR,
  FETCH_RANGES_OF_A_PRODUCT_SUCCESS,
  FETCH_RANGES_OF_A_PRODUCT_ERROR,
  UPDATE_RANGE_PRODUCT_SUCCESS,
  UPDATE_RANGE_PRODUCT_ERROR,
  UPDATE_RANGE_PRODUCTS_SUCCESS,
  SWITCH_POPSER,
} from "../types/rangeProductsActionTypes";
import { store } from "../store";

const replaceListRangeProducts = (updatedRangeProduct) => {
  const { rangeProducts } = store.getState();
  const newRangeProducts = [...rangeProducts.rangeProducts];
  updatedRangeProduct.forEach((rangeProduct) => {
    const indexTable = newRangeProducts.findIndex(
      (oldRangeProduct) => oldRangeProduct.id === rangeProduct.id
    );
    newRangeProducts.splice(indexTable, 1, rangeProduct);
  });

  return newRangeProducts;
};

export const fetchRangeProducts = (rangeID, accessToken) => async (
  dispatch
) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_PRODUCTS}?range_id=${rangeID}`;
  try {
    const res = await axios({ method: "get", url, headers });
    dispatch({
      type: FETCH_RANGE_PRODUCTS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ERROR_FETCHING_RANGE_PRODUCTS,
      payload: { error: e },
    });
  }
};

export const fetchRangesOfAProduct = (productId, accessToken) => async (
  dispatch
) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_PRODUCTS}?product_id=${productId}`;
  try {
    const res = await axios({ method: "get", url, headers });

    dispatch({
      type: FETCH_RANGES_OF_A_PRODUCT_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: FETCH_RANGES_OF_A_PRODUCT_ERROR,
      payload: { error: e },
    });
  }
};

export const updateRangeProduct = (rangeProduct, accessToken) => async (
  dispatch
) => {
  const rangeProductData = {
    ...rangeProduct,
    product: rangeProduct.product.id,
    range: rangeProduct.range.id,
    project: rangeProduct.project.id,
  };
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_PRODUCTS}${rangeProduct.uuid}/`;
  try {
    const res = await axios({
      method: "patch",
      url,
      headers,
      data: rangeProductData,
    });
    dispatch({
      type: UPDATE_RANGE_PRODUCT_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: UPDATE_RANGE_PRODUCT_ERROR,
      payload: { error: e },
    });
  }
};
export const putRangeProducts = (rangeProducts, accessToken) => async (
  dispatch
) => {
  const updatedRangeProducts = [];
  let errorLog = { isError: false, message: null };

  await Promise.all(
    rangeProducts.map(async (rangeProduct) => {
      const rangeProductData = {
        ...rangeProduct,
        product: rangeProduct.product.id,
        range: rangeProduct.range.id,
        project: rangeProduct.project.id,
      };

      const headers = {
        "Content-Type": `application/json;`,
        Authorization: `Bearer ${accessToken}`,
      };
      const url = `${RANGE_PRODUCTS}${rangeProduct.uuid}/`;
      try {
        const res = await axios({
          method: "patch",
          url,
          headers,
          data: rangeProductData,
        });
        updatedRangeProducts.push(res.data);
      } catch (e) {
        errorLog = { isError: true, message: e };
      }
    })
  );
  if (errorLog.isError) {
    dispatch({
      type: UPDATE_RANGE_PRODUCT_ERROR,
      payload: { error: errorLog.message },
    });
  } else {
    dispatch({
      type: UPDATE_RANGE_PRODUCTS_SUCCESS,
      payload: replaceListRangeProducts(updatedRangeProducts),
    });
  }
};

export const updateRangeProducts = (rangeProducts) => async (dispatch) => {
  dispatch({
    type: UPDATE_RANGE_PRODUCTS_SUCCESS,
    payload: replaceListRangeProducts(rangeProducts),
  });
};

export const addProductToRange = (
  project_id,
  range_id,
  product_id,
  accessToken,
  rangeProduct
) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_PRODUCTS}`;
  try {
    const res = await axios({
      method: "post",
      url,
      headers,
      data: {
        project: project_id,
        range: range_id,
        product: product_id,
        ...rangeProduct,
      },
    });
    dispatch({
      type: ADD_PRODUCT_TO_RANGE_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ADD_PRODUCT_TO_RANGE_ERROR,
      payload: { error: e },
    });
  }
};

export const removeProductFromRange = (rangeProductUUID, accessToken) => async (
  dispatch
) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_PRODUCTS}${rangeProductUUID}`;
  try {
    await axios({ method: "delete", url, headers });
    dispatch({
      type: DELETE_RANGE_PRODUCT_SUCCESS,
      payload: rangeProductUUID,
    });
  } catch (e) {
    dispatch({
      type: DELETE_RANGE_PRODUCT_ERROR,
      payload: { error: e },
    });
  }
};

export const switchPopser = (popser) => async (dispatch) => {
  dispatch({
    type: SWITCH_POPSER,
    payload: popser,
  });
};
