import { getParsedFloatNumber, toFixedNumber } from "../range/RangeService";

export const getIndicatorPercentage = (indicator, total_product) => {
  return toFixedNumber(
    getParsedFloatNumber(
      (getParsedFloatNumber(indicator) / getParsedFloatNumber(total_product)) *
        100.0
    ),
    2
  );
};

export const getIndicatorFixedPartPercentage = (
  indicator_fixed_part,
  indicator
) => {
  return toFixedNumber(
    getParsedFloatNumber(
      (getParsedFloatNumber(indicator_fixed_part) /
        getParsedFloatNumber(indicator)) *
        100.0
    ),
    2
  );
};

export const getIndicatorFixedPartAmount = (
  indicator_fixed_part_percentage,
  indicator
) => {
  return toFixedNumber(
    getParsedFloatNumber(
      getParsedFloatNumber(indicator) *
        (getParsedFloatNumber(indicator_fixed_part_percentage) / 100.0)
    ),
    2
  );
};

export default getIndicatorPercentage;
