import { SHOW_COMMENTS } from "../types/managerFeedbackActionTypes";

const initialState = {
  show_comments: {
    size_comments_screen: 0,
    size_other_screen: 12,
  },
};

const managerFeedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_COMMENTS:
      return {
        ...state,
        show_comments: action.payload,
      };
    default:
      return state;
  }
};

export default managerFeedbackReducer;
