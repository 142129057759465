import React from "react";

const NotEnoughData = () => (
  <p>
    Il n&apos;y a pas assez de données pour vous offrir ces informations.
    Essayez d&apos;ajouter des produits à votre gamme.
  </p>
);

export default NotEnoughData;
