import React from "react";

import {
  Briefcase,
  Users,
  ShoppingBag,
  Book,
  Eye,
  Target,
} from "react-feather";
import { Trans } from "react-i18next";
import Projects from "../../pages/pages/Projects";
import Products from "../../pages/pages/Products";
import ShowProduct from "../../pages/pages/ShowProduct";
import ProductDetail from "../../pages/pages/AddProduct";
import Ranges from "../../pages/pages/Ranges";
import AddRange from "../../pages/pages/AddRange";
import ShowRange from "../../pages/pages/ShowRange";
import ShowOffer from "../../pages/offers/ShowOffer";
import ShowSig from "../../pages/sig/ShowSig";
import LoadAnalysis from "../../pages/sig/LoadAnalysis";
import ShowCser from "../../pages/cser/ShowCser";

import SignIn from "../../pages/auth/SignIn";
import SignUp from "../../pages/auth/SignUp";
import ResetPassword from "../../pages/auth/ResetPassword";
import Page404 from "../../pages/auth/Page404";
import Page500 from "../../pages/auth/Page500";
import offersList from "../../pages/offers/offersList";
import offerCreate from "../../pages/offers/offerCreate";

const projectsRoutes = {
  id: <Trans i18nKey="project">Projets</Trans>,
  path: "/projects",
  icon: <Briefcase />,
  component: Projects,
  children: null,
  isProtected: true,
};

const productsRoutes = {
  id: <Trans i18nKey="product">Produits</Trans>,
  path: "/product",
  icon: <ShoppingBag />,
  children: [
    {
      path: "/product",
      name: <Trans i18nKey="list product">Liste des produits</Trans>,
      component: Products,
    },
    {
      path: "/product/add",
      name: <Trans i18nKey="add product">Ajouter un produit</Trans>,
      component: ProductDetail,
    },
  ],
  isProtected: true,
};

const productDetailRoutes = {
  id: <Trans i18nKey="detail product">Détails d&apos;un produit</Trans>,
  path: "/product/:productId",
  component: ShowProduct,
  children: null,
  isProtected: true,
};

const rangeDetailRoutes = {
  id: <Trans i18nKey="detail product">Détails d&apos;une gamme</Trans>,
  path: "/range/:rangeId",
  component: ShowRange,
  children: null,
  isProtected: true,
};

const rangesRoutes = {
  id: <Trans i18nKey="range">Gammes</Trans>,
  path: "/range",
  icon: <ShoppingBag />,
  children: [
    {
      path: "/range",
      name: <Trans i18nKey="list ranges">Liste des gammes</Trans>,
      component: Ranges,
    },
    {
      path: "/range/add",
      name: <Trans i18nKey="add range">Ajouter une gamme</Trans>,
      component: AddRange,
    },
  ],
  isProtected: true,
};
const offerDetailRoutes = {
  id: <Trans i18nKey="detail offer">Détails d&apos;une offre</Trans>,
  path: "/offer/:offerId",
  component: ShowOffer,
  children: null,
  isProtected: true,
};
const offersRoute = {
  id: <Trans i18nKey="range">Offres</Trans>,
  path: "/offer",
  icon: <Book />,
  children: [
    {
      path: "/offer",
      name: <Trans i18nKey="list ranges">Liste des offres</Trans>,
      component: offersList,
    },
    {
      path: "/offer/add",
      name: <Trans i18nKey="add range">Ajouter une offre</Trans>,
      component: offerCreate,
    },
  ],
  isProtected: true,
};

const sigRoute = {
  id: <Trans i18nKey="sig">SIG</Trans>,
  name: <Trans i18nKey="sig">SIG</Trans>,
  path: "/sig",
  icon: <Eye />,
  component: ShowSig,
  children: [
    {
      path: "/sig",
      name: (
        <Trans i18nKey="Intermediate Management Thresholds">
          Soldes Intermédiaires de Gestion
        </Trans>
      ),
      component: ShowSig,
    },
    {
      path: "/sig/load-analysis",
      name: <Trans i18nKey="Load analysis">Analyse des charges</Trans>,
      component: LoadAnalysis,
    },
  ],
  isProtected: true,
};

const cserRoute = {
  id: <Trans i18nKey="CSER">CSER</Trans>,
  name: <Trans i18nKey="CSER">CSER</Trans>,
  path: "/cser",
  icon: <Target />,
  component: ShowCser,
  children: null,

  isProtected: true,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  isProtected: false,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  projectsRoutes,
  productsRoutes,
  rangesRoutes,
  productDetailRoutes,
  rangeDetailRoutes,
  offersRoute,
  offerDetailRoutes,
  sigRoute,
  cserRoute,
];

// Routes visible in the sidebar
export const sidebarRoutes = [
  projectsRoutes,
  productsRoutes,
  rangesRoutes,
  offersRoute,
  sigRoute,
  cserRoute,
];
