/* eslint-disable no-unused-vars */
import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { Add as AddIcon } from "@material-ui/icons";
import OfferTable from "../../components/Offers/offerTable";

function EmptyRowsRenderer() {
  return (
    <Grid container spacing={0} alignContent="center">
      <Grid xs={12}>
        <div style={{ textAlign: "center" }}>Pas d&apos;offre disponible</div>
      </Grid>
      <Grid xs={12} style={{ textAlign: "center" }}>
        <Button
          component={Link}
          to="/offer/add"
          variant="contained"
          color="primary"
        >
          Créer une offre
        </Button>
      </Grid>
    </Grid>
  );
}

const offersList = () => {
  return (
    <Grid container justify="space-between" spacing={3}>
      <Grid item xs={11}>
        <Typography variant="h1">Offres</Typography>
      </Grid>
      <Grid item xs={1}>
        <div style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/offer/add"
          >
            <AddIcon />
            <Trans i18nKey="add">Créer</Trans>
          </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        <OfferTable />
      </Grid>
    </Grid>
  );
};

export default offersList;
