import {
  UPDATE_TECHNICAL_SHEET,
  UPDATE_TECHNICAL_SHEET_ERROR,
  FETCH_TECHNICAL_SHEET,
  FETCH_TECHNICAL_SHEET_ERROR,
  CLEAR_TECHNICAL_SHEET,
} from "../types/technicalSheetActionTypes";
import apiClient from "../../services/api/apiClient";
import { PRODUCTS_URL } from "../../services/constants/urls";

export const updateTechnicalSheet = (
  technicalSheet,
  accessToken,
  productId,
  idCurrentProject
) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const data = {
    project: idCurrentProject,
    coefficient: technicalSheet.multiplyingFactor.value
      ? technicalSheet.multiplyingFactor.value
      : undefined,
    tva_rate: technicalSheet.tvaRate.value
      ? technicalSheet.tvaRate.value
      : undefined,
    ttc_price: technicalSheet.sellingPriceTTC.value
      ? technicalSheet.sellingPriceTTC.value
      : undefined,
    selling_price_ht: technicalSheet.sellingPriceHT.value
      ? technicalSheet.sellingPriceHT.value
      : undefined,
    per_unit_price: technicalSheet.totalPricePortion.value
      ? technicalSheet.totalPricePortion.value
      : undefined,
    total_raw_material_cost: technicalSheet.totalMaterialCost.value
      ? technicalSheet.totalMaterialCost.value
      : undefined,
    tva: technicalSheet.tva.value ? technicalSheet.tva.value : undefined,
    extra_charge: technicalSheet.extraCharge.value
      ? technicalSheet.extraCharge.value
      : undefined,
    per_unit_energy: technicalSheet.perUnitEnergy.value
      ? technicalSheet.perUnitEnergy.value
      : undefined,
    raw_material_cost: technicalSheet.materialCosts.value
      ? technicalSheet.materialCosts.value
      : undefined,
  };
  const url = `${process.env.REACT_APP_POPSER_API_URL}/product/${productId}`;
  try {
    await apiClient({ method: "patch", url, headers, data });
    dispatch({
      type: UPDATE_TECHNICAL_SHEET,
      payload: technicalSheet,
    });
  } catch (e) {
    dispatch({
      type: UPDATE_TECHNICAL_SHEET_ERROR,
      payload: technicalSheet,
    });
  }
};

export const fetchTechnicalSheet = (product_id, accessToken) => async (
  dispatch
) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${PRODUCTS_URL}${product_id}`;
  try {
    const res = await apiClient({ method: "get", url, headers });

    const resultTechnicalSheet = {
      listTotalPrice: {
        value: [],
        loading: false,
      },
      materialCosts: {
        value: res.data.raw_material_cost || 0,
        loading: false,
      },
      extraCharge: {
        value: res.data.extra_charge || 0,
        loading: false,
      },
      totalMaterialCost: {
        value: res.data.total_raw_material_cost || 0,
        loading: false,
      },
      totalPricePortion: {
        value: res.data.per_unit_price || 0,
        loading: false,
      },
      perUnitEnergy: {
        value: res.data.per_unit_energy || 0,
        loading: false,
      },
      multiplyingFactor: {
        isInput: false,
        value: res.data.coefficient || 0,
        loading: false,
      },
      sellingPriceHT: {
        value: res.data.selling_price_ht || 0,
        loading: false,
      },
      tva: {
        value: res.data.tva || 0,
        loading: false,
      },
      tvaRate: {
        isInput: false,
        value: res.data.tva_rate || 0,
        loading: false,
      },
      sellingPriceTTC: {
        isInput: false,
        value: res.data.ttc_price || 0,
        loading: false,
      },
      loading: false,
    };
    dispatch({
      type: FETCH_TECHNICAL_SHEET,
      payload: resultTechnicalSheet,
    });
  } catch (e) {
    dispatch({
      type: FETCH_TECHNICAL_SHEET_ERROR,
      payload: { error: e },
    });
  }
};
export const clearTechnicalSheet = () => async (dispatch) => {
  const resultTechnicalSheet = {
    listTotalPrice: {
      value: [],
      loading: true,
    },
    materialCosts: {
      value: 0,
      loading: true,
    },
    extraCharge: {
      value: 0,
      loading: true,
    },
    totalMaterialCost: {
      value: 0,
      loading: true,
    },
    totalPricePortion: {
      value: 0,
      loading: true,
    },
    totalEnergy: {
      value: 0,
      loading: true,
    },
    perUnitEnergy: {
      value: 0,
      loading: true,
    },
    multiplyingFactor: {
      isInput: false,
      value: 0,
      loading: true,
    },
    sellingPriceHT: {
      value: 0,
      loading: true,
    },
    tva: {
      value: 0,
      loading: true,
    },
    tvaRate: {
      isInput: false,
      value: 0,
      loading: true,
    },
    sellingPriceTTC: {
      isInput: false,
      value: 0,
      loading: true,
    },
    loading: true,
  };
  dispatch({
    type: CLEAR_TECHNICAL_SHEET,
    payload: resultTechnicalSheet,
  });
};
export default updateTechnicalSheet;
