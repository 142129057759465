import { SHOW_COMMENTS } from "../types/managerFeedbackActionTypes";

export const show_comments = () => async (dispatch) => {
  dispatch({
    type: SHOW_COMMENTS,
    payload: {
      size_comments_screen: 2,
      size_other_screen: 10,
    },
  });
};
export const hide_comments = () => async (dispatch) => {
  dispatch({
    type: SHOW_COMMENTS,
    payload: {
      size_comments_screen: 0,
      size_other_screen: 12,
    },
  });
};
export default show_comments;
