/* react/no-unescaped-entities */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Modal,
  TextField,
  Fade,
  Backdrop,
} from "@material-ui/core";

import {
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  DeleteForever as DeleteForeverIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import axios from "../../services/api/apiClient";
import { clearProduct } from "../../redux/actions/productActions";
import { clearTechnicalSheet } from "../../redux/actions/technicalSheetActions";

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "30%",
  },
  listSelected: {
    margin: "0px",
    fontSize: "12px",
    listStyle: "circle",
  },
  search: {
    width: "100%",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "name", alignment: "left", label: "Nom du Produit" },
  { id: "seasonnality", alignment: "left", label: "Saisonnalité" },
  { id: "number_produced", alignment: "left", label: "Nombre de portions" },
  { id: "actions", alignment: "right", label: "Actions" },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  numSelected: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

const EnhancedTableToolbar = ({
  numSelected,
  setOpen,
  setUrlSearch,
  setUrlPage,
}) => {
  const handleOpen = () => {
    setOpen(true);
  };
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChangeSearch = (event) => {
    setUrlSearch(`&search=${event.target.value}`);
    setUrlPage(`&page=${1}`);
    // setPage(1);
  };

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="h6">
            {numSelected}
            <Trans i18nKey="selected">
              {" "}
              selectionné
              {numSelected > 1 ? "s" : ""}
            </Trans>
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            <Trans i18nKey="products">Produits</Trans>
          </Typography>
        )}
      </ToolbarTitle>
      <TextField
        id="standard-search"
        className={classes.search}
        type="search"
        onChange={handleChangeSearch}
        placeholder={t("Recherche")}
      />
      <Spacer />

      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete" onClick={handleOpen}>
            <IconButton aria-label="Delete">
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  setOpen: PropTypes.func.isRequired,
  setUrlSearch: PropTypes.func,
  setUrlPage: PropTypes.func,
};

EnhancedTableToolbar.defaultProps = {
  setUrlSearch: null,
  setUrlPage: null,
};

const loadListProduct = async (
  user,
  urlPage,
  urlPageSize,
  urlSearch,
  urlSort,
  urlSortAsc,
  currentProject
) => {
  if (typeof currentProject.id === "undefined") {
    return [];
  }
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${user.accessToken}`,
  };
  const url = `${process.env.REACT_APP_POPSER_API_URL}/product/?project_id=${currentProject.id}${urlPage}${urlPageSize}${urlSearch}${urlSort}${urlSortAsc}`;
  try {
    const res = await axios({
      method: "get",
      url,
      headers,
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return [];
  }
  return [];
};

function ProductTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const [urlPage, setUrlPage] = useState("");
  const [urlPageSize, setUrlPageSize] = useState("");
  const [urlSearch, setUrlSearch] = useState("");
  const [urlSort, setUrlSort] = useState("");
  const [urlSortAsc, setUrlSortAsc] = useState("");
  const currentProject = useSelector((state) => state.projects.currentProject);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { handleSubmit, register, errors } = useForm();

  const [listItem, setListItem] = useState({
    error: null,
    results: [],
    links: {},
    page: null,
    pageSize: null,
    total: null,
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    if (isAsc) {
      setUrlSort(`&order_by=${property}`);
      setUrlSortAsc("&order_asc=true");
    } else {
      setUrlSort(`&order_by=${property}`);
      setUrlSortAsc("&order_asc=false");
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listItem.results.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setUrlPage(`&page=${newPage + 1}`);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setUrlPageSize(`&page_size=${parseInt(event.target.value, 10)}`);
    setUrlPage(`&page=${1}`);
    setPage(1);
  };

  const isSelected = (row) => selected.includes(row);

  const handleOpen = (event, product) => {
    setSelected([product]);
    setOpen(true);
  };

  useEffect(() => {
    loadListProduct(
      user,
      urlPage,
      urlPageSize,
      urlSearch,
      urlSort,
      urlSortAsc,
      currentProject
    )
      .then((data) => {
        if (data.results.length > 0) {
          setListItem({
            error: null,
            results: data.results,
            links: data.links,
            page: data.page,
            pageSize: data.page_size,
            total: data.total,
          });
        } else {
          setListItem({ error: null, results: [] });
        }
        setRowsPerPage(data.page_size);
        setPage(data.page - 1);
      })
      .catch((err) => {
        setListItem({ error: err, results: [] });
      });
  }, [
    setListItem,
    user,
    urlPage,
    urlPageSize,
    urlSearch,
    urlSort,
    urlSortAsc,
    currentProject,
  ]);
  const handleClose = () => {
    setOpen(false);
    setSelected([]);
  };
  const submitDelete = async () => {
    selected.forEach(async (element) => {
      const headers = {
        "Content-Type": `application/json;`,
        Authorization: `Bearer ${user.accessToken}`,
      };
      try {
        const res = await axios({
          method: "delete",
          url: `${process.env.REACT_APP_POPSER_API_URL}/product/${element.id}`,
          headers,
        });
        if (res.status === 204) {
          listItem.results.splice(listItem.results.indexOf(element), 1);
          setListItem({ results: listItem.results });
        }
      } catch (error) {
        console.log(error);
      }
    });

    return handleClose();
  };
  const dispatch = useDispatch();
  const clearProductStore = async () => {
    await dispatch(clearProduct());
    await dispatch(clearTechnicalSheet());
  };
  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          setOpen={setOpen}
          setUrlSearch={setUrlSearch}
          setUrlPage={setUrlPage}
          setPage={setPage}
        />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={listItem.results.length}
            />
            <TableBody>
              {stableSort(listItem.results, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}`}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={
                            (event) =>
                              handleClick(event, row, isItemSelected, index)
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                        />
                      </TableCell>

                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.seasonnality}</TableCell>
                      <TableCell align="left">{row.number_produced}</TableCell>
                      <TableCell padding="none" align="right">
                        <Box mr={2}>
                          <IconButton
                            aria-label="delete"
                            onClick={(event) => handleOpen(event, row)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            aria-label="details"
                            component={Link}
                            onClick={() => clearProductStore()}
                            to={`/product/${row.id}`}
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={listItem.total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open} className={classes.paper}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">
              <Trans i18nKey="delete a project">Suppression du produit :</Trans>
              {selected.map((row) => {
                return (
                  <p className={classes.listSelected} key={row.id}>
                    <span>-</span>
                    <span>{row.name}</span>
                  </p>
                );
              })}
            </h2>
            <Typography gutterBottom display="inline">
              <Trans i18nKey="Are you sure you want to delete it?">
                Êtes-vous sûr(e) ?
              </Trans>
            </Typography>
            <form onSubmit={handleSubmit(submitDelete)}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  name="confirm_delete"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <Trans i18nKey="confirm delete">
                      Confirmer la suppression en écrivant &apos;CONFIRMER&apos;
                    </Trans>
                  }
                  type="text"
                  id="confirm_delete"
                  error={"confirm_delete" in errors}
                  inputRef={register({
                    validate: (value) => value === "CONFIRMER",
                  })}
                  helperText={
                    "confirm_delete" in errors ? (
                      <Trans i18nKey="Word does not match">
                        Le mot ne correspond pas
                      </Trans>
                    ) : (
                      ""
                    )
                  }
                />
              </Grid>
              <Button
                type="submit"
                margin="normal"
                fullWidth
                variant="contained"
                color="primary"
              >
                <Trans i18nKey="delete">Supprimer</Trans>
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default ProductTable;
