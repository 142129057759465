/* eslint-disable no-self-compare */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { Grid } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import FooterAddLine from "../ElementsTable/FooterAddLine";
import {
  addProductToRange,
  fetchRangeProducts,
  removeProductFromRange,
  updateRangeProduct,
  putRangeProducts,
} from "../../redux/actions/rangeProductActions";

import { fetchProducts } from "../../redux/actions/productActions";

import AutoComplete from "../ElementsTable/FieldAutoCompleted";
import FieldWithSuffix from "../ElementsTable/FieldWithSuffix";
import {
  interactionQuantitySoldReal,
  interactionNumberOfDaysOfPresentationReal,
  interactionPriceVatIncluded,
  interactionRawMaterialCost,
  interactionVatRate,
  interactionVatExclPrice,
  interactionChangePollQuantity,
} from "../../services/range/RangeCalculInteractionService";
import { updateRangeAction } from "../../redux/actions/rangeActions";
import { openSnackbar } from "../../redux/actions/snackbarActions";
import { copyProductToRangeProduct } from "../../services/range/RangeProductService";
import {
  getParsedFloatNumber,
  toFixedNumber,
} from "../../services/range/RangeService";
import { store } from "../../redux/store";

const getTableTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          border: "1px solid #ddd",
        },
      },

      MuiFormControlLabel: {
        root: {
          "&:first-child": { display: "none" },
          "&:nth-child(2)": { display: "none" },
        },
      },
    },
  });

const RangeProductsRealMode = () => {
  const { t } = useTranslation();
  const range = useSelector((state) => state.ranges.range);
  const { register, errors, control } = useForm({
    defaultValues: range,
  });
  const popser = useSelector((state) => state.rangeProducts.popser);

  const dispatch = useDispatch();
  const currentProject = useSelector((state) => state.projects.currentProject);
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const onChangeField = (e) => console.log(e);
  const products = useSelector((state) => state.product.products);
  const [suggestedProducts, setSuggestedProducts] = useState(products);
  const rangeProducts = useSelector(
    (state) => state.rangeProducts.rangeProducts
  );
  useEffect(() => {
    const productsIdInRange = rangeProducts.map(
      (rangeProduct) => rangeProduct.product.id
    );
    const suggested = products.filter(
      (product) => !productsIdInRange.includes(product.id)
    );

    setSuggestedProducts(suggested);
  }, [products, rangeProducts.length]);
  const onChangeProductName = async (event, product) => {
    const rangeProduct = copyProductToRangeProduct(product, {});
    if (product) {
      await dispatch(
        addProductToRange(
          currentProject.id,
          range.id,
          product.id,
          user.accessToken,
          rangeProduct
        )
      );
      // In order to compute the indicators of the newly added product, we get it from the store
      const state = store.getState();
      const rangeProductStore = state.rangeProducts.rangeProducts;
      // This method is quick and dirty and based on the fact that the new product of the range will be the last element of the array of rangeProducts in the store
      const newRangeProduct = rangeProductStore[rangeProductStore.length - 1];
      // We use the TTC price to compute the first indicators on the new project, using it to compute all the indicators of the range as well
      const updatedRangeProducts = interactionPriceVatIncluded(
        newRangeProduct.id,
        newRangeProduct.selling_price_vat_incl
      );
      // Now we dispatch this
      dispatch(
        putRangeProducts(updatedRangeProducts.rangeProducts, user.accessToken)
      );
      dispatch(
        updateRangeAction(
          updatedRangeProducts.range,
          range.id,
          user.accessToken
        )
      );
    }
  };
  const { rangeId } = useParams();
  const [listRangeProduct, setListRangeProduct] = useState([]);

  useEffect(() => {
    setListRangeProduct(rangeProducts);
  }, [rangeProducts]);

  useEffect(() => {
    dispatch(fetchProducts(currentProject.id, user.accessToken));
    dispatch(fetchRangeProducts(rangeId, user.accessToken));
  }, []);
  const addLine = () => {
    setListRangeProduct(
      listRangeProduct.concat({
        id:
          listRangeProduct.length === 0
            ? `new ${1}`
            : `new ${listRangeProduct[listRangeProduct.length - 1].uuid + 1}`,
        id_replace: null,
        name_product: null,
        ttc_price: null,
        multiplying_coefficient_product: null,
        cser_indicatif: null,
        gross_margin_HT_Total: null,
        rentability: null,
        quantity_sold: null,
        number_days_presentation: null,
        popularity: null,
        ranking: null,
        range: 0,
        pv_HT: 0.0,
        selling_percentage: 0,
        presentation_index: 0,
        popularity_index: 0,
        popularity_popser: "",
        popularity_boston_school: "",
        unit_gross_margin_vat_exluded: 0,
        rentability_popser: "",
        rentability_boston_school: "",
        ranking_popser: "",
        ranking_boston: "",
        tva: null,
        quantity_check: 0,
        // Information Produit
        product: 0,
        raw_material_cost: 0,
      })
    );
  };

  const deleteRows = async (RowsDeleted) => {
    const ids = RowsDeleted.data.map((d) => d.dataIndex);
    const idsToDeleted = ids.map((index) => listRangeProduct[index]);

    idsToDeleted.map(async (dataDelete) => {
      listRangeProduct.splice(listRangeProduct.indexOf(dataDelete), 1);
      setListRangeProduct(listRangeProduct.concat([]));
      dispatch(removeProductFromRange(dataDelete.uuid, user.accessToken));
    });
  };
  const options = {
    sort: false,
    filter: false,
    filterType: "checkbox",
    enableNestedDataAccess: ".",
    onRowsDelete: deleteRows,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
      <FooterAddLine
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        // eslint-disable-next-line no-shadow
        onChangePage={(_, page) => changePage(page)}
        onChangeRowsPerPage={(event) => changeRowsPerPage(event.target.value)}
        rowsPerPageOptions={[10, 15, 100]}
        addLine={addLine}
        title={t("ajouter produit")}
      />
    ),
  };
  const [rowRangeProduct] = useState();

  const registerRangeProduct = (rangeProduct) => {
    // eslint-disable-next-line no-param-reassign
    listRangeProduct[listRangeProduct.indexOf(rangeProduct)].id =
      rowRangeProduct.id_replace;
  };

  const handleTtcPriceChange = (event, value) => {
    const rangeProductId = parseInt(event.rowData[0], 10);
    const newPrice = getParsedFloatNumber(value);
    const updatedRangeProductsAndRange = interactionPriceVatIncluded(
      rangeProductId,
      newPrice
    );

    dispatch(
      putRangeProducts(
        updatedRangeProductsAndRange.rangeProducts,
        user.accessToken
      )
    );
    dispatch(
      updateRangeAction(
        updatedRangeProductsAndRange.range,
        range.id,
        user.accessToken
      )
    );
  };

  const handleRawMaterialCostChange = (event, value) => {
    const rangeProductId = parseInt(event.rowData[0], 10);
    const newRawMaterialCost = getParsedFloatNumber(value);
    const updatedRangeProducts = interactionRawMaterialCost(
      rangeProductId,
      newRawMaterialCost
    );

    dispatch(putRangeProducts(updatedRangeProducts, user.accessToken));
  };

  const handleVatRateChange = async (event, value) => {
    const rangeProductId = parseInt(event.rowData[0], 10);
    const newVatRate = getParsedFloatNumber(value);
    const updatedRangeProduct = interactionVatRate(rangeProductId, newVatRate);
    await dispatch(updateRangeProduct(updatedRangeProduct, user.accessToken));
    const updatedRangeProducts = interactionPriceVatIncluded(
      rangeProductId,
      updatedRangeProduct.selling_price_vat_incl
    );
    dispatch(
      putRangeProducts(updatedRangeProducts.rangeProducts, user.accessToken)
    );
    dispatch(
      updateRangeAction(updatedRangeProducts.range, range.id, user.accessToken)
    );
  };

  const handleChangePollQuantity = async (event, value) => {
    const rangeProductId = parseInt(event.rowData[0], 10);
    const parsedPollQuantity = toFixedNumber(getParsedFloatNumber(value), 2);
    const {
      updatedRange,
      updatedRangeProducts,
    } = interactionChangePollQuantity(rangeProductId, parsedPollQuantity);
    await dispatch(putRangeProducts(updatedRangeProducts, user.accessToken));
    await dispatch(
      updateRangeAction(updatedRange, updatedRange.id, user.accessToken)
    );
    const updatedRangeProduct = updatedRangeProducts.filter(
      (_rangeProduct) => _rangeProduct.id === rangeProductId
    )[0];
    const result = interactionQuantitySoldReal(
      updatedRangeProduct.quantity_sold,
      rangeProductId
    );
    dispatch(putRangeProducts(result.rangeProducts, user.accessToken));
    dispatch(updateRangeAction(result.range, range.id, user.accessToken));
  };

  const handleHTPriceChange = async (event, value) => {
    const rangeProductId = parseInt(event.rowData[0], 10);
    const newVatExclPrice = getParsedFloatNumber(value);
    const updatedRangeProduct = interactionVatExclPrice(
      rangeProductId,
      newVatExclPrice
    );
    await dispatch(updateRangeProduct(updatedRangeProduct, user.accessToken));
    const updatedRangeProducts = interactionPriceVatIncluded(
      rangeProductId,
      updatedRangeProduct.selling_price_vat_incl
    );
    dispatch(
      putRangeProducts(updatedRangeProducts.rangeProducts, user.accessToken)
    );
    dispatch(
      updateRangeAction(updatedRangeProducts.range, range.id, user.accessToken)
    );
  };

  useEffect(() => {
    if (rowRangeProduct) {
      if (`${rowRangeProduct.id}`.includes("new")) {
        registerRangeProduct(rowRangeProduct);
      } else {
        // updateRawMaterial();
      }
    }
  }, [rowRangeProduct, user]);
  const onChangeQuantitySold = (tableMeta, value) => {
    const result = interactionQuantitySoldReal(value, tableMeta.rowData[0]);
    dispatch(updateRangeAction(result.range, range.id, user.accessToken));
    dispatch(putRangeProducts(result.rangeProducts, user.accessToken));

    dispatch(openSnackbar("Gamme modifiée avec succès", "success"));
  };

  const onChangeDisplayNumber = (tableMeta, value) => {
    const updatedRangeProducts = interactionNumberOfDaysOfPresentationReal(
      value,
      tableMeta.rowData[0]
    );
    dispatch(putRangeProducts(updatedRangeProducts, user.accessToken));
    dispatch(openSnackbar("Gamme modifiée avec succès", "success"));
  };

  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "uuid",
      options: {
        display: false,
      },
    },
    {
      name: "product.name",
      label: t("Nom"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <AutoComplete
              nameColumn={`product.name${tableMeta.rowData[0]}`}
              value={value}
              onChangeField={onChangeProductName}
              tableMeta={tableMeta}
              listObject={suggestedProducts}
              updateValue={updateValue}
            />
          );
        },
      },
    },
    {
      name: "selling_price_vat_incl",
      label: t("Prix de vente TTC"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              isEdit
              nameColumn={`selling_price_vat_incl${tableMeta.rowData[0]}`}
              control={control}
              onChangeField={handleTtcPriceChange}
              tableMeta={tableMeta}
              errors={errors}
              suffix={t("€")}
              register={register}
              updateValue={updateValue}
              value={value}
            />
          );
        },
      },
    },
    /*   name: "poll_quantity",
      label: t("Quantité sondage"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              nameColumn={`poll_quantity${tableMeta.rowData[0]}`}
              control={control}
              onChangeField={onChangeField}
              tableMeta={tableMeta}
              errors={errors}
              suffix=""
              register={register}
              updateValue={updateValue}
              value={value}
            />
          );
        },
      }, */
    {
      name: "quantity_sold",
      label: t("Quantités vendues"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              isEdit
              nameColumn="quantity_sold"
              control={control}
              onChangeField={onChangeQuantitySold}
              tableMeta={tableMeta}
              errors={errors}
              suffix=""
              register={register}
              updateValue={updateValue}
              value={value}
            />
          );
        },
      },
    },
    {
      name: "number_of_days_of_presentation",
      label: t("Nb présentation"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              isEdit
              nameColumn="number_of_days_of_presentation"
              control={control}
              onChangeField={onChangeDisplayNumber}
              tableMeta={tableMeta}
              errors={errors}
              suffix=""
              register={register}
              updateValue={updateValue}
              value={value}
            />
          );
        },
      },
    },
    {
      name: "raw_material_cost_vat_excl",
      label: t("Coût mat. unit."),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              isEdit
              nameColumn={`raw_material_cost_vat_excl${tableMeta.rowData[0]}`}
              control={control}
              onChangeField={handleRawMaterialCostChange}
              tableMeta={tableMeta}
              errors={errors}
              suffix={t("€")}
              register={register}
              updateValue={updateValue}
              value={value}
            />
          );
        },
      },
    },
    {
      name: "vat",
      label: t("Tx TVA (si différent)"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              isEdit
              nameColumn="vat"
              control={control}
              onChangeField={handleVatRateChange}
              tableMeta={tableMeta}
              errors={errors}
              suffix="%"
              register={register}
              updateValue={updateValue}
              value={value}
            />
          );
        },
      },
    },
    {
      name: "selling_price_vat_excl",
      label: t("Pv HT"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              isEdit
              nameColumn={`selling_price_vat_excl${tableMeta.rowData[0]}`}
              control={control}
              onChangeField={handleHTPriceChange}
              tableMeta={tableMeta}
              errors={errors}
              suffix={t("€")}
              register={register}
              updateValue={updateValue}
              value={value}
            />
          );
        },
      },
    },
    {
      name: "percent_of_sells",
      label: t("Pourcentage des Ventes"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "display_index",
      label: t("Indice des ventes"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "popularity_index",
      label: t("Indice de popularité"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "iles_popularity",
      label: t("Zone Popularité Popser"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "bcg_popularity",
      label: t("Zone Popularité Boston"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "unit_gross_margin_vat_exluded",
      label: t("MB U"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              nameColumn={`unit_gross_margin_vat_exluded${tableMeta.rowData[0]}`}
              control={control}
              onChangeField={onChangeField}
              tableMeta={tableMeta}
              errors={errors}
              suffix={t("€")}
              register={register}
              updateValue={updateValue}
              value={value}
              disabled
            />
          );
        },
      },
    },
    {
      name: "total_unit_gross_margin_vat_exluded",
      label: t("MB HT"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FieldWithSuffix
              nameColumn={`total_unit_gross_margin_vat_exluded${tableMeta.rowData[0]}`}
              control={control}
              onChangeField={onChangeField}
              tableMeta={tableMeta}
              errors={errors}
              suffix={t("€")}
              register={register}
              updateValue={updateValue}
              value={value}
              disabled
            />
          );
        },
      },
    },
    {
      name: "iles_profitability",
      label: t("Rentabilité Popser"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "bcg_profitability",
      label: t("Rentabilité de Boston"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "iles_rank",
      label: t("Classement Popser"),
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "bcg_rank",
      label: t("Classement Boston"),
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
  ];
  const [columnsState, setColumnsState] = useState(columns);

  useEffect(() => {
    if (range.real_mode === false) {
      const cols = columns;
      cols[4] = {
        name: "quantity_sold",
        label: t("Quantités vendues"),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <FieldWithSuffix
                nameColumn="quantity_sold"
                control={control}
                onChangeField={onChangeQuantitySold}
                tableMeta={tableMeta}
                errors={errors}
                disabled
                suffix=""
                register={register}
                updateValue={updateValue}
                value={value}
              />
            );
          },
        },
      };
      cols.splice(4, 0, {
        name: "poll_quantity",
        label: t("Quantité sondage"),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <FieldWithSuffix
                isEdit
                nameColumn={`poll_quantity${tableMeta.rowData[0]}`}
                control={control}
                onChangeField={handleChangePollQuantity}
                tableMeta={tableMeta}
                errors={errors}
                suffix=""
                register={register}
                updateValue={updateValue}
                value={value}
                type="number"
              />
            );
          },
        },
      });
      setColumnsState(cols);
    } else {
      setColumnsState(columns);
    }
  }, [range.real_mode]);

  useEffect(() => {
    if (popser) {
      // FIXME : This is a quick but dirty way of handling rank changes. If columns array shape change, this will be fucked up.
      const cols = columns;
      cols[cols.length - 2] = {
        name: "iles_rank",
        label: t("Classement Popser"),
        options: {
          filter: true,
          sort: true,
          display: popser.isPopser,
        },
      };

      cols[cols.length - 1] = {
        name: "bcg_rank",
        label: t("Classement Boston"),
        options: {
          filter: true,
          sort: true,
          display: !popser.isPopser,
        },
      };
      setColumnsState(cols);
    }
  }, [popser]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <MuiThemeProvider theme={getTableTheme()}>
            <MUIDataTable
              data={listRangeProduct}
              columns={columnsState}
              options={options}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </>
  );
};

export default RangeProductsRealMode;
