/* eslint-disable */
import React, { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import {
  Button,
  Drawer,
  Fab as MuiFab,
  ListItem,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { Palette as PaletteIcon } from "@material-ui/icons";

const Paper = styled(MuiPaper)(spacing);

const Demo = styled(Paper)`
  cursor: pointer;
  text-align: center;
  box-shadow: none;
`;

const Fab = styled(MuiFab)`
  position: fixed;
  right: ${(props) => props.theme.spacing(8)}px;
  bottom: ${(props) => props.theme.spacing(8)}px;
  z-index: 1;
`;

const Wrapper = styled.div`
  width: 240px;
  overflow-x: hidden;
`;

const Screenshot = styled.img`
  max-width: 100%;
  height: auto;
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  display: block;
`;

const Heading = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.palette.common.black};
  background: ${(props) => props.theme.palette.common.white};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

const Spacer = styled.div(spacing);

let Demos = function ({ dispatch }) {
  return (
    <Wrapper>
      <Heading>Select a demo</Heading>
      <Spacer my={2} mx={4}>
        <Button
          href="https://material-ui.com/store/items/material-app/"
          variant="contained"
          color="primary"
          size="large"
          target="_blank"
          fullWidth
        >
          Purchase Now
        </Button>
      </Spacer>
    </Wrapper>
  );
};

function Settings() {
  const [state, setState] = useState({
    isOpen: false,
  });

  const toggleDrawer = (open) => () => {
    setState({ ...state, isOpen: open });
  };

  return (
    <>

    </>
  );
}

export default Settings;
