import { OFFERS_URL } from "../constants/urls";
import apiClient from "./apiClient";

export const addOffer = async (project_id, accessToken, offer) => {
  let result;
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${OFFERS_URL}`;
  try {
    const res = await apiClient({
      method: "post",
      url,
      headers,
      data: {
        project: project_id,
        ...offer,
      },
    });
    if (res.status === 201) {
      result = { result: res.data, isError: false };
    }
  } catch (error) {
    result = { result: null, isError: true };
  }
  return result;
};

export default addOffer;
