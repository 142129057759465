// Returns 0 if null or NaN
export const getParsedFloatNumber = (value) =>
  parseFloat(value) || parseFloat(0);
// Nombre de sondés au total || nombre de clients / an
export function multipleCustomDaysByYear(numberCustomByDay, daysByYear) {
  const result = numberCustomByDay * daysByYear;
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}
// Nombre de choix des sondés dans cette gamme
export function totalQuantityCheck(listQuantityCheck) {
  let result = 0;
  if (listQuantityCheck) {
    if (listQuantityCheck.length > 0) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      result = Array.from(listQuantityCheck).reduce(reducer);
    }
  }
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}
// Total Nombre de jours de présentation
export function totalPresentationDays(listPresentationDays) {
  let result = 0;
  if (listPresentationDays) {
    if (listPresentationDays.length > 0) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      result = Array.from(listPresentationDays).reduce(reducer);
    }
  }
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}
// Indice de présentation
export function presentationIndex(presentationDays, listPresentationDays) {
  const result = presentationDays / totalPresentationDays(listPresentationDays);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Nombre de choix des sondés dans cette gamme || nombre de clients / an
export function numberCustomByYearOnNumberOptionsCheckTotal(
  numberCustomByDay,
  daysByYear,
  listQuantityCheck,
  number_check_total
) {
  const result =
    (multipleCustomDaysByYear(numberCustomByDay, daysByYear) *
      totalQuantityCheck(listQuantityCheck)) /
    number_check_total;
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// SOMME DE Marge Brute Totale HT
export function totalGrossMarginHtTotal(listGrossMarginHtTotal) {
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const result = listGrossMarginHtTotal.reduce(reducer);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}
// Rentabilité
export function rentabilityRange(
  listGrossMarginHtTotal,
  numberCustomByDay,
  listQuantityCheck,
  number_check_total,
  daysByYear
) {
  const result =
    totalGrossMarginHtTotal(listGrossMarginHtTotal) *
    numberCustomByYearOnNumberOptionsCheckTotal(
      numberCustomByDay,
      daysByYear,
      listQuantityCheck,
      number_check_total
    );
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// PV HT
export function calculPvHT(tvaRange, priceTTC, tva) {
  let result = 0;

  if (tvaRange) {
    result = priceTTC / (1 + tva / 100);
  } else {
    result = priceTTC / (1 + tvaRange / 100);
  }
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}
// Marge Brute Unitaire HT
export function unitGrossMarginHT(rawCostHT, tvaRange, priceTTC, tva) {
  const result = calculPvHT(tvaRange, priceTTC, tva) - rawCostHT;
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Rentabilité Ecole de Boston
export function rentabilityBostonSchool(
  rawCostHT,
  tvaRange,
  priceTTC,
  tva,
  listGrossMarginHtTotal,
  numberCustomByDay,
  numberOptionCheckRange,
  number_check_total,
  daysByYear
) {
  let result = null;
  if (
    unitGrossMarginHT(rawCostHT, tvaRange, priceTTC, tva) <
    rentabilityRange(
      listGrossMarginHtTotal,
      numberCustomByDay,
      numberOptionCheckRange,
      number_check_total,
      daysByYear
    )
  ) {
    result = "basse";
  } else {
    result = "haute";
  }

  return result;
}
// Popularité
export function popularityRange(countProduct) {
  const result = 0.7 / countProduct;

  return result;
}

// Quantités vendues
export function calculQuantitySold(
  quantityCheck,
  numberCustomByDay,
  daysByYear,
  number_check_total,
  listQuantityCheck
) {
  const customByYearOnNumberOptionsCheckTotal = numberCustomByYearOnNumberOptionsCheckTotal(
    numberCustomByDay,
    daysByYear,
    listQuantityCheck,
    number_check_total
  );

  const result =
    (quantityCheck * customByYearOnNumberOptionsCheckTotal) /
    totalQuantityCheck(listQuantityCheck);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// %age des Ventes
export function percentageSold(
  quantityCheck,
  numberCustomByDay,
  daysByYear,
  number_check_total,
  listQuantityCheck
) {
  const result =
    (calculQuantitySold(
      quantityCheck,
      numberCustomByDay,
      daysByYear,
      number_check_total,
      listQuantityCheck
    ) /
      numberCustomByYearOnNumberOptionsCheckTotal(
        numberCustomByDay,
        daysByYear,
        listQuantityCheck,
        number_check_total
      )) *
    100;
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Indice de popularité
export function popularityIndex(
  quantityCheck,
  numberCustomByDay,
  daysByYear,
  number_check_total,
  listQuantityCheck,
  presentationDays,
  listPresentationDays
) {
  const percentageS =
    percentageSold(
      quantityCheck,
      numberCustomByDay,
      daysByYear,
      number_check_total,
      listQuantityCheck
    ) / 100;
  const presentationDaysIndex = presentationIndex(
    presentationDays,
    listPresentationDays
  );
  const result = percentageS / presentationDaysIndex;

  return result;
}

// Popularité Ecole de Boston
export function popularityBostonSchool(
  quantityCheck,
  numberCustomByDay,
  daysByYear,
  number_check_total,
  listQuantityCheck,
  countProduct
) {
  let result = null;
  const percentageS = percentageSold(
    quantityCheck,
    numberCustomByDay,
    daysByYear,
    number_check_total,
    listQuantityCheck
  );
  if (percentageS < popularityRange(countProduct)) {
    result = "basse";
  } else result = "haute";

  return result;
}
// Methode Classement de Boston
export function methodBoston(
  quantityCheck,
  numberCustomByDay,
  daysByYear,
  numberOptionCheckRange,
  number_check_total,
  listQuantityCheck,
  countProduct,
  rawCostHT,
  tvaRange,
  priceTTC,
  tva,
  listGrossMarginHtTotal
) {
  let result;
  const vPopularityBoston = popularityBostonSchool(
    quantityCheck,
    numberCustomByDay,
    daysByYear,
    numberOptionCheckRange,
    number_check_total,
    listQuantityCheck,
    countProduct
  );

  const vRentabilityBostonSchool = rentabilityBostonSchool(
    rawCostHT,
    tvaRange,
    priceTTC,
    tva,
    listGrossMarginHtTotal,
    numberCustomByDay,
    numberOptionCheckRange,
    number_check_total,
    daysByYear
  );

  if (vPopularityBoston === "basse") {
    if (vRentabilityBostonSchool === "basse") {
      result = "Dog (Poids Mort)";
    } else {
      result = "Puzzle (Plat Dilemme)";
    }
  } else if (vPopularityBoston !== "basse") {
    if (vRentabilityBostonSchool === "basse") {
      result = "PlowHorse (Vache à Lait)";
    } else {
      result = "Star (Plat Etoile)";
    }
  }
  return result;
}

// Total produit vendu
export function sumQuantitySold(listQuantitySold) {
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const result = listQuantitySold.reduce(reducer);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Total prix de vente TTC
export function sumSellingPriceTtc(listSellingPriceTtc) {
  let result = 0;
  if (listSellingPriceTtc.length > 0) {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    result = listSellingPriceTtc.reduce(reducer);
    if (Number.isNaN(Number(result))) {
      return 0;
    }
  }

  return result;
}

// Chiffre D'affaire TTC
export function turnoverTTC(listQuantitySold, listSellingPriceTtc) {
  let result = 0;
  let i = 0;
  listQuantitySold.forEach((element) => {
    result += element * listSellingPriceTtc[i];
    i += 1;
  });
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// TOTAL DE PV HT
export function sumPvHt(listPvHt) {
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const result = listPvHt.reduce(reducer);

  return result;
}

// Chiffre D'affaire HT
export function turnoverHT(listQuantitySold, listPvHt) {
  let result = 0;
  let i = 0;
  listQuantitySold.forEach((element) => {
    result += element * listPvHt[i];
    i += 1;
  });
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Consomation de Matières HT
export function consumptionMaterialsHT(listQuantitySold, listRawCostHT) {
  let result = 0;
  let i = 0;
  listQuantitySold.forEach((element) => {
    result += element * listRawCostHT[i];
    i += 1;
  });
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Marge brute HT
export const grossMargin = (listQuantitySold, listRawCostHT, listPvHt) => {
  const result =
    turnoverHT(listQuantitySold, listPvHt) -
    consumptionMaterialsHT(listQuantitySold, listRawCostHT);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
};

// coefficient multiplicateur gamme
export function multiplyingCoefficientRange(
  listQuantitySold,
  listRawCostHT,
  listPvHt
) {
  const result =
    turnoverHT(listQuantitySold, listPvHt) /
    consumptionMaterialsHT(listQuantitySold, listRawCostHT);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result || 0;
}

// Ticket moyen gamme HT
export function mediumRangeTicket(
  numberCustomByDay,
  daysByYear,
  listQuantityCheck,
  number_check_total,
  listQuantitySold,
  listPvHt
) {
  const result =
    turnoverHT(listQuantitySold, listPvHt) /
    numberCustomByYearOnNumberOptionsCheckTotal(
      numberCustomByDay,
      daysByYear,
      listQuantityCheck,
      number_check_total
    );
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Conso. Matières HT
export function consumptionMaterials(
  numberCustomByDay,
  daysByYear,
  numberOptionCheckRange,
  number_check_total,
  listQuantitySold,
  listRawCostHT
) {
  const result =
    consumptionMaterialsHT(listQuantitySold, listRawCostHT) /
    numberCustomByYearOnNumberOptionsCheckTotal(
      numberCustomByDay,
      daysByYear,
      numberOptionCheckRange,
      number_check_total
    );
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// ratio matière moyen
export function averageMaterialRatio(
  listQuantitySold,
  listRawCostHT,
  listPvHt
) {
  let result =
    1 / multiplyingCoefficientRange(listQuantitySold, listRawCostHT, listPvHt);
  if (result === "Infinity") {
    result = 0;
  }
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Prix moyen offert TTC
export function averagePriceOfferedTTC(listSellingPriceTtc, countProduct) {
  const result = sumSellingPriceTtc(listSellingPriceTtc) / countProduct;
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Prix Moyen demandé
export function averagePriceRequested(
  listQuantitySold,
  listSellingPriceTtc,
  numberCustomByDay,
  daysByYear,
  listQuantityCheck,
  number_check_total
) {
  const vTurnoverTTC = turnoverTTC(listQuantitySold, listSellingPriceTtc);
  const vNumberCustomByYearOnNumberOptionsCheckTotal = numberCustomByYearOnNumberOptionsCheckTotal(
    numberCustomByDay,
    daysByYear,
    listQuantityCheck,
    number_check_total
  );
  const result = vTurnoverTTC / vNumberCustomByYearOnNumberOptionsCheckTotal;
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return Math.round((result + Number.EPSILON) * 100) / 100;
}

// RANGE PRODUCT

// Coefficient produit
export function multiplyingCoefficientProduct(
  rawCostHT,
  tvaRange,
  priceTTC,
  tva
) {
  const result = rawCostHT / calculPvHT(tvaRange, priceTTC, tva);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Cser indicatif produit
export function cserProduct(CserRange, rawCostHT, tvaRange, priceTTC, tva) {
  const result =
    CserRange *
    multiplyingCoefficientProduct(rawCostHT, tvaRange, priceTTC, tva);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Marge Brute Totale HT
export function grossMarginHtTotal(
  rawCostHT,
  tvaRange,
  priceTTC,
  tva,
  quantityCheck,
  numberCustomByDay,
  daysByYear,
  numberOptionCheckRange,
  number_check_total,
  listQuantityCheck
) {
  const result =
    unitGrossMarginHT(rawCostHT, tvaRange, priceTTC, tva) *
    calculQuantitySold(
      quantityCheck,
      numberCustomByDay,
      daysByYear,
      numberOptionCheckRange,
      number_check_total,
      listQuantityCheck
    );
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// NEW CALCUL METHOD
export function toFixedNumber(num, digits, base) {
  const pow = (base || 10) ** digits;
  return Math.round(num * pow) / pow;
}

// %age des Ventes

export function getPercentageSold(quantitySold, totalQuantitySold) {
  const result = getParsedFloatNumber(quantitySold / totalQuantitySold);
  return toFixedNumber(result, 2);
}

// Total produit vendu
export function getSumQuantitySold(listQuantitySold) {
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const result = listQuantitySold.reduce(reducer);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}
// Cser indicatif produit

export function getCserProduct(CserRange, multiplyingCoefficient) {
  const result = CserRange * multiplyingCoefficient;
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return getParsedFloatNumber(result);
}
export function getAverageUnitGrossMargin(listUnitGrossMargin) {
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  return toFixedNumber(
    getParsedFloatNumber(
      listUnitGrossMargin.reduce(reducer) / listUnitGrossMargin.length
    ),
    2
  );
}

// LIMITS METHODs POPSER

export function getIlesLowerLimitsPopularity(variationPopularity) {
  const result = toFixedNumber(
    getParsedFloatNumber(1 - variationPopularity / 100),
    2
  );
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}
export function getIlesAverageLimitsPopularity(variationPopularity) {
  const result = toFixedNumber(
    getParsedFloatNumber(1 - variationPopularity / 100),
    2
  );
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}
export function getIlesHigherLimitsPopularity(variationPopularity) {
  const result = toFixedNumber(
    getParsedFloatNumber(1 + variationPopularity / 100),
    2
  );
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

export function getIlesLowerLimitsRentability(
  averageUnitGrossMargin,
  variationRentability
) {
  const result = toFixedNumber(
    getParsedFloatNumber(averageUnitGrossMargin) *
      getParsedFloatNumber(1 - variationRentability / 100),
    2
  );
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}
export function getIlesAverageLimitsRentability(
  averageUnitGrossMargin,
  variationRentability
) {
  const result = toFixedNumber(
    getParsedFloatNumber(averageUnitGrossMargin) *
      getParsedFloatNumber(1 - variationRentability / 100),
    2
  );
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}
export function getIlesHigherLimitsRentability(
  averageUnitGrossMargin,
  variationRentability
) {
  const result = toFixedNumber(
    getParsedFloatNumber(averageUnitGrossMargin) *
      getParsedFloatNumber(1 + variationRentability / 100),
    2
  );
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}
// LIMITS METHODs BOSTON

export function getBostonLowerLimitsPopularity(numberRangeProducts) {
  const result = getParsedFloatNumber(0.7 / numberRangeProducts) * 100;
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return toFixedNumber(result, 2);
}
export function getBostonHigherLimitsPopularity(bostonLowerLimitsRentability) {
  const result = getParsedFloatNumber(bostonLowerLimitsRentability) * 100;
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return toFixedNumber(result, 2);
}

export function getBostonLowerLimitsRentability(
  sumOfTotalGrossMargin,
  sumOfQuantitiesSold
) {
  const result = getParsedFloatNumber(
    getParsedFloatNumber(sumOfTotalGrossMargin) /
      getParsedFloatNumber(sumOfQuantitiesSold)
  );
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return toFixedNumber(result, 2);
}
export function getBostonHigherLimitsRentability(bostonLowerLimitsRentability) {
  const result = getParsedFloatNumber(bostonLowerLimitsRentability);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return toFixedNumber(result, 2);
}

// Cser Range

export const getCserRange = (coefficentK, multiplyingCoefficient) => {
  const result = multiplyingCoefficient * coefficentK;
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return toFixedNumber(getParsedFloatNumber(result), 2);
};

// Marge Brute Totale HT
export function getGrossMarginHtTotal(grossMarginUnit, quantitySold) {
  const result = grossMarginUnit * quantitySold;

  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return toFixedNumber(getParsedFloatNumber(result, 10), 2);
}

// Marge Brute Unit HT
export function getGrossMarginHtUnit(sellingPriceHt, rawMaterialCost) {
  const result = sellingPriceHt - rawMaterialCost;

  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return toFixedNumber(getParsedFloatNumber(result, 10), 2);
}

// SOMME DE Marge Brute Totale HT
export function getSumGrossMarginHtTotal(listTotalGrossMarginHtTotal) {
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const result = listTotalGrossMarginHtTotal.reduce(reducer);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// SOMME DE Marge Brute par unité Totale HT
export function getSumGrossMarginHtUnit(listTotalGrossMarginHtUnit) {
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const result = listTotalGrossMarginHtUnit.reduce(reducer);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Rentabilité Ecole de Boston
export function getRentabilityBostonSchool(
  bostonLowerLimitsRentability,
  unitGrossMargin
) {
  let result = null;
  if (unitGrossMargin < bostonLowerLimitsRentability) {
    result = "basse";
  } else {
    result = "haute";
  }

  return result;
}

// Popularité Ecole de Boston
export function getPopularityBostonSchool(
  bostonLowerLimitsPopularity,
  percentageSell
) {
  let result = null;
  if (percentageSell < bostonLowerLimitsPopularity) {
    result = "basse";
  } else {
    result = "haute";
  }

  return result;
}

// Indice de popularité
export function getPopularityIndex(percentageSell, display_index) {
  const percentageS = percentageSell / 100;
  const result = percentageS / display_index;

  return toFixedNumber(result, 2);
}

// Methode Classement de Boston
export function getBostonRank(popularityBoston, rentabilityBoston) {
  let result;

  if (popularityBoston === "basse") {
    if (rentabilityBoston === "basse") {
      result = "Dog (Poids Mort)";
    } else {
      result = "Puzzle (Plat Dilemme)";
    }
  } else if (popularityBoston !== "basse") {
    if (rentabilityBoston === "basse") {
      result = "PlowHorse (Vache à Lait)";
    } else {
      result = "Star (Plat Etoile)";
    }
  }
  return result;
}

// Popularité Popser
export function getPopularityPopser(
  popularityIndx,
  iles_higher_limits_popularity,
  iles_lower_limits_popularity
) {
  let result = null;
  if (popularityIndx < iles_lower_limits_popularity) {
    result = "basse";
  } else if (popularityIndx < iles_higher_limits_popularity) {
    result = "moyenne";
  } else {
    result = "haute";
  }

  return result;
}

// rentabilité Popser
export function getRentabilityPopser(
  iles_lower_limits_rentability,
  iles_higher_limits_rentability,
  unitGrossMargin
) {
  let result = null;

  if (unitGrossMargin < iles_lower_limits_rentability) {
    result = "basse";
  } else if (unitGrossMargin < iles_higher_limits_rentability) {
    result = "moyenne";
  } else {
    result = "haute";
  }

  return result;
}

// classement Popser
export function getPopserRank(rentabilityPopser, popularityPopser) {
  let result = null;

  if (popularityPopser === "basse") {
    if (rentabilityPopser === "basse") {
      result = "Poids Mort";
    } else if (rentabilityPopser === "moyenne") {
      result = "Plat à Redynamiser";
    } else {
      result = "Plat Dilemme";
    }
  } else if (popularityPopser === "moyenne") {
    if (rentabilityPopser === "basse") {
      result = "Plat à Rentabiliser";
    } else if (rentabilityPopser === "moyenne") {
      result = "Plat Moyen";
    } else {
      result = "Le Rentable";
    }
  } else if (rentabilityPopser === "basse") {
    result = "Vache à Lait";
  } else if (rentabilityPopser === "moyenne") {
    result = "Le Populaire";
  } else {
    result = "Plat Etoile";
  }

  return result;
}

// Indice de présentation
export function getDisplayIndex(displayNumber, totalDisplayNumber) {
  const result = displayNumber / totalDisplayNumber;
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return toFixedNumber(result, 2);
}
// Total de nombre de présentation
export function getTotalDisplayNumber(listDisplayNumber) {
  let result = 0;
  if (listDisplayNumber) {
    if (listDisplayNumber.length > 0) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      result = Array.from(listDisplayNumber).reduce(reducer);
    }
  }
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

export const getCustomersAYearUsingCustomersADayAndNumberOfDaysAYear = (
  customers_by_day,
  days_a_year
) => {
  const parsed_customers_by_day = toFixedNumber(
    getParsedFloatNumber(customers_by_day),
    2
  );
  const parsed_days_a_year = toFixedNumber(
    getParsedFloatNumber(days_a_year),
    2
  );
  return toFixedNumber(
    getParsedFloatNumber(parsed_customers_by_day * parsed_days_a_year),
    2
  );
};

export const getCustomersChoicesByYear = (
  customers_by_year,
  count_of_choices_in_range,
  surveyed_customers
) => {
  const parsed_customers_by_year = toFixedNumber(
    getParsedFloatNumber(customers_by_year),
    2
  );
  const parsed_count_of_choices_in_range = toFixedNumber(
    getParsedFloatNumber(count_of_choices_in_range),
    2
  );
  const parsed_surveyed_customers = toFixedNumber(
    getParsedFloatNumber(surveyed_customers),
    2
  );

  return toFixedNumber(
    (parsed_customers_by_year * parsed_count_of_choices_in_range) /
      parsed_surveyed_customers,
    2
  );
};

export const getNumberOptionCheckRange = (listPollQuantity) => {
  let result = 0;
  if (listPollQuantity) {
    if (listPollQuantity.length > 0) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      result = Array.from(listPollQuantity).reduce(reducer);
    }
  }
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
};
/*
Calcul Boston :
=IF(A10="";"";IF(M10="basse";
                    IF(Q10="basse";"Dog (Poids Mort)";"Puzzle (Plat Dilemme)");
                    IF(Q10="basse";"PlowHorse (Vache à Lait)";"Star (Plat Etoile)")))

REGLE CLASSEMENT BOSTON :
IF (A10 != "") {
    IF(M10=="basse"){
        IF(Q10=="basse") {
            Dog (Poids Mort)
        }ELSE {
            Puzzle (Plat Dilemme)
        }
    }ELSE {
        IF(Q10=="basse") {
            PlowHorse (Vache à Lait)
        }ELSE {
            Star (Plat Etoile)
        }
    }
}


REGLE A10 :
Nom du plat

REGLE M10 :
Popularité école de boston
=IF(A10="";"";IF(I10<$T$22;"basse";"haute"))
    IF(% age des ventes < (0,7 / Nombre de produit)){
        IF(Q10=="basse") {
            Dog (Poids Mort)
        }ELSE {
            Puzzle (Plat Dilemme)
        }
    }ELSE {
        IF(Q10=="basse") {
            PlowHorse (Vache à Lait)
        }ELSE {
            Star (Plat Etoile)
        }
    }

REGLE Q10 :
Rentabilité école de boston
=IF(A11="";"";IF(N11<$T$26;"basse";"haute"))
IF(Marge brute unitaire HT < (SUM(marge brute Total HT) / Nombre de client par an avec nombre des choix sondés dans la gamme)) {
    basse
}else{
    "haute"
}





*/
