import React from "react";
import styled from "styled-components";

import { Avatar as MuiAvatar } from "@material-ui/core";
import PropTypes from "prop-types";

const Avatar = styled(MuiAvatar)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  width: 33px;
  height: 33px;
  font-size: 15px;
`;

function AvatarComponent({ initial }) {
  const min = 0;
  const max = 6;
  const rand = parseInt(min + Math.random() * (max - min), 10);
  const colors = [
    "brown",
    "orange",
    "#1976d2",
    "black",
    "grey",
    "purple",
    "#48901b",
  ];

  return <Avatar style={{ backgroundColor: colors[rand] }}>{initial}</Avatar>;
}

export default AvatarComponent;

AvatarComponent.propTypes = {
  initial: PropTypes.string.isRequired,
};
