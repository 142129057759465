import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { fetchRangeProducts } from "../../redux/actions/rangeProductActions";
import {
  getLowMiddleSlice,
  getLowSlice,
  getHighMiddleSlice,
  getTopSlice,
  getNumberOfProductsInFirstSlice,
  getNumberOfProductsInSecondSlice,
  getNumberOfProductsInThirdSlice,
  getSecondPrincipleInterpretation,
} from "../../services/range/RangeBalanceService";
import NotEnoughData from "./NotEngoughData";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  margin: {
    margin: "12px",
  },
  padding12: {
    padding: "12px",
  },
});

const SecondOmnesPrinciple = ({ range }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const rangeProducts = useSelector(
    (state) => state.rangeProducts.rangeProducts
  );
  const user = auth.stsTokenManager;
  const allProductPrices = rangeProducts.map((rangeProduct) =>
    rangeProduct.product.ttc_price != null ? rangeProduct.product.ttc_price : 0
  );

  useEffect(() => {
    if (user.accessToken) {
      dispatch(fetchRangeProducts(range.id, user.accessToken));
    }
  }, [user]);

  const secondPrincipleInterpretation = getSecondPrincipleInterpretation({
    allProductPrices,
  });

  return (
    <Grid container>
      <Grid container item xs={6} className={classes.padding12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Tranche basse</TableCell>
                <TableCell colspan={2} align="center">
                  Tranche médiane
                </TableCell>
                <TableCell align="right">Tranche supérieure</TableCell>
              </TableRow>
            </TableHead>

            {rangeProducts.length > 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    {`${getLowSlice({ allProductPrices })} €`}
                  </TableCell>
                  <TableCell align="center">
                    {`${getLowMiddleSlice({ allProductPrices })} €`}
                  </TableCell>
                  <TableCell align="center">
                    {`${getHighMiddleSlice({ allProductPrices })} €`}
                  </TableCell>
                  <TableCell align="right">
                    {`${getTopSlice({ allProductPrices })} €`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    {getNumberOfProductsInFirstSlice({ allProductPrices })}
                    &nbsp;produits
                  </TableCell>

                  <TableCell colspan={2} align="center">
                    {getNumberOfProductsInSecondSlice({ allProductPrices })}
                    &nbsp;produits
                  </TableCell>
                  <TableCell align="right">
                    {getNumberOfProductsInThirdSlice({ allProductPrices })}
                    &nbsp;produits
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <NotEnoughData />
            )}
          </Table>
        </TableContainer>
      </Grid>
      <Grid container xs={6} className={classes.padding12}>
        <Grid item xs={12}>
          <p>Interprétation</p>
          <p>
            {secondPrincipleInterpretation.dispersion}
            <br />
            {secondPrincipleInterpretation.productBalance}
            {
              secondPrincipleInterpretation.writtenQuantityOfProductInSecondSliceRecommanded
            }
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
};

SecondOmnesPrinciple.propTypes = {
  range: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    products: PropTypes.shape({}),
    sales_revenue_TTC: PropTypes.number,
    sales_revenue_HT: PropTypes.number,
    material_consumption_HT: PropTypes.number,
    gross_margin: PropTypes.number,
    average_price_offered_TTC: PropTypes.number,
    average_material_ratio: PropTypes.number,
    range_multiplier_coefficient: PropTypes.number,
    coefficient_k: PropTypes.number,
    cser_range: PropTypes.string,
    average_price_requested_TTC: PropTypes.number,
    variation_popularity: PropTypes.number,
    variation_rentability: PropTypes.number,
    price_TTC: PropTypes.number,
    quantity_sold: PropTypes.number,
    quantity_check: PropTypes.number,
    number_days_presentation: PropTypes.number,
    number_check_total: PropTypes.number,
    number_option_check_range: PropTypes.number,
    number_day_by_year: PropTypes.number,
    number_custom_by_day: PropTypes.number,
    number_custom_by_year_number_check_total: PropTypes.number,
    number_custom_by_year_number_option_check_range: PropTypes.number,
    tva_rate: PropTypes.number,
  }).isRequired,
};

export default SecondOmnesPrinciple;
