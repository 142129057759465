import React from "react";

import { makeStyles, Typography, TableRow, TableCell } from "@material-ui/core";
import { Trans } from "react-i18next";
import { useForm } from "react-hook-form";

import { useSelector } from "react-redux";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";

const useStyles = makeStyles(() => ({
  titleHeaderCell: {
    color: "#1876d2",
  },
  borderTop: {
    borderTop: "solid 5px #1876d2",
  },
  center: {
    textAlign: "center",
  },
}));

const KIndicator = () => {
  const { register, control, errors } = useForm();
  const cser_k = useSelector((state) => state.cser.computed_cser.cser_k);
  const classes = useStyles();

  return (
    <TableRow key="yearly_indicators">
      <TableCell component="th" scope="row" align="center" />
      <TableCell
        className={classes.borderTop}
        colSpan={9}
        component="th"
        scope="row"
        align="center"
      >
        <Typography
          variant="h4"
          gutterBottom
          display=""
          className={classes.titleHeaderCell}
        >
          <Trans i18nKey="k = CSER/C = ">k = CSER/C =</Trans>
        </Typography>
        <FieldWithSuffix
          disabled
          name="k_indicator"
          control={control}
          errors={errors}
          suffix=""
          value={cser_k}
          register={register}
        />
      </TableCell>
      <TableCell component="th" scope="row" align="center" />
    </TableRow>
  );
};

export default KIndicator;
