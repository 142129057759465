import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Typography,
  makeStyles,
  Modal,
  Fade,
  Backdrop,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import UpdateIcon from "@material-ui/icons/Update";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import FunctionsIcon from "@material-ui/icons/Functions";
import { useSelector } from "react-redux";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";
import {
  copyNewVariableCostRateToSerForecastSimulationPercentageOfVariableLoadRate,
  handleChangePercentageReductionMaterialConsumption,
} from "../../services/cser/cserInteractions";

const useStyles = makeStyles({
  margin: {
    marginTop: "12px",
  },
  input: {
    width: "90px!important",
  },
  gridInput: {
    borderLeft: "solid 1px",
    paddingLeft: "30px",
    borderBottom: "solid 1px",
  },
  gridTitle: {
    borderBottom: "solid 1px",
    paddingBottom: "11px",
    paddingTop: "21px",
  },
  tableEqualWidth: {
    "& th": { width: "20%!important" },
  },
  tableEqualWidthTotal: {
    "& th": { width: "20%!important", border: "0px!important" },
    "& td": { border: "0px!important" },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "5px",
    border: "solid",
    width: "400px",
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  card: {
    paddingBottom: "0px!important",
  },
  gridColor: {
    backgroundColor: "#ffffff",
  },
  componentSig: {
    marginBottom: "0px!important",
  },
  noPadding: {
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  noPaddingTh: {
    "& th": {
      padding: "0px",
    },
  },
  paddingThTd: {
    "& th": {
      paddingTop: "5px!important",
      paddingBottom: "5px!important",
    },
    "& td": {
      paddingTop: "5px!important",
      paddingBottom: "5px!important",
    },
  },
  floatRight: {
    float: "right",
  },
});
const NewVariableCostsRate = ({ setOpenModal, openModal }) => {
  const { register, control, errors } = useForm();
  const classes = useStyles();
  const { ser_forecast } = useSelector((state) => state.cser.computed_cser);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={classes.modal}
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal} className={classes.paper}>
        <div className={classes.paper}>
          <Typography variant="h5" gutterBottom display="inline">
            <Trans i18nKey="tax">Calculer le nouveaux taux de CV :</Trans>
          </Typography>
          <FunctionsIcon className={classes.floatRight} />

          <TableContainer component={Paper}>
            <Table
              className={
                ([classes.table, classes.tableEqualWidth],
                classes.disableField,
                classes.noPaddingThTd)
              }
              aria-label="simple table"
            >
              <TableBody className={classes.paddingThTd}>
                <TableRow key="percentage_reduction_material_consumption">
                  <TableCell
                    component="th"
                    scope="row"
                    align="right"
                    className={classes.borderRight}
                  >
                    <Typography variant="h9" gutterBottom display="inline">
                      <Trans i18nKey="% de baisse de la consommation de matières envisagée	">
                        % de baisse de la consommation de matières envisagée
                      </Trans>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <FieldWithSuffix
                      isEdit
                      name="percentage_reduction_material_consumption"
                      control={control}
                      errors={errors}
                      suffix={t("%")}
                      value={
                        ser_forecast.percentage_reduction_material_consumption
                      }
                      onChangeField={
                        handleChangePercentageReductionMaterialConsumption
                      }
                      register={register}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key="new_variable_costs_rate">
                  <TableCell
                    component="th"
                    scope="row"
                    align="right"
                    className={classes.borderRight}
                  >
                    <Typography variant="h9" gutterBottom display="inline">
                      <Trans i18nKey="Nouveau Tx de CV	">Nouveau Tx de CV</Trans>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <FieldWithSuffix
                      disabled
                      name="new_variable_costs_rate"
                      control={control}
                      errors={errors}
                      suffix={t("%")}
                      value={ser_forecast.new_variable_costs_rate}
                      register={register}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key="copy_to_cell">
                  <TableCell colSpan={2} align="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={() =>
                        copyNewVariableCostRateToSerForecastSimulationPercentageOfVariableLoadRate(
                          ser_forecast.new_variable_costs_rate
                          // eslint-disable-next-line prettier/prettier
                        )}
                      startIcon={<UpdateIcon />}
                    >
                      <Trans i18nKey="Utiliser ce taux">Utiliser ce taux</Trans>
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Fade>
    </Modal>
  );
};

NewVariableCostsRate.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
};
export default NewVariableCostsRate;
