import {
  getMaterialCosts,
  getTotalMaterialCost,
  getTotalPricePortion,
  getMultiplyingFactor,
  getSellingPriceHTByMultiplyingFactor,
  getSellingPriceTTC,
  getTvaByRate,
  getTvaBySellingPriceTTC,
  getSellingPriceHTBySellingPriceTTC,
  getTotalEnergy,
  getPerUnitEnergy,
} from "./TechnicalSheetCalculIndicatorService";
import { store } from "../../redux/store";

export const interactionExtraCharge = (extraCharge) => {
  const floatExtraCharge = parseFloat(extraCharge);
  const percentExtraCharge = floatExtraCharge / 100;

  const state = store.getState();
  let { technicalSheet } = state.technicalSheet;
  const { product } = state.product;
  const percentTvaRate = parseFloat(technicalSheet.tvaRate.value) / 100;

  const totalMaterialCost = getTotalMaterialCost(
    percentExtraCharge,
    parseFloat(technicalSheet.materialCosts.value)
  );
  const totalPricePortion = getTotalPricePortion(
    totalMaterialCost,
    product.number_produced
  );
  if (technicalSheet.multiplyingFactor.isInput) {
    const sellingPriceHT = getSellingPriceHTByMultiplyingFactor(
      totalPricePortion,
      parseFloat(technicalSheet.multiplyingFactor.value)
    );
    const tva = getTvaByRate(sellingPriceHT, percentTvaRate);
    const sellingPriceTTC = getSellingPriceTTC(sellingPriceHT, tva);
    technicalSheet = {
      ...technicalSheet,
      extraCharge: {
        ...technicalSheet.extraCharge,
        value: floatExtraCharge,
      },
      totalMaterialCost: {
        ...technicalSheet.totalMaterialCost,
        value: totalMaterialCost,
      },
      totalPricePortion: {
        ...technicalSheet.totalPricePortion,
        value: totalPricePortion,
      },
      sellingPriceTTC: {
        ...technicalSheet.sellingPriceTTC,
        value: sellingPriceTTC,
      },
      sellingPriceHT: {
        ...technicalSheet.sellingPriceHT,
        value: sellingPriceHT,
      },
      tva: {
        ...technicalSheet.tva,
        value: tva,
      },
    };
  } else {
    const sellingPriceHT = getSellingPriceHTBySellingPriceTTC(
      parseFloat(technicalSheet.sellingPriceTTC.value),
      percentTvaRate
    );
    const tva = getTvaBySellingPriceTTC(
      parseFloat(technicalSheet.sellingPriceTTC.value),
      sellingPriceHT
    );
    const multiplyingFactor = getMultiplyingFactor(
      sellingPriceHT,
      totalPricePortion
    );
    technicalSheet = {
      ...technicalSheet,
      extraCharge: {
        ...technicalSheet.extraCharge,
        value: floatExtraCharge,
      },
      totalMaterialCost: {
        ...technicalSheet.totalMaterialCost,
        value: totalMaterialCost,
      },
      totalPricePortion: {
        ...technicalSheet.totalPricePortion,
        value: totalPricePortion,
      },
      multiplyingFactor: {
        ...technicalSheet.multiplyingFactor,
        value: multiplyingFactor,
      },
      sellingPriceHT: {
        ...technicalSheet.sellingPriceHT,
        value: sellingPriceHT,
      },
      tva: {
        ...technicalSheet.tva,
        value: tva,
      },
    };
  }

  return technicalSheet;
};

export const interactionNumberUnitProduced = (numberUnitProduced) => {
  const state = store.getState();
  let { technicalSheet } = state.technicalSheet;
  const percentTvaRate = parseFloat(technicalSheet.tvaRate.value) / 100 || 0;

  const perUnitEnergy = getPerUnitEnergy(
    parseFloat(technicalSheet.totalEnergy.value),
    numberUnitProduced
  );
  const totalPricePortion = getTotalPricePortion(
    parseFloat(technicalSheet.totalMaterialCost.value),
    numberUnitProduced
  );
  if (technicalSheet.multiplyingFactor.isInput) {
    const sellingPriceHT = getSellingPriceHTByMultiplyingFactor(
      totalPricePortion,
      parseFloat(technicalSheet.multiplyingFactor.value)
    );
    const tva = getTvaByRate(sellingPriceHT, percentTvaRate);
    const sellingPriceTTC = getSellingPriceTTC(sellingPriceHT, tva);
    technicalSheet = {
      ...technicalSheet,
      totalPricePortion: {
        ...technicalSheet.totalPricePortion,
        value: totalPricePortion,
      },
      sellingPriceTTC: {
        ...technicalSheet.sellingPriceTTC,
        value: sellingPriceTTC,
      },
      sellingPriceHT: {
        ...technicalSheet.sellingPriceHT,
        value: sellingPriceHT,
      },
      tva: {
        ...technicalSheet.tva,
        value: tva,
      },
      perUnitEnergy: {
        ...technicalSheet.perUnitEnergy,
        value: perUnitEnergy,
      },
    };
  } else {
    const sellingPriceHT = getSellingPriceHTBySellingPriceTTC(
      parseFloat(technicalSheet.sellingPriceTTC.value),
      percentTvaRate
    );
    const tva = getTvaBySellingPriceTTC(
      parseFloat(technicalSheet.sellingPriceTTC.value),
      sellingPriceHT
    );
    const multiplyingFactor = getMultiplyingFactor(
      sellingPriceHT,
      totalPricePortion
    );
    technicalSheet = {
      ...technicalSheet,
      totalPricePortion: {
        ...technicalSheet.totalPricePortion,
        value: totalPricePortion,
      },
      multiplyingFactor: {
        ...technicalSheet.multiplyingFactor,
        value: multiplyingFactor,
      },
      sellingPriceHT: {
        ...technicalSheet.sellingPriceHT,
        value: sellingPriceHT,
      },
      tva: {
        ...technicalSheet.tva,
        value: tva,
      },
      perUnitEnergy: {
        ...technicalSheet.perUnitEnergy,
        value: perUnitEnergy,
      },
    };
  }
  return technicalSheet;
};
export const interactionMultiplyingFactor = (multiplyingFactor) => {
  const state = store.getState();
  let { technicalSheet } = state.technicalSheet;
  const percentTvaRate = parseFloat(technicalSheet.tvaRate.value) / 100;

  const sellingPriceHT = getSellingPriceHTByMultiplyingFactor(
    parseFloat(technicalSheet.totalPricePortion.value),
    multiplyingFactor
  );
  const tva = getTvaByRate(sellingPriceHT, percentTvaRate || 0);
  const sellingPriceTTC = getSellingPriceTTC(sellingPriceHT, tva);

  technicalSheet = {
    ...technicalSheet,
    multiplyingFactor: {
      ...technicalSheet.multiplyingFactor,
      value: multiplyingFactor,
      isInput: true,
    },
    sellingPriceHT: {
      ...technicalSheet.sellingPriceHT,
      value: sellingPriceHT,
    },
    sellingPriceTTC: {
      ...technicalSheet.sellingPriceTTC,
      value: sellingPriceTTC,
      isInput: false,
    },
    tva: {
      ...technicalSheet.tva,
      value: tva,
    },
  };

  return technicalSheet;
};

export const interactionSellingPriceTTC = (sellingPriceTTC) => {
  const state = store.getState();
  let { technicalSheet } = state.technicalSheet;
  const floatSellingPriceTTC = parseFloat(sellingPriceTTC);
  const percentTvaRate = parseFloat(technicalSheet.tvaRate.value) / 100;

  const sellingPriceHT = getSellingPriceHTBySellingPriceTTC(
    floatSellingPriceTTC,
    percentTvaRate || 0
  );

  const tva = getTvaBySellingPriceTTC(floatSellingPriceTTC, sellingPriceHT);

  const multiplyingFactor = getMultiplyingFactor(
    sellingPriceHT,
    parseFloat(technicalSheet.totalPricePortion.value)
  );
  technicalSheet = {
    ...technicalSheet,
    multiplyingFactor: {
      ...technicalSheet.multiplyingFactor,
      value: multiplyingFactor,
      isInput: false,
    },
    sellingPriceHT: {
      ...technicalSheet.sellingPriceHT,
      value: sellingPriceHT,
    },
    sellingPriceTTC: {
      ...technicalSheet.sellingPriceTTC,
      value: floatSellingPriceTTC,
      isInput: true,
    },
    tva: {
      ...tva,
      value: tva,
    },
  };
  return technicalSheet;
};

export const interactionTvaRate = (tvaRate) => {
  const state = store.getState();
  let { technicalSheet } = state.technicalSheet;
  const floatTvaRate = parseFloat(tvaRate);
  const percentTvaRate = floatTvaRate / 100;

  if (technicalSheet.multiplyingFactor.isInput) {
    const tva = getTvaByRate(
      parseFloat(technicalSheet.sellingPriceHT.value),
      percentTvaRate
    );
    const sellingPriceTTC = getSellingPriceTTC(
      parseFloat(technicalSheet.sellingPriceHT.value),
      tva
    );
    technicalSheet = {
      ...technicalSheet,
      sellingPriceTTC: {
        ...technicalSheet.sellingPriceTTC,
        value: sellingPriceTTC,
      },
      tva: {
        ...technicalSheet.tva,
        value: tva,
      },
      tvaRate: {
        ...technicalSheet.tvaRate,
        value: floatTvaRate,
      },
    };
  } else {
    const sellingPriceHT = getSellingPriceHTBySellingPriceTTC(
      parseFloat(technicalSheet.sellingPriceTTC.value),
      percentTvaRate
    );
    const tva = getTvaBySellingPriceTTC(
      parseFloat(technicalSheet.sellingPriceTTC.value),
      sellingPriceHT
    );
    const multiplyingFactor = getMultiplyingFactor(
      sellingPriceHT,
      parseFloat(technicalSheet.totalPricePortion.value)
    );
    technicalSheet = {
      ...technicalSheet,
      tva: {
        ...technicalSheet.tva,
        value: tva,
      },
      tvaRate: {
        ...technicalSheet.tvaRate,
        value: floatTvaRate,
      },
      sellingPriceHT: {
        ...technicalSheet.sellingPriceHT,
        value: sellingPriceHT,
      },
      multiplyingFactor: {
        ...technicalSheet.multiplyingFactor,
        value: multiplyingFactor,
      },
    };
  }
  return technicalSheet;
};

export const interactionTotalEnergy = (listTotalEnergy) => {
  const state = store.getState();
  let { technicalSheet } = state.technicalSheet;
  const { product } = state.product;

  const totalEnergy = getTotalEnergy(listTotalEnergy);
  const perUnitEnergy = getPerUnitEnergy(totalEnergy, product.number_produced);

  technicalSheet = {
    ...technicalSheet,
    totalEnergy: {
      ...technicalSheet.totalEnergy,
      value: totalEnergy,
    },
    perUnitEnergy: {
      ...technicalSheet.perUnitEnergy,
      value: perUnitEnergy,
    },
  };

  return technicalSheet;
};

export const interactionListRawMaterialsTotalPrice = (listTotalPrice) => {
  const state = store.getState();
  let { technicalSheet } = state.technicalSheet;
  const floatExtraCharge = parseFloat(technicalSheet.extraCharge.value);
  const percentExtraCharge = floatExtraCharge / 100;
  const percentTvaRate = parseFloat(technicalSheet.tvaRate.value) / 100;

  const { product } = state.product;
  const materialCosts = getMaterialCosts(listTotalPrice);
  const totalMaterialCost = getTotalMaterialCost(
    percentExtraCharge,
    materialCosts
  );
  const totalPricePortion = getTotalPricePortion(
    totalMaterialCost,
    product.number_produced
  );
  if (technicalSheet.multiplyingFactor.isInput) {
    const sellingPriceHT = getSellingPriceHTByMultiplyingFactor(
      totalPricePortion,
      parseFloat(technicalSheet.multiplyingFactor.value)
    );
    const tva = getTvaByRate(sellingPriceHT, percentTvaRate);
    const sellingPriceTTC = getSellingPriceTTC(sellingPriceHT, tva);
    technicalSheet = {
      ...technicalSheet,
      listTotalPrice: {
        ...technicalSheet.listTotalPrice,
        value: listTotalPrice,
      },
      materialCosts: {
        ...technicalSheet.materialCosts,
        value: materialCosts,
      },
      totalMaterialCost: {
        ...technicalSheet.totalMaterialCost,
        value: totalMaterialCost,
      },
      totalPricePortion: {
        ...technicalSheet.totalPricePortion,
        value: totalPricePortion,
      },
      sellingPriceTTC: {
        ...technicalSheet.sellingPriceTTC,
        value: sellingPriceTTC,
      },
      sellingPriceHT: {
        ...technicalSheet.sellingPriceHT,
        value: sellingPriceHT,
      },
      tva: {
        ...technicalSheet.tva,
        value: tva,
      },
    };
  } else {
    const sellingPriceHT = getSellingPriceHTBySellingPriceTTC(
      parseFloat(technicalSheet.sellingPriceTTC.value),
      percentTvaRate
    );
    const tva = getTvaBySellingPriceTTC(
      parseFloat(technicalSheet.sellingPriceTTC.value),
      sellingPriceHT
    );
    const multiplyingFactor = getMultiplyingFactor(
      sellingPriceHT,
      totalPricePortion
    );
    technicalSheet = {
      ...technicalSheet,
      listTotalPrice: {
        ...technicalSheet.listTotalPrice,
        value: listTotalPrice,
      },
      materialCosts: {
        ...technicalSheet.materialCosts,
        value: materialCosts,
      },
      totalMaterialCost: {
        ...technicalSheet.totalMaterialCost,
        value: totalMaterialCost,
      },
      totalPricePortion: {
        ...technicalSheet.totalPricePortion,
        value: totalPricePortion,
      },
      multiplyingFactor: {
        ...technicalSheet.multiplyingFactor,
        value: multiplyingFactor,
      },
      sellingPriceHT: {
        ...technicalSheet.sellingPriceHT,
        value: sellingPriceHT,
      },
      tva: {
        ...technicalSheet.tva,
        value: tva,
      },
    };
  }

  return technicalSheet;
};
