import { getParsedFloatNumber } from "../range/RangeService";

export function getToFixedNumber(num, digits, base) {
  const pow = (base || 10) ** digits;
  return Math.round(num * pow) / pow;
}
export const getListSalesRevenueHT = (offerRanges) => {
  const listSalesRevenueHT = [];

  offerRanges.forEach((offerRange) => {
    listSalesRevenueHT.push(
      getParsedFloatNumber(offerRange.range.sales_revenue_HT)
    );
  });

  return listSalesRevenueHT;
};

export const getListNumberCustomByYearNumberCheckTotal = (offerRanges) => {
  const listNumberCustomByYearNumberCheckTotal = [];

  offerRanges.forEach((offerRange) => {
    listNumberCustomByYearNumberCheckTotal.push(
      getParsedFloatNumber(
        offerRange.range.number_custom_by_year_number_check_total
      )
    );
  });

  return listNumberCustomByYearNumberCheckTotal;
};
export const getListNumberDayByYear = (offerRanges) => {
  const listNumberDayByYear = [];

  offerRanges.forEach((offerRange) => {
    listNumberDayByYear.push(
      getParsedFloatNumber(offerRange.range.number_day_by_year)
    );
  });

  return listNumberDayByYear;
};
export const getListMaterialConsumptionHT = (offerRanges) => {
  const listMaterialConsumptionHT = [];

  offerRanges.forEach((offerRange) => {
    listMaterialConsumptionHT.push(
      getParsedFloatNumber(offerRange.range.material_consumption_HT)
    );
  });

  return listMaterialConsumptionHT;
};
export const getListGrossMargin = (offerRanges) => {
  const listGrossMargin = [];

  offerRanges.forEach((offerRange) => {
    listGrossMargin.push(getParsedFloatNumber(offerRange.range.gross_margin));
  });

  return listGrossMargin;
};
export const getListRangeMultiplierCoefficient = (offerRanges) => {
  const listRangeMultiplierCoefficient = [];

  offerRanges.forEach((offerRange) => {
    listRangeMultiplierCoefficient.push(
      getParsedFloatNumber(offerRange.range.range_multiplier_coefficient)
    );
  });

  return listRangeMultiplierCoefficient;
};
export const getListAverageMaterialRatio = (offerRanges) => {
  const listAverageMaterialRatio = [];

  offerRanges.forEach((offerRange) => {
    listAverageMaterialRatio.push(
      getParsedFloatNumber(offerRange.range.average_material_ratio)
    );
  });

  return listAverageMaterialRatio;
};
