/* eslint-disable no-unused-vars */
import { store } from "../../redux/store";
import {
  toFixedNumber,
  getCserRange,
  getCserProduct,
  getIlesLowerLimitsPopularity,
  getIlesAverageLimitsPopularity,
  getIlesHigherLimitsPopularity,
  getIlesLowerLimitsRentability,
  getIlesAverageLimitsRentability,
  getIlesHigherLimitsRentability,
  getAverageUnitGrossMargin,
  getSumQuantitySold,
  getPercentageSold,
  getSumGrossMarginHtTotal,
  getBostonLowerLimitsRentability,
  getBostonHigherLimitsRentability,
  getRentabilityBostonSchool,
  getBostonRank,
  getPopularityBostonSchool,
  getPopularityIndex,
  getPopularityPopser,
  getRentabilityPopser,
  getPopserRank,
  getGrossMarginHtTotal,
  getBostonLowerLimitsPopularity,
  getBostonHigherLimitsPopularity,
  getTotalDisplayNumber,
  getDisplayIndex,
  getParsedFloatNumber,
  getGrossMarginHtUnit,
  getCustomersAYearUsingCustomersADayAndNumberOfDaysAYear,
  getCustomersChoicesByYear,
  getNumberOptionCheckRange,
} from "./RangeService";
import {
  getListUnitGrossMargin,
  getListQuantitySold,
  getListTotalGrossMargin,
  getListDisplayNumber,
  getListTurnoverTTC,
  getListTurnoverHT,
  getListMaterialConsumptionHT,
  getListProductPrices,
  getListPollQuantity,
} from "./RangeMethodService";
import rangeProductsReducer from "../../redux/reducers/rangeProductReducer";
import {
  getTotalTurnoverTTC,
  getTotalTurnoverHT,
  getTotalconsumptionMaterialsHT,
  getGrossMarginHT,
  getAverageMaterialRatio,
  getMultiplyingCoefficientRange,
} from "./RangeTurnoverCalculService";
import {
  getAveragePrice,
  getAvarageRequestedPrice,
} from "./RangeBalanceService";
import { getQuantiSoldFromSurvey } from "./RangeProductService";

function pushToArray(arr, obj) {
  const arrayCopy = arr;
  const index = arrayCopy.findIndex((e) => e.uuid === obj.uuid);

  if (index === -1) {
    arrayCopy.push(obj);
  } else {
    arrayCopy[index] = obj;
  }
  return arrayCopy;
}
export const interactionTurnoverRange = (rangeProducts, listQuantitySold) => {
  const listTurnoverTTC = getListTurnoverTTC(rangeProducts);
  const listTurnoverHT = getListTurnoverHT(rangeProducts);
  const listMaterialConsumptionHT = getListMaterialConsumptionHT(rangeProducts);
  const listProductPrices = {
    allProductPrices: getListProductPrices(rangeProducts),
  };
  const listProductPricesAndQuantitySold = {
    allProductPrices: listProductPrices.allProductPrices,
    allProductQuantitiesSold: listQuantitySold,
  };
  // CALCUL SALE REVENUE TTC FOR TurnoverRange.js
  const turnoverTTC = getTotalTurnoverTTC(listTurnoverTTC, listQuantitySold);
  // CALCUL SALE REVENUE HT FOR TurnoverRange.js
  const turnoverHT = getTotalTurnoverHT(listTurnoverHT, listQuantitySold);
  // CALCUL Total Consommation de Matières HT FOR TurnoverRange.js
  const totalconsumptionMaterialsHT = getTotalconsumptionMaterialsHT(
    listMaterialConsumptionHT,
    listQuantitySold
  );
  // CALCUL Marge HT FOR TurnoverRange.js
  const grossMarginHT = getGrossMarginHT(
    turnoverHT,
    totalconsumptionMaterialsHT
  );

  // CALCUL coeff multiplicateur TurnoverRange.js
  const multiplyingCoefficientRange = getMultiplyingCoefficientRange(
    turnoverHT,
    totalconsumptionMaterialsHT
  );

  // CALCUL Moyenne ratio matiere TurnoverRange.js
  const averageMaterialRatio = getAverageMaterialRatio(
    multiplyingCoefficientRange
  );

  // CALCUL Prix moyen offert TurnoverRange.js
  const averagePriceOfferedTTC = getAveragePrice(listProductPrices);

  // CALCUL Prix moyen demandé TurnoverRange.js
  const averagePriceRequestedTTC = getAvarageRequestedPrice(
    listProductPricesAndQuantitySold
  );

  return {
    sales_revenue_TTC: toFixedNumber(turnoverTTC, 2),
    sales_revenue_HT: toFixedNumber(turnoverHT, 2),
    material_consumption_HT: toFixedNumber(totalconsumptionMaterialsHT, 2),
    gross_margin: toFixedNumber(grossMarginHT, 2),
    average_material_ratio: toFixedNumber(averageMaterialRatio, 2),
    range_multiplier_coefficient: toFixedNumber(multiplyingCoefficientRange, 2),
    average_price_offered_TTC: toFixedNumber(averagePriceOfferedTTC, 2),
    average_price_requested_TTC: toFixedNumber(averagePriceRequestedTTC, 2),
  };
};
export const interactionPriceVatIncluded = (
  rangeProductId,
  newPriceVatIncluded
) => {
  const state = store.getState();
  const parsedNewPrice = getParsedFloatNumber(newPriceVatIncluded);

  const { rangeProducts } = state.rangeProducts;
  const rangeProductsCopy = rangeProducts;

  // A partir de l'ID du produit de la gamme, on retrouve le produit lié et son ID
  const rangeProduct = rangeProductsCopy.filter(
    (_rangeProduct) => _rangeProduct.id === rangeProductId
  );
  const { range } = rangeProduct[0];
  const currentRangeProduct = rangeProduct[0];

  let updatedRangeProducts = [];

  // Une fois qu'on a l'ID du produit on va le chercher dans le store et on le modifie
  const vatRateInRangeProduct = getParsedFloatNumber(currentRangeProduct.vat);

  let vat =
    (parsedNewPrice / (100 + getParsedFloatNumber(range.tva_rate))) *
    getParsedFloatNumber(range.tva_rate);
  if (vatRateInRangeProduct > 0) {
    vat =
      (parsedNewPrice / (100 + getParsedFloatNumber(vatRateInRangeProduct))) *
      getParsedFloatNumber(vatRateInRangeProduct);
  }

  vat = getParsedFloatNumber(toFixedNumber(vat, 2));

  const priceVatExcluded = toFixedNumber(
    getParsedFloatNumber(parsedNewPrice - vat),
    2
  );

  const rangeProductUnitGrossMargin = toFixedNumber(
    getParsedFloatNumber(
      priceVatExcluded -
        getParsedFloatNumber(currentRangeProduct.raw_material_cost_vat_excl)
    ),
    2
  );

  const rangeProductTotalGrossMargin = toFixedNumber(
    getParsedFloatNumber(
      rangeProductUnitGrossMargin *
        getParsedFloatNumber(currentRangeProduct.quantity_sold)
    ),
    2
  );

  const updatedRangeProduct = {
    ...currentRangeProduct,
    selling_price_vat_excl: priceVatExcluded,
    selling_price_vat_incl: newPriceVatIncluded,
    unit_gross_margin_vat_exluded: rangeProductUnitGrossMargin,
    total_unit_gross_margin_vat_exluded: rangeProductTotalGrossMargin,
  };

  updatedRangeProducts = pushToArray(updatedRangeProducts, updatedRangeProduct);

  // Now we need all the range products available in one array to compute the mean of gross margins
  // First we find the rangeProduct in the list using it's database ID
  const index = rangeProductsCopy.findIndex(
    (_rangeProduct) => _rangeProduct.id === updatedRangeProduct.id
  );
  // Then we replace this element in place in the list with the updatedRangeProduct
  rangeProductsCopy[index] = updatedRangeProduct;

  const listUnitGrossMargin = getListUnitGrossMargin(rangeProductsCopy);

  const averageUnitGrossMargin = getAverageUnitGrossMargin(listUnitGrossMargin);

  const ilesLowerLimitsRentability = getIlesLowerLimitsRentability(
    averageUnitGrossMargin,
    getParsedFloatNumber(range.variation_rentability)
  );

  const ilesHigherLimitsRentability = getIlesHigherLimitsRentability(
    averageUnitGrossMargin,
    getParsedFloatNumber(range.variation_rentability)
  );

  // Since the gross margin changed, we need to compute the popser rentability for all the range products
  rangeProducts.forEach((_rangeProduct) => {
    const rentabilityPopser = getRentabilityPopser(
      ilesLowerLimitsRentability,
      ilesHigherLimitsRentability,
      _rangeProduct.unit_gross_margin_vat_exluded
    );
    updatedRangeProducts = pushToArray(updatedRangeProducts, {
      ..._rangeProduct,
      iles_profitability: rentabilityPopser,
    });
  });

  // Now that we updated the popser profitability we update the boston profitability

  const listQuantitySold = getListQuantitySold(updatedRangeProducts);
  const sumQuantitySold = getSumQuantitySold(listQuantitySold);
  const listTotalGrossMargin = getListTotalGrossMargin(updatedRangeProducts);
  const sumGrossMarginHtTotal = getSumGrossMarginHtTotal(listTotalGrossMargin);

  updatedRangeProducts.forEach((_rangeProduct) => {
    const unitGrossMargin = getParsedFloatNumber(
      _rangeProduct.unit_gross_margin_vat_exluded
    );
    const rentabilityBostonSchool = getRentabilityBostonSchool(
      getBostonLowerLimitsRentability(sumGrossMarginHtTotal, sumQuantitySold),
      unitGrossMargin
    );
    updatedRangeProducts = pushToArray(updatedRangeProducts, {
      ..._rangeProduct,
      bcg_profitability: rentabilityBostonSchool,
    });
  });

  // Now that profitability is computed, we'll compute ranks
  // First, Popser Rank : iles_rank

  updatedRangeProducts.forEach((_rangeProduct) => {
    const ilesRank = getPopserRank(
      _rangeProduct.iles_profitability,
      _rangeProduct.iles_popularity
    );
    updatedRangeProducts = pushToArray(updatedRangeProducts, {
      ..._rangeProduct,
      iles_rank: ilesRank,
    });
  });

  // Next with Boston Rank, bcg_rank

  const rangeProductsWithBostonRankUpdated = [];

  updatedRangeProducts.forEach((_rangeProduct) => {
    const bcgRank = getBostonRank(
      _rangeProduct.bcg_popularity,
      _rangeProduct.bcg_profitability
    );

    updatedRangeProducts = pushToArray(updatedRangeProducts, {
      ..._rangeProduct,
      bcg_rank: bcgRank,
    });
  });
  // INTERACTION TURNOVER RANGE
  const turnoverRange = interactionTurnoverRange(
    updatedRangeProducts,
    listQuantitySold
  );
  const updateRange = {
    ...range,
    ...turnoverRange,
  };

  return { range: updateRange, rangeProducts: updatedRangeProducts };
};

export const interactionCoefficentK = (coefficientK) => {
  const state = store.getState();
  const parseCoefficient = getParsedFloatNumber(coefficientK);
  const { rangeProducts } = state.rangeProducts;

  const CserRange = getCserRange(
    parseCoefficient,
    getParsedFloatNumber(state.ranges.range.range_multiplier_coefficient) || 0
  );

  const newRangeProducts = [];

  rangeProducts.forEach((rangeProduct) => {
    const cserProduct = getCserProduct(
      CserRange,
      getParsedFloatNumber(rangeProduct.product_factor)
    );
    newRangeProducts.push({
      ...rangeProduct,
      product_cser: cserProduct,
    });
  });
  const range = {
    ...state.ranges.range,
    cser_range: CserRange,
    coefficient_k: parseCoefficient,
  };

  return { range, rangeProducts: newRangeProducts };
};
//  Only impact the POPSER METHOD !
export const interactionVariationRentability = (variationRentability) => {
  const state = store.getState();
  const { rangeProducts } = state.rangeProducts;

  const parseVariationRentability = getParsedFloatNumber(variationRentability);
  const listUnitGrossMargin = getListUnitGrossMargin(
    state.rangeProducts.rangeProducts
  );

  const averageUnitGrossMargin = getAverageUnitGrossMargin(listUnitGrossMargin);
  const ilesLowerLimitsRentability = getIlesLowerLimitsRentability(
    averageUnitGrossMargin,
    parseVariationRentability
  );
  const ilesAverageLimitsRentability = getIlesAverageLimitsRentability(
    averageUnitGrossMargin,
    parseVariationRentability
  );
  const ilesHigherLimitsRentability = getIlesHigherLimitsRentability(
    averageUnitGrossMargin,
    parseVariationRentability
  );
  const newRangeProducts = [];

  rangeProducts.forEach((rangeProduct) => {
    const rentabilityPopser = getRentabilityPopser(
      ilesHigherLimitsRentability,
      ilesLowerLimitsRentability,
      getParsedFloatNumber(rangeProduct.unit_gross_margin_vat_exluded)
    );
    const popserRank = getPopserRank(
      rentabilityPopser,
      rangeProduct.iles_popularity
    );
    newRangeProducts.push({
      ...rangeProduct,
      iles_rentability: rentabilityPopser,
      iles_rank: popserRank,
    });
  });

  const range = {
    ...state.ranges.range,
    variation_rentability: getParsedFloatNumber(parseVariationRentability),
    iles_lower_limits_rentability: ilesLowerLimitsRentability,
    iles_average_limits_rentability: ilesAverageLimitsRentability,
    iles_higher_limits_rentability: ilesHigherLimitsRentability,
  };
  return { range, rangeProducts: newRangeProducts };
};

export const interactionVariationPopularity = (variationPopularity) => {
  const parseVariationPopularity = getParsedFloatNumber(variationPopularity);
  const state = store.getState();
  const { rangeProducts } = state.rangeProducts;
  const ilesLowerLimitsPopularity = getIlesLowerLimitsPopularity(
    parseVariationPopularity
  );
  const ilesAverageLimitsPopularity = getIlesAverageLimitsPopularity(
    parseVariationPopularity
  );
  const ilesHigherLimitsPopularity = getIlesHigherLimitsPopularity(
    parseVariationPopularity
  );

  const newRangeProducts = [];

  rangeProducts.forEach((rangeProduct) => {
    const popularityPopser = getPopularityPopser(
      rangeProduct.popularity_index,
      ilesHigherLimitsPopularity,
      ilesLowerLimitsPopularity
    );
    const popserRank = getPopserRank(
      rangeProduct.iles_rentability,
      popularityPopser
    );

    newRangeProducts.push({
      ...rangeProduct,
      iles_popularity: popularityPopser,
      iles_rank: popserRank,
    });
  });
  const rangeAndRangeProducts = {
    range: {
      ...state.ranges.range,
      variation_popularity: getParsedFloatNumber(parseVariationPopularity),
      iles_lower_limits_popularity: ilesLowerLimitsPopularity,
      iles_average_limits_popularity: ilesAverageLimitsPopularity,
      iles_higher_limits_popularity: ilesHigherLimitsPopularity,
    },
    rangeProducts: newRangeProducts,
  };

  return rangeAndRangeProducts;
};

export const interactionTotalSumQuantitySold = (
  totalSumQuantitySold,
  RangeProducts
) => {
  const state = store.getState();

  const listTotalGrossMarginTotal = getListTotalGrossMargin(RangeProducts);

  const sumGrossMarginTotal = getSumGrossMarginHtTotal(
    listTotalGrossMarginTotal
  );
  const bostonLowerLimitsRentability = getBostonLowerLimitsRentability(
    sumGrossMarginTotal,
    totalSumQuantitySold
  );
  const bostonHigherLimitsRentability = getBostonHigherLimitsRentability(
    bostonLowerLimitsRentability
  );
  const bostonLowerLimitsPopularity = getBostonLowerLimitsPopularity(
    RangeProducts.length
  );

  const bostonHigherLimitsPopularity = getBostonHigherLimitsPopularity(
    getBostonLowerLimitsPopularity
  );

  const range = {
    ...state.ranges.range,
    boston_lower_limits_rentability: bostonLowerLimitsRentability,
    boston_higher_limits_rentability: bostonHigherLimitsRentability,
    boston_lower_limits_popularity: bostonLowerLimitsPopularity,
    boston_higher_limits_popularity: bostonHigherLimitsPopularity,
  };
  const newRangeProducts = [];

  RangeProducts.forEach((rangeProduct) => {
    let percentageSold = getPercentageSold(
      rangeProduct.quantity_sold,
      totalSumQuantitySold
    );

    percentageSold = toFixedNumber(percentageSold * 100, 2);

    const popularityBostonSchool = getPopularityBostonSchool(
      range.boston_lower_limits_popularity,
      percentageSold
    );
    const popularityIndex = getPopularityIndex(
      percentageSold,
      rangeProduct.display_index || 0
    );

    const popularityPopser = getPopularityPopser(
      popularityIndex,
      range.iles_higher_limits_popularity,
      range.iles_lower_limits_popularity
    );
    const rentabilityBostonSchool = getRentabilityBostonSchool(
      range.boston_lower_limits_rentability,
      getParsedFloatNumber(rangeProduct.unit_gross_margin_vat_exluded)
    );
    const rentabilityPopser = getRentabilityPopser(
      range.iles_higher_limits_rentability,
      range.iles_lower_limits_rentability,
      getParsedFloatNumber(rangeProduct.unit_gross_margin_vat_exluded)
    );
    const bostonRank = getBostonRank(
      popularityBostonSchool,
      rentabilityBostonSchool
    );
    const popserRank = getPopserRank(rentabilityPopser, popularityPopser);
    newRangeProducts.push({
      ...rangeProduct,
      percent_of_sells: percentageSold,
      bcg_popularity: popularityBostonSchool,
      bcg_profitability: rentabilityBostonSchool,
      bcg_rank: bostonRank,
      popularity_index: popularityIndex,
      iles_popularity: popularityPopser,
      iles_profitability: rentabilityPopser,
      iles_rank: popserRank,
    });
  });

  return { range, rangeProducts: newRangeProducts };
};

export const interactionQuantitySoldReal = (quantitySold, idRange) => {
  const parseQuantitySold = getParsedFloatNumber(quantitySold);
  const state = store.getState();
  const newRangeProducts = [];

  state.rangeProducts.rangeProducts.forEach((rangeProduct) => {
    if (idRange === rangeProduct.id) {
      const totalUnitGrossMarginVatExluded = getGrossMarginHtTotal(
        rangeProduct.unit_gross_margin_vat_exluded,
        parseQuantitySold
      );
      newRangeProducts.push({
        ...rangeProduct,
        quantity_sold: parseQuantitySold,
        total_unit_gross_margin_vat_exluded: totalUnitGrossMarginVatExluded,
      });
    } else {
      const totalUnitGrossMarginVatExluded = getGrossMarginHtTotal(
        rangeProduct.unit_gross_margin_vat_exluded,
        rangeProduct.quantity_sold
      );
      newRangeProducts.push({
        ...rangeProduct,
        total_unit_gross_margin_vat_exluded: totalUnitGrossMarginVatExluded,
      });
    }
  });
  const listQuantitySold = getListQuantitySold(newRangeProducts);
  const turnoverRange = interactionTurnoverRange(
    newRangeProducts,
    listQuantitySold
  );
  const totalSumQuantitySold = getSumQuantitySold(listQuantitySold);
  const objectRangeRangeProducts = interactionTotalSumQuantitySold(
    totalSumQuantitySold,
    newRangeProducts
  );

  return {
    range: {
      ...objectRangeRangeProducts.range,
      ...turnoverRange,
    },
    rangeProducts: objectRangeRangeProducts.rangeProducts,
  };
};
export const interactionTotalDisplayNumber = (
  totalDisplayNumber,
  rangeProducts
) => {
  const newRangeProducts = [];
  rangeProducts.forEach((rangeProduct) => {
    const displayIndex = getDisplayIndex(
      rangeProduct.number_of_days_of_presentation,
      totalDisplayNumber
    );

    newRangeProducts.push({
      ...rangeProduct,
      display_index: displayIndex,
    });
  });

  return { rangeProducts: newRangeProducts };
};
export const interactionNumberOfDaysOfPresentationReal = (
  numberOfDaysOfPresentation,
  idRange
) => {
  const parseDisplayNumber = getParsedFloatNumber(numberOfDaysOfPresentation);
  const state = store.getState();
  let updatedRangeProducts = [];

  state.rangeProducts.rangeProducts.forEach((rangeProduct) => {
    if (idRange === rangeProduct.id) {
      updatedRangeProducts.push({
        ...rangeProduct,
        number_of_days_of_presentation: parseDisplayNumber,
      });
    } else {
      updatedRangeProducts.push({
        ...rangeProduct,
      });
    }
  });

  const listDisplayNumber = getListDisplayNumber(updatedRangeProducts);
  const totalDisplayNumber = getTotalDisplayNumber(listDisplayNumber);

  updatedRangeProducts = interactionTotalDisplayNumber(
    totalDisplayNumber,
    updatedRangeProducts
  ).rangeProducts;

  // Update display_index, popularity_index, iles_popularity, bcg_popularity, total_unit_gross_margin_vat_exluded, iles_rank
  updatedRangeProducts.forEach((_rangeProduct) => {
    const displayIndex = getDisplayIndex(
      _rangeProduct.number_of_days_of_presentation,
      totalDisplayNumber
    );

    const popularityIndex = getPopularityIndex(
      _rangeProduct.percent_of_sells,
      displayIndex
    );
    const popserPopularity = getPopularityPopser(
      popularityIndex,
      getIlesLowerLimitsPopularity(_rangeProduct.variation_popularity || 0),
      getIlesHigherLimitsPopularity(_rangeProduct.variation_popularity || 0)
    );

    const bostonPopularity = getPopularityBostonSchool(
      getBostonLowerLimitsPopularity(updatedRangeProducts.length),
      _rangeProduct.percent_of_sells
    );
    const grossMarginHtTotal = getGrossMarginHtTotal(
      _rangeProduct.unit_gross_margin_vat_exluded,
      _rangeProduct.quantity_sold
    );

    const ilesRank = getPopserRank(
      _rangeProduct.iles_profitability,
      popserPopularity
    );

    updatedRangeProducts = pushToArray(updatedRangeProducts, {
      ..._rangeProduct,
      display_index: displayIndex,
      popularity_index: popularityIndex,
      iles_popularity: popserPopularity,
      bcg_popularity: bostonPopularity,
      total_unit_gross_margin_vat_exluded: grossMarginHtTotal,
      iles_rank: ilesRank,
    });
  });

  // Update bcg_profitability, bcg_rank
  const listOfGrossMarginHTTotal = getListTotalGrossMargin(
    updatedRangeProducts
  );
  const listQuantititesSold = getListQuantitySold(updatedRangeProducts);
  const sumGrossMarginHtTotal = getSumGrossMarginHtTotal(
    listOfGrossMarginHTTotal
  );

  const sumOfQuantitiesSold = getSumQuantitySold(listQuantititesSold);

  const bostonLowerLimitsRentability = getBostonLowerLimitsRentability(
    sumGrossMarginHtTotal,
    sumOfQuantitiesSold
  );

  updatedRangeProducts.forEach((_rangeProduct) => {
    const bcgProfitability = getRentabilityBostonSchool(
      bostonLowerLimitsRentability,
      _rangeProduct.unit_gross_margin_vat_exluded
    );
    const bcgRank = getBostonRank(
      _rangeProduct.bcg_popularity,
      bcgProfitability
    );
    updatedRangeProducts = pushToArray(updatedRangeProducts, {
      ..._rangeProduct,
      bcg_profitability: bcgProfitability,
      bcg_rank: bcgRank,
    });
  });

  return updatedRangeProducts;
};

export const interactionRawMaterialCost = (rangeProductId, rawMaterialCost) => {
  // Gather initial data
  const state = store.getState();
  const parsedRawMaterialCost = getParsedFloatNumber(rawMaterialCost);

  const { rangeProducts } = state.rangeProducts;
  const rangeProductsCopy = rangeProducts;

  // Get current rangeProduct
  const rangeProduct = rangeProductsCopy.filter(
    (_rangeProduct) => _rangeProduct.id === rangeProductId
  );
  const currentRangeProduct = rangeProduct[0];

  // Initialize empty list we will use to store updated range products
  let updatedRangeProducts = [];

  // We updated our raw material cost so it'll impact the gross margins
  const unitGrossMargin = getGrossMarginHtUnit(
    currentRangeProduct.selling_price_vat_excl,
    parsedRawMaterialCost
  );

  const totalGrossMargin = getGrossMarginHtTotal(
    unitGrossMargin,
    currentRangeProduct.quantity_sold
  );

  const updatedRangeProduct = {
    ...currentRangeProduct,
    total_unit_gross_margin_vat_exluded: totalGrossMargin,
    unit_gross_margin_vat_exluded: unitGrossMargin,
    raw_material_cost_vat_excl: parsedRawMaterialCost,
  };
  // Once our current range product margins are updated, we put it into the updatedRangeProducts list
  updatedRangeProducts = pushToArray(updatedRangeProducts, updatedRangeProduct);

  const index = rangeProductsCopy.findIndex(
    (_rangeProduct) => _rangeProduct.id === updatedRangeProduct.id
  );
  rangeProductsCopy[index] = updatedRangeProduct;
  updatedRangeProducts = rangeProductsCopy;

  // Updating the gross margins will change the profitability and ranks. To compute thoses scores we'll need to gather
  // sum of total gross margin, average of unit gross margin, sum of quantities sold
  // some iles and bcg parameters
  const listUnitGrossMargin = getListUnitGrossMargin(updatedRangeProducts);
  const listTotalGrossmargin = getListTotalGrossMargin(updatedRangeProducts);
  const averageUnitGrossMargin = getAverageUnitGrossMargin(listUnitGrossMargin);
  const sumTotalGrossMargin = getSumGrossMarginHtTotal(listTotalGrossmargin);
  const listQuantitySold = getListQuantitySold(updatedRangeProducts);
  const sumOfQuantitiesSold = getSumQuantitySold(listQuantitySold);
  const ilesLowerLimitsRentability = getIlesLowerLimitsPopularity(
    currentRangeProduct.range.variation_rentability
  );
  const ilesHigherLimiteRentability = getIlesHigherLimitsRentability(
    averageUnitGrossMargin,
    currentRangeProduct.range.variation_rentability
  );
  const bostonLowerLimitsRentability = getBostonLowerLimitsRentability(
    sumTotalGrossMargin,
    sumOfQuantitiesSold
  );

  // Once we have all the parameters to update the profitabilities and ranks we look over our range products to update them values
  updatedRangeProducts.forEach((_rangeProduct) => {
    const popserProfitability = getRentabilityPopser(
      ilesLowerLimitsRentability,
      ilesHigherLimiteRentability,
      _rangeProduct.unit_gross_margin_vat_exluded
    );
    const bcgProfitability = getRentabilityBostonSchool(
      bostonLowerLimitsRentability,
      _rangeProduct.unit_gross_margin_vat_exluded
    );
    const popserRank = getPopserRank(
      popserProfitability,
      _rangeProduct.iles_popularity
    );
    const bcgRank = getBostonRank(
      _rangeProduct.bcg_popularity,
      bcgProfitability
    );

    updatedRangeProducts = pushToArray(updatedRangeProducts, {
      ..._rangeProduct,
      iles_profitability: popserProfitability,
      bcg_profitability: bcgProfitability,
      iles_rank: popserRank,
      bcg_rank: bcgRank,
    });
  });

  // We return updatedRangedProducts with the new raw material cost, margins and scores
  return updatedRangeProducts;
};

export const interactionVatRate = (rangeProductId, newVatRate) => {
  // Gather initial data
  const state = store.getState();
  const parsedVatRate = getParsedFloatNumber(newVatRate);

  const { rangeProducts } = state.rangeProducts;
  const rangeProductsCopy = rangeProducts;

  // Get current rangeProduct
  const rangeProduct = rangeProductsCopy.filter(
    (_rangeProduct) => _rangeProduct.id === rangeProductId
  );
  const currentRangeProduct = rangeProduct[0];

  const sellingPriceVatExcl = getParsedFloatNumber(
    toFixedNumber(currentRangeProduct.selling_price_vat_excl, 2)
  );

  // Vat rate will update the vat included price
  const vat = getParsedFloatNumber(
    toFixedNumber(sellingPriceVatExcl * (parsedVatRate / 100), 2)
  );

  const sellingPriceVatIncl = toFixedNumber(
    getParsedFloatNumber(sellingPriceVatExcl + vat),
    2
  );

  return {
    ...currentRangeProduct,
    vat: parsedVatRate,
    selling_price_vat_incl: sellingPriceVatIncl,
  };
};

export const interactionVatExclPrice = (rangeProductId, newPriceVatExcl) => {
  // Gather initial data
  const state = store.getState();
  const parsedPriceVatExcluded = getParsedFloatNumber(newPriceVatExcl);

  const { rangeProducts } = state.rangeProducts;
  const rangeProductsCopy = rangeProducts;

  // Get current rangeProduct
  const rangeProduct = rangeProductsCopy.filter(
    (_rangeProduct) => _rangeProduct.id === rangeProductId
  );
  const currentRangeProduct = rangeProduct[0];

  const vatRate = getParsedFloatNumber(
    toFixedNumber(currentRangeProduct.vat, 2)
  );

  // Vat rate will update the vat included price
  const vat = getParsedFloatNumber(
    toFixedNumber(parsedPriceVatExcluded * (vatRate / 100), 2)
  );

  const sellingPriceVatIncl = toFixedNumber(
    getParsedFloatNumber(parsedPriceVatExcluded + vat),
    2
  );

  return {
    ...currentRangeProduct,
    vat: vatRate,
    selling_price_vat_incl: sellingPriceVatIncl,
    selling_price_vat_excl: parsedPriceVatExcluded,
  };
};

export const interactionCustomersADay = (customersADay, daysAYear) => {
  const state = store.getState();
  const { range } = state.ranges;
  const customers_by_year = getCustomersAYearUsingCustomersADayAndNumberOfDaysAYear(
    customersADay,
    daysAYear
  );
  const customer_choices_in_survey_by_year = getCustomersChoicesByYear(
    customers_by_year,
    range.count_of_choices_in_survey,
    range.surveyed_customers
  );

  return { customers_by_year, customer_choices_in_survey_by_year };
};

export const interactionSurveyedCustomers = (
  customers_by_year,
  count_of_choices_in_range,
  surveyed_customers
) => {
  const customers_choices_by_year = getCustomersChoicesByYear(
    customers_by_year,
    count_of_choices_in_range,
    surveyed_customers
  );

  return customers_choices_by_year;
};

export const interactionChangePollQuantity = (rangeProductId, pollQuantity) => {
  const parsedPollQuantity = toFixedNumber(
    getParsedFloatNumber(pollQuantity),
    2
  );
  const state = store.getState();
  const { rangeProducts } = state.rangeProducts;
  const { range } = state.ranges;
  const rangeProductsCopy = rangeProducts;
  const rangeProduct = rangeProductsCopy.filter(
    (_rangeProduct) => _rangeProduct.id === rangeProductId
  );
  const otherRangeproducts = rangeProductsCopy.filter(
    (_rangeProduct) => _rangeProduct.id !== rangeProductId
  );
  const currentRangeProduct = rangeProduct[0];
  currentRangeProduct.poll_quantity = parsedPollQuantity;

  const updatedRangeProducts = [...otherRangeproducts, currentRangeProduct];
  const count_of_choices_in_range = updatedRangeProducts.reduce((prev, cur) => {
    return getParsedFloatNumber(prev) + getParsedFloatNumber(cur.poll_quantity);
  }, 0);

  const customer_choices_in_survey_by_year = getCustomersChoicesByYear(
    range.customers_by_year,
    count_of_choices_in_range,
    range.surveyed_customers
  );

  updatedRangeProducts.forEach((_updatedRangeproduct) => {
    // eslint-disable-next-line no-param-reassign
    _updatedRangeproduct.quantity_sold = getQuantiSoldFromSurvey(
      getParsedFloatNumber(_updatedRangeproduct.poll_quantity),
      customer_choices_in_survey_by_year,
      count_of_choices_in_range
    );
  });
  const listPollQuantity = getListPollQuantity(updatedRangeProducts);
  const count_of_choices_in_survey = getNumberOptionCheckRange(
    listPollQuantity
  );
  return {
    updatedRange: {
      ...range,
      customer_choices_in_survey_by_year,
      count_of_choices_in_survey,
    },
    updatedRangeProducts,
  };
};

export const interactionChangePollQuantityByRangeProjectedMode = (range) => {
  const state = store.getState();
  const { rangeProducts } = state.rangeProducts;

  const count_of_choices_in_range = rangeProducts.reduce((prev, cur) => {
    return getParsedFloatNumber(prev) + getParsedFloatNumber(cur.poll_quantity);
  }, 0);

  const customer_choices_in_survey_by_year = getCustomersChoicesByYear(
    range.customers_by_year,
    count_of_choices_in_range,
    range.surveyed_customers
  );

  rangeProducts.forEach((_updatedRangeproduct) => {
    // eslint-disable-next-line no-param-reassign
    _updatedRangeproduct.quantity_sold = getQuantiSoldFromSurvey(
      getParsedFloatNumber(_updatedRangeproduct.poll_quantity),
      customer_choices_in_survey_by_year,
      count_of_choices_in_range
    );
  });
  const listPollQuantity = getListPollQuantity(rangeProducts);
  const count_of_choices_in_survey = getNumberOptionCheckRange(
    listPollQuantity
  );
  return {
    updatedRange: {
      ...range,
      customer_choices_in_survey_by_year,
      count_of_choices_in_survey,
    },
    rangeProducts,
  };
};
