import { OFFER_RANGES_URL } from "../../services/constants/urls";
import {
  ADD_OFFER_RANGES_SUCCESS,
  ADD_OFFER_RANGES_ERROR,
  REGISTER_OFFER_RANGES_SUCCESS,
  FETCH_OFFER_RANGES,
  FETCH_OFFER_RANGES_ERROR,
  DELETE_OFFER_RANGES,
  DELETE_OFFER_RANGES_ERROR,
  UPDATE_TOTAL_OFFER_RANGES,
} from "../types/offerRangeActionTypes";
import apiClient from "../../services/api/apiClient";
import { openSnackbar } from "./snackbarActions";
import { store } from "../store";

const deleteListOfferRanges = (listDeleteOfferRangesUuid) => {
  const { offerRanges } = store.getState();
  const newOfferRanges = [...offerRanges.offerRanges];
  listDeleteOfferRangesUuid.forEach((offerRangeUuid) => {
    const indexTable = newOfferRanges.findIndex(
      (oldOfferRange) => oldOfferRange.uuid === offerRangeUuid
    );
    newOfferRanges.splice(indexTable, 1);
  });

  return newOfferRanges;
};

export const addOfferRangetWithoutRecording = (offerRange) => async (
  dispatch
) => {
  dispatch({
    type: ADD_OFFER_RANGES_SUCCESS,
    payload: offerRange,
  });
};

export const registerOfferRanges = (
  project_id,
  range_id,
  offer_id,
  accessToken
) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${OFFER_RANGES_URL}`;
  try {
    const res = await apiClient({
      method: "post",
      url,
      headers,
      data: {
        project: project_id,
        range: range_id,
        offer: offer_id,
      },
    });

    dispatch({
      type: REGISTER_OFFER_RANGES_SUCCESS,
      payload: res.data,
    });
    dispatch(openSnackbar("Offre de gamme ajouté avec succès", "Success"));
  } catch (e) {
    console.log(e);

    dispatch({
      type: ADD_OFFER_RANGES_ERROR,
      payload: { error: e },
    });
  }
};

export const fetchOfferRanges = (offer_uuid, accessToken) => async (
  dispatch
) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${OFFER_RANGES_URL}?offer_uuid=${offer_uuid}`;
  try {
    const res = await apiClient({
      method: "get",
      url,
      headers,
    });
    dispatch({
      type: FETCH_OFFER_RANGES,
      payload: res.data.results,
    });
  } catch (e) {
    dispatch({
      type: FETCH_OFFER_RANGES_ERROR,
      payload: { error: e },
    });
  }
};

export const deleteOfferRanges = (
  list_offer_ranges_uuid,
  accessToken
) => async (dispatch) => {
  const listDeleteOfferRanges = [];
  let errorLog = { isError: false, message: null };
  await Promise.all(
    list_offer_ranges_uuid.map(async (uuid) => {
      const headers = {
        "Content-Type": `application/json;`,
        Authorization: `Bearer ${accessToken}`,
      };
      const url = `${OFFER_RANGES_URL}${uuid}/`;
      try {
        await apiClient({
          method: "delete",
          url,
          headers,
        });
        listDeleteOfferRanges.push(uuid);
      } catch (e) {
        errorLog = { isError: true, message: e };
      }
    })
  );
  if (errorLog.isError) {
    dispatch({
      type: DELETE_OFFER_RANGES_ERROR,
      payload: { error: errorLog.message },
    });
  } else {
    dispatch({
      type: DELETE_OFFER_RANGES,
      payload: deleteListOfferRanges(listDeleteOfferRanges),
    });
    dispatch(openSnackbar("Offre supprimée avec succès", "Success"));
  }
};

export const updateTotalOfferRanges = (totalOfferRange) => (dispatch) => {
  dispatch({
    type: UPDATE_TOTAL_OFFER_RANGES,
    payload: totalOfferRange,
  });
};
