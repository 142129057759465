import { getParsedFloatNumber } from "../range/RangeService";
import { store } from "../../redux/store";

function toFixedNumber(num, digits, base) {
  const pow = (base || 10) ** digits;
  return Math.round(num * pow) / pow;
}

// Calcul le resultat par rapport au pourcentage entrée
export function getResultByPercentage(sum, percentage) {
  return toFixedNumber((parseInt(percentage, 10) * parseInt(sum, 10)) / 100, 2);
}

export const getAddedValuePercentage = (addedValue, totalProduct) => {
  const parsedAddedValue = getParsedFloatNumber(addedValue);
  const parsedTotalProduct = getParsedFloatNumber(totalProduct);

  return toFixedNumber(
    getParsedFloatNumber((parsedAddedValue / parsedTotalProduct) * 100.0),
    2
  );
};

// Calcul le pourcentage par rapport à la valeur entrée
export function getPercentagetByValue(sum, value) {
  return toFixedNumber((parseInt(value, 10) * 100) / sum, 2);
}

// Calcul total product
export function getTotalProduct(projectIntermediateManagementThreshold) {
  return (
    getParsedFloatNumber(
      projectIntermediateManagementThreshold.net_turnover_excl_tax
    ) +
    getParsedFloatNumber(
      projectIntermediateManagementThreshold.others_products
    ) +
    getParsedFloatNumber(projectIntermediateManagementThreshold.production_used)
  );
}

// Calcul consumed Materials
export function getConsumedMaterials(projectIntermediateManagementThreshold) {
  return (
    getParsedFloatNumber(
      projectIntermediateManagementThreshold.raw_material_purchases
    ) +
    getParsedFloatNumber(
      projectIntermediateManagementThreshold.change_in_inventories_raw_material
    ) +
    getParsedFloatNumber(
      projectIntermediateManagementThreshold.purchase_goods
    ) +
    getParsedFloatNumber(
      projectIntermediateManagementThreshold.change_in_inventories_purchase_goods
    )
  );
}

// Calcul consumed Materials devise_part_fixe
export function getConsumedMaterialsDevisePartFixe(
  projectIntermediateManagementThreshold
) {
  return (
    getParsedFloatNumber(
      projectIntermediateManagementThreshold.raw_material_purchases_devise_part_fixe
    ) +
    getParsedFloatNumber(
      projectIntermediateManagementThreshold.change_in_inventories_raw_material_devise_part_fixe
    ) +
    getParsedFloatNumber(
      projectIntermediateManagementThreshold.purchase_goods_devise_part_fixe
    ) +
    getParsedFloatNumber(
      projectIntermediateManagementThreshold.change_in_inventories_purchase_goods_devise_part_fixe
    )
  );
}
// Calcul percentage consumed Materials
export function getConsumedMaterialsPercentage(
  totalProduct,
  consumedMatierals
) {
  return toFixedNumber(
    (getParsedFloatNumber(consumedMatierals) * 100) /
      getParsedFloatNumber(totalProduct),
    2
  );
}
// Calcul percentage gross margin
export function getGrossMarginPercentage(totalProduct, grossMargin) {
  return toFixedNumber(
    (parseInt(grossMargin, 10) * 100) / parseInt(totalProduct, 10),
    2
  );
}

export const getGrossMargin = (totalProduct, consumedMatierals) => {
  const parsedTotalProduct = getParsedFloatNumber(totalProduct);
  const parsedConsumedMatierals = getParsedFloatNumber(consumedMatierals);
  return toFixedNumber(
    getParsedFloatNumber(parsedTotalProduct - parsedConsumedMatierals),
    2
  );
};

export const getAddedValue = (grossMargin, totalExternalCosts) => {
  const parsedGrossMargin = getParsedFloatNumber(grossMargin);
  const parsedTotalExternalCosts = getParsedFloatNumber(totalExternalCosts);
  return toFixedNumber(
    getParsedFloatNumber(parsedGrossMargin - parsedTotalExternalCosts),
    2
  );
};

// Calcul percentage on total Product
export function getPercentageOnTotalProduct(totalProduct, value) {
  return toFixedNumber(
    (getParsedFloatNumber(value) * 100) / getParsedFloatNumber(totalProduct),
    2
  );
}

// Calcul OperatingResult
export function getOperatingResult() {
  const state = store.getState();
  const { sig } = state.sig;
  const { external_costs_summary } = state.sig;
  const { workforce_costs_summary } = state.sig;

  const gross_margin_operating_surplus =
    getAddedValue(
      getGrossMargin(getTotalProduct(sig), getConsumedMaterials(sig)),
      external_costs_summary.external_cost_total
    ) -
    sig.taxes_duties -
    workforce_costs_summary.external_cost_total -
    sig.other_operating_expenses;

  return toFixedNumber(
    gross_margin_operating_surplus -
      getParsedFloatNumber(sig.depreciation_amortization),
    2
  );
}

// Calcul Current Result Before Tax RCAI
export function getCurrentResultBeforeTaxRCAI(
  operatingResult,
  exceptional_result,
  financial_result
) {
  const parsedOperatingResult = getParsedFloatNumber(operatingResult);
  const parsedExceptionalResult = getParsedFloatNumber(exceptional_result);
  const parsedFinancialResult = getParsedFloatNumber(financial_result);
  return toFixedNumber(
    parsedOperatingResult - parsedExceptionalResult + parsedFinancialResult,
    2
  );
}

// Calcul Result Net
export function getNetResult(currentResult, profit_tax) {
  return toFixedNumber(currentResult - profit_tax, 0);
}

// Calcul Cash Flow
export function getCashFlow(netResult, depreciationAmortization) {
  const parsedNetResult = getParsedFloatNumber(netResult);
  const parsedDepreciationAmortization = getParsedFloatNumber(
    depreciationAmortization
  );
  return toFixedNumber(parsedNetResult + parsedDepreciationAmortization, 2);
}
// Calcul Profit Tax
export function getCalculProfitTax(
  currentResult,
  taxLowerTier,
  taxRateLowerTier,
  taxRateUpperTier
) {
  const currentResultFloat = getParsedFloatNumber(currentResult);
  const taxLowerTierFloat = getParsedFloatNumber(taxLowerTier);
  const taxRateLowerTierFloat = getParsedFloatNumber(taxRateLowerTier);
  const taxRateUpperTierFloat = getParsedFloatNumber(taxRateUpperTier);

  if (currentResultFloat > taxLowerTierFloat) {
    return (
      (taxRateLowerTierFloat / 100) * taxLowerTierFloat +
      (currentResultFloat - taxLowerTierFloat) * (taxRateUpperTierFloat / 100)
    );
  }

  return toFixedNumber(currentResultFloat * (taxRateLowerTierFloat / 100), 2);
}
