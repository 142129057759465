/* eslint-disable no-unused-vars */
import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Card, Grid, Divider } from "@material-ui/core";
import { useSelector } from "react-redux";
import TurnoverRangeDashboard from "./TurnoverRangeDashboard";

const useStyles = makeStyles(() => ({
  ButtonRight: {
    width: "100%",
  },
  underlineTextField: {
    "& .MuiInput-underline:before": {
      borderBottom: "0px",
    },
  },
  input: {
    "& input": {
      width: "25px",
    },
    "& i.MuiInputBase-input::after": {
      content: "%",
    },
  },
  margin: {
    marginTop: "20px",
  },
}));

function RangeDashboard() {
  const classes = useStyles();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const { rangeId } = useParams();
  return (
    <Card>
      <TurnoverRangeDashboard />
      <Divider my={6} mr={24} className={classes.margin} />
      <Grid container spacing={6} />
    </Card>
  );
}

export default RangeDashboard;
