import { USER_ACTION } from "../types/userActionTypes";
import { CLEAN_CSER } from "../types/cserActionTypes";
import { CLEAN_OFFERS } from "../types/offerActionTypes";
import { CLEAN_OFFER_RANGES } from "../types/offerRangeActionTypes";
import { CLEAN_PRODUCT } from "../types/productActionTypes";
import { CLEAN_RANGE } from "../types/rangeActionTypes";
import { CLEAN_RANGE_PRODUCTS } from "../types/rangeProductsActionTypes";
import { CLEAN_SIG } from "../types/sigActionTypes";
import { CLEAN_TECHNICAL_SHEET } from "../types/technicalSheetActionTypes";

export const updateUserAction = (userAction) => async (dispatch) => {
  dispatch({
    type: USER_ACTION,
    payload: userAction,
  });
};

export const resetStoreAction = () => async (dispatch) => {
  dispatch({
    type: CLEAN_CSER,
  });
  dispatch({
    type: CLEAN_OFFERS,
  });
  dispatch({
    type: CLEAN_OFFER_RANGES,
  });
  dispatch({
    type: CLEAN_PRODUCT,
  });
  dispatch({
    type: CLEAN_RANGE,
  });
  dispatch({
    type: CLEAN_RANGE_PRODUCTS,
  });
  dispatch({
    type: CLEAN_SIG,
  });
  dispatch({
    type: CLEAN_TECHNICAL_SHEET,
  });
};

export default updateUserAction;
