import {
  getExternalCostTotal,
  getExternalCostTotalFixedPart,
} from "../external_costs/external_costs_summary";
import { toFixedNumber, getParsedFloatNumber } from "../range/RangeService";
import { store } from "../../redux/store";
import {
  getConsumptionOfRawMaterials,
  getConsumptionOfRawMaterialsAmountVariableExpenses,
  getExternalCostsAmountVariableExpenses,
  getFinancialExpensesAmountVariableExpenses,
  getFinancialExpensesTaxes,
  getFinancialExpensesTaxesAmountFixedExpenses,
  getWorkforceCostAmountVariableExpenses,
} from "../sig/LoadAnalysisMethodService";
import {
  getConsumedMaterials,
  getConsumedMaterialsDevisePartFixe,
} from "../sig/SigCalculService";

// B6
export const getVariableCostsForAdjustedImt = (
  sig,
  workforce_costs,
  external_costs
) => {
  if (!workforce_costs || !external_costs || !sig) {
    return 0;
  }
  if (
    !sig.production_used &&
    !sig.taxes_duties &&
    !sig.other_operating_expenses &&
    !sig.financial_result &&
    !sig.taxes_duties_devise_part_fixe &&
    !sig.other_operating_expenses_devise_part_fixe &&
    !sig.financial_result_devise_part_fixe
  ) {
    return 0;
  }

  return (
    getConsumptionOfRawMaterialsAmountVariableExpenses(
      getConsumptionOfRawMaterials(
        getConsumedMaterials(sig),
        sig.production_used
      ),
      getConsumedMaterialsDevisePartFixe(sig)
    ) +
    getWorkforceCostAmountVariableExpenses(
      getExternalCostTotal(workforce_costs),
      getExternalCostTotalFixedPart(workforce_costs)
    ) +
    getExternalCostsAmountVariableExpenses(
      getExternalCostTotal(external_costs),
      getExternalCostTotalFixedPart(external_costs)
    ) +
    getFinancialExpensesAmountVariableExpenses(
      getFinancialExpensesTaxes(
        sig.taxes_duties,
        sig.other_operating_expenses,
        sig.financial_result
      ),
      getFinancialExpensesTaxesAmountFixedExpenses(
        sig.taxes_duties_devise_part_fixe,
        sig.other_operating_expenses_devise_part_fixe,
        sig.financial_result_devise_part_fixe
      )
    )
  );
};

// B7
export const getMarginOnVariableCostsForAdjustedImt = (
  simulationAdjustedImtTurnoverBeforeTax,
  simulationAdjustedImtVariableCosts
) => {
  const parsed_simulationAdjustedImtTurnoverBeforeTax = getParsedFloatNumber(
    simulationAdjustedImtTurnoverBeforeTax
  );
  const parsed_simulationAdjustedImtVariableCosts = getParsedFloatNumber(
    simulationAdjustedImtVariableCosts
  );

  return toFixedNumber(
    getParsedFloatNumber(
      parsed_simulationAdjustedImtTurnoverBeforeTax -
        parsed_simulationAdjustedImtVariableCosts
    ),
    2
  );
};

// B8
export const getFixedCostsForAdjustedImt = (
  sig,
  workforce_costs,
  external_costs
) => {
  if (workforce_costs.length === 0 || external_costs.length === 0) {
    return 0;
  }
  return (
    getConsumedMaterialsDevisePartFixe(sig) +
    getExternalCostTotalFixedPart(workforce_costs) +
    getExternalCostTotalFixedPart(external_costs) +
    getFinancialExpensesTaxesAmountFixedExpenses(
      sig.taxes_duties_devise_part_fixe,
      sig.other_operating_expenses_devise_part_fixe,
      sig.financial_result_devise_part_fixe
    )
  );
};

// B9
export const getCurrentIncomeBeforeTaxesForAdjustedImt = (
  simulationAdjustedImtMarginOnVariableCosts,
  simulationAdjustedImtFixedCosts
) => {
  const parsed_simulationAdjustedImtMarginOnVariableCosts = getParsedFloatNumber(
    simulationAdjustedImtMarginOnVariableCosts
  );
  const parsed_simulationAdjustedImtFixedCosts = getParsedFloatNumber(
    simulationAdjustedImtFixedCosts
  );

  return toFixedNumber(
    getParsedFloatNumber(
      parsed_simulationAdjustedImtMarginOnVariableCosts -
        parsed_simulationAdjustedImtFixedCosts
    ),
    2
  );
};

// B10
export const getCorporateTaxesForAdjustedImt = (
  taxPercentageOnLowerSlice,
  taxPercentageOnHigherSlice,
  lowerSliceTaxValue,
  currentIncomeBeforeTaxes
) => {
  const parsed_taxPercentageOnLowerSlice =
    getParsedFloatNumber(taxPercentageOnLowerSlice) / 100;
  const parsed_taxPercentageOnHigherSlice =
    getParsedFloatNumber(taxPercentageOnHigherSlice) / 100;
  const parsed_lowerSliceTaxValue = getParsedFloatNumber(lowerSliceTaxValue);
  const parsed_currentIncomeBeforeTaxes = getParsedFloatNumber(
    currentIncomeBeforeTaxes
  );

  if (parsed_currentIncomeBeforeTaxes > parsed_lowerSliceTaxValue) {
    const result =
      parsed_lowerSliceTaxValue * parsed_taxPercentageOnLowerSlice +
      (parsed_currentIncomeBeforeTaxes - parsed_lowerSliceTaxValue) *
        parsed_taxPercentageOnHigherSlice;
    return toFixedNumber(getParsedFloatNumber(result), 2);
  }

  if (parsed_currentIncomeBeforeTaxes > 0) {
    return toFixedNumber(
      getParsedFloatNumber(
        parsed_currentIncomeBeforeTaxes * parsed_taxPercentageOnLowerSlice
      ),
      2
    );
  }

  return 0;
};

// B11
export const getNetProfitAfterTaxForAdjustedImt = (
  currentIncomeBeforeTaxes,
  corporateTaxes
) => {
  const parsed_currentIncomeBeforeTaxes = getParsedFloatNumber(
    currentIncomeBeforeTaxes
  );
  const parsed_corporateTaxes = getParsedFloatNumber(corporateTaxes);

  return toFixedNumber(
    getParsedFloatNumber(
      parsed_currentIncomeBeforeTaxes - parsed_corporateTaxes
    ),
    2
  );
};

// B12
export const getCashFlowForAdjustedImt = (
  netProfitAfterTaxes,
  sigDepreciationAmortizationDevisePartFixe
) => {
  const parsed_netProfitAfterTaxes = getParsedFloatNumber(netProfitAfterTaxes);
  const parsed_sigDepreciationAmortizationDevisePartFixe = getParsedFloatNumber(
    sigDepreciationAmortizationDevisePartFixe
  );

  return toFixedNumber(
    getParsedFloatNumber(
      parsed_netProfitAfterTaxes +
        parsed_sigDepreciationAmortizationDevisePartFixe
    ),
    2
  );
};

export const getCashflowForProfitabilityThreshold = (
  sigDepreciationAmortizationDevisePartFixe
) => {
  return toFixedNumber(
    getParsedFloatNumber(sigDepreciationAmortizationDevisePartFixe),
    2
  );
};

// B14
export const getNetSurplusForAdjustedImt = (cashFlow, loanRepayments) => {
  const parsed_cashFlow = getParsedFloatNumber(cashFlow);
  const parsed_loanRepayments = getParsedFloatNumber(loanRepayments);
  return toFixedNumber(
    getParsedFloatNumber(parsed_cashFlow - parsed_loanRepayments),
    2
  );
};

// C5
export const getTurnoverBeforeTaxPercentageForAdjustedImt = (
  turnoverBeforeTax
) => {
  if (getParsedFloatNumber(turnoverBeforeTax) !== 0) {
    return 100;
  }
  return 0;
};

// C6
export const getVariableCostsPercentageForAdjustedImt = (
  variableCosts,
  turnoverBeforeTax
) => {
  const parsed_variableCosts = getParsedFloatNumber(variableCosts);
  const parsed_turnoverBeforeTax = getParsedFloatNumber(turnoverBeforeTax);

  return toFixedNumber(
    getParsedFloatNumber(
      (parsed_variableCosts / parsed_turnoverBeforeTax) * 100
    ),
    2
  );
};

// C7
export const getMarginOnVariableCostsPercentageForAdjustedImt = (
  variableCostsPercentage,
  turnoverBeforeTaxPercentage
) => {
  const parsed_variableCostsPercentage = getParsedFloatNumber(
    variableCostsPercentage
  );
  const parsed_turnoverBeforeTaxPercentage = getParsedFloatNumber(
    turnoverBeforeTaxPercentage
  );
  return toFixedNumber(
    getParsedFloatNumber(
      parsed_turnoverBeforeTaxPercentage - parsed_variableCostsPercentage
    ),
    2
  );
};

// C9

export const getCurrentIncomeBeforeTaxesPercentageForAdjustedImt = (
  currentIncomeBeforeTaxes,
  turnoverBeforeTaxPercentage,
  turnoverBeforeTax
) => {
  const parsed_currentIncomeBeforeTaxes = getParsedFloatNumber(
    currentIncomeBeforeTaxes
  );
  const parsed_turnoverBeforeTaxPercentage = getParsedFloatNumber(
    turnoverBeforeTaxPercentage / 100
  );
  const parsed_turnoverBeforeTax = getParsedFloatNumber(turnoverBeforeTax);

  return toFixedNumber(
    getParsedFloatNumber(
      ((parsed_currentIncomeBeforeTaxes * parsed_turnoverBeforeTaxPercentage) /
        parsed_turnoverBeforeTax) *
        100
    ),
    2
  );
};

// C11
export const getNetProfitAfterTaxPercentageForAdjustedImt = (
  netProfitAfterTaxes,
  turnoverBeforeTaxPercentage,
  turnoverBeforeTax
) => {
  const parsed_turnoverBeforeTaxPercentage = getParsedFloatNumber(
    turnoverBeforeTaxPercentage / 100
  );
  const parsed_turnoverBeforeTax = getParsedFloatNumber(turnoverBeforeTax);
  const parsed_netProfitAfterTaxes = getParsedFloatNumber(netProfitAfterTaxes);
  return toFixedNumber(
    getParsedFloatNumber(
      ((parsed_netProfitAfterTaxes * parsed_turnoverBeforeTaxPercentage) /
        parsed_turnoverBeforeTax) *
        100
    ),
    2
  );
};

// C12
export const getCashFlowForPercentageAdjustedImt = (
  cashFlow,
  turnoverBeforeTaxPercentage,
  turnoverBeforeTax
) => {
  const parsed_turnoverBeforeTaxPercentage = getParsedFloatNumber(
    turnoverBeforeTaxPercentage / 100
  );
  const parsed_turnoverBeforeTax = getParsedFloatNumber(turnoverBeforeTax);
  const parsed_cashFlow = getParsedFloatNumber(cashFlow);
  return toFixedNumber(
    getParsedFloatNumber(
      ((parsed_cashFlow * parsed_turnoverBeforeTaxPercentage) /
        parsed_turnoverBeforeTax) *
        100
    ),
    2
  );
};

// C13
export const getLoanRepaymentPercentageForAdjustedImt = (
  loanRepayments,
  turnoverBeforeTaxPercentage,
  turnoverBeforeTax
) => {
  const parsed_turnoverBeforeTaxPercentage = getParsedFloatNumber(
    turnoverBeforeTaxPercentage / 100
  );
  const parsed_turnoverBeforeTax = getParsedFloatNumber(turnoverBeforeTax);
  const parsed_loanRepayments = getParsedFloatNumber(loanRepayments);
  return toFixedNumber(
    getParsedFloatNumber(
      ((parsed_loanRepayments * parsed_turnoverBeforeTaxPercentage) /
        parsed_turnoverBeforeTax) *
        100
    ),
    2
  );
};

// C14
export const getNetSurplusPercentageForAdjustedImt = (
  netSurplus,
  turnoverBeforeTaxPercentage,
  turnoverBeforeTax
) => {
  const parsed_turnoverBeforeTaxPercentage = getParsedFloatNumber(
    turnoverBeforeTaxPercentage / 100
  );
  const parsed_turnoverBeforeTax = getParsedFloatNumber(turnoverBeforeTax);
  const parsed_netSurplus = getParsedFloatNumber(netSurplus);
  return toFixedNumber(
    getParsedFloatNumber(
      ((parsed_netSurplus * parsed_turnoverBeforeTaxPercentage) /
        parsed_turnoverBeforeTax) *
        100
    ),
    2
  );
};

// B21
export const getIndicatorsFillRateForAdjustedImt = (
  indicators_number_of_customers_by_day,
  sig_capacity_per_day
) => {
  const parsed_indicators_number_of_customers_by_day = getParsedFloatNumber(
    indicators_number_of_customers_by_day
  );
  const parsed_sig_capacity_per_day = getParsedFloatNumber(
    sig_capacity_per_day
  );
  return toFixedNumber(
    getParsedFloatNumber(
      (parsed_indicators_number_of_customers_by_day /
        parsed_sig_capacity_per_day) *
        100
    ),
    2
  );
};

// B22

export const getIndicatorsAveragePriceInEuros = (
  indicators_turnover_day,
  indicators_number_of_customers_by_day
) => {
  const parsed_indicators_turnover_day = getParsedFloatNumber(
    indicators_turnover_day
  );
  const parsed_indicators_number_of_customers_by_day = getParsedFloatNumber(
    indicators_number_of_customers_by_day
  );
  return toFixedNumber(
    getParsedFloatNumber(
      parsed_indicators_turnover_day /
        parsed_indicators_number_of_customers_by_day
    ),
    2
  );
};

// B24
export const getIndicatorsTurnoverDayForAdjustedImt = (
  turnoverBeforeTax,
  sigNumberDaysOpenPerYear
) => {
  const parsed_turnoverBeforeTax = getParsedFloatNumber(turnoverBeforeTax);
  const parsed_sigNumberDaysOpenPerYear = getParsedFloatNumber(
    sigNumberDaysOpenPerYear
  );
  return toFixedNumber(
    getParsedFloatNumber(
      parsed_turnoverBeforeTax / parsed_sigNumberDaysOpenPerYear
    ),
    2
  );
};

// B26
export const getIndicatorsNumberOfCustomersByWeek = (
  indicators_number_of_customers_by_day,
  sig_number_days_open_per_year,
  sig_number_weeks_open_per_year
) => {
  const parsed_indicators_number_of_customers_by_day = getParsedFloatNumber(
    indicators_number_of_customers_by_day
  );
  const parsed_sig_number_days_open_per_year = getParsedFloatNumber(
    sig_number_days_open_per_year
  );
  const parsed_sig_number_weeks_open_per_year = getParsedFloatNumber(
    sig_number_weeks_open_per_year
  );
  return toFixedNumber(
    getParsedFloatNumber(
      (parsed_indicators_number_of_customers_by_day *
        parsed_sig_number_days_open_per_year) /
        ((parsed_sig_number_days_open_per_year *
          parsed_sig_number_weeks_open_per_year) /
          365)
    ),
    0
  );
};

// B27
export const getIndicatorsGrossMarginInEuros = (
  grossMarginInEurosExcludingConsumedProductionForAdjustedImt,
  sig_number_days_open_per_year
) => {
  const parsed_grossMarginInEurosExcludingConsumedProductionForAdjustedImt = getParsedFloatNumber(
    grossMarginInEurosExcludingConsumedProductionForAdjustedImt
  );
  const parsed_sig_number_days_open_per_year = getParsedFloatNumber(
    sig_number_days_open_per_year
  );
  return toFixedNumber(
    getParsedFloatNumber(
      parsed_grossMarginInEurosExcludingConsumedProductionForAdjustedImt /
        parsed_sig_number_days_open_per_year
    ),
    2
  );
};

// B30
export const getIndicatorsCoefficientForAdjustedImt = (
  sig,
  turnover_before_tax
) => {
  const consumptionOfRawMaterialsAmountVariableExpenses = getConsumptionOfRawMaterialsAmountVariableExpenses(
    getConsumptionOfRawMaterials(
      getConsumedMaterials(sig),
      sig.production_used
    ),
    getConsumedMaterialsDevisePartFixe(sig)
  );
  const parsed_turnover_before_tax = getParsedFloatNumber(turnover_before_tax);
  return toFixedNumber(
    getParsedFloatNumber(
      parsed_turnover_before_tax /
        consumptionOfRawMaterialsAmountVariableExpenses
    ),
    3
  );
};

export const getIndicatorsRatioOfMaterials = (indicators_coefficients) => {
  const parsed_indicators_coefficients = getParsedFloatNumber(
    indicators_coefficients
  );
  return toFixedNumber(
    getParsedFloatNumber(1 / parsed_indicators_coefficients) * 100,
    2
  );
};

// B32
export const getGrossMarginInEurosExcludingConsumedProductionForAdjustedImt = (
  turnoverBeforeTax,
  indicators_coefficients
) => {
  const parsed_turnoverBeforeTax = getParsedFloatNumber(turnoverBeforeTax);
  const parsed_indicators_coefficients = getParsedFloatNumber(
    indicators_coefficients
  );
  return toFixedNumber(
    getParsedFloatNumber(
      parsed_turnoverBeforeTax -
        parsed_turnoverBeforeTax / parsed_indicators_coefficients
    ),
    2
  );
};

// D6
export const getSimulationVariableCostsProfitabilityThreshold = (
  adjusted_imt_simulation_variable_costs_percentage,
  adjusted_imt_simulation_fixed_costs,
  adjusted_imt_simulation_margin_on_variable_costs_percentage
) => {
  const parsed_adjusted_imt_simulation_variable_costs_percentage = getParsedFloatNumber(
    adjusted_imt_simulation_variable_costs_percentage
  );
  const parsed_adjusted_imt_simulation_fixed_costs = getParsedFloatNumber(
    adjusted_imt_simulation_fixed_costs
  );
  const parsed_adjusted_imt_simulation_margin_on_variable_costs_percentage = getParsedFloatNumber(
    adjusted_imt_simulation_margin_on_variable_costs_percentage
  );

  return toFixedNumber(
    getParsedFloatNumber(
      (parsed_adjusted_imt_simulation_variable_costs_percentage *
        parsed_adjusted_imt_simulation_fixed_costs) /
        parsed_adjusted_imt_simulation_margin_on_variable_costs_percentage
    ),
    0
  );
};

// D5
export const getSimulationTurnoverBeforeTaxProfitabilityThreshold = (
  simulation_fixed_costs,
  adjusted_imt_simulation_margin_on_variable_costs_percentage
) => {
  const parsed_simulation_fixed_costs = getParsedFloatNumber(
    simulation_fixed_costs
  );
  const parsed_adjusted_imt_simulation_margin_on_variable_costs_percentage = getParsedFloatNumber(
    adjusted_imt_simulation_margin_on_variable_costs_percentage / 100
  );
  return toFixedNumber(
    getParsedFloatNumber(
      parsed_simulation_fixed_costs /
        parsed_adjusted_imt_simulation_margin_on_variable_costs_percentage
    ),
    2
  );
};

// E5
export const getTurnoverBeforeTaxSerGrowthTurnover = (
  margin_on_variable_costs_ser_growth_turnover,
  margin_on_variable_costs_adjsuted_imt_percentage
) => {
  const parsed_margin_on_variable_costs_ser_growth_turnover = getParsedFloatNumber(
    margin_on_variable_costs_ser_growth_turnover
  );
  const parsed_margin_on_variable_costs_adjsuted_imt_percentage =
    getParsedFloatNumber(margin_on_variable_costs_adjsuted_imt_percentage) /
    100;
  return toFixedNumber(
    getParsedFloatNumber(
      parsed_margin_on_variable_costs_ser_growth_turnover /
        parsed_margin_on_variable_costs_adjsuted_imt_percentage
    ),
    2
  );
};

// E5 %
export const getTurnoverBeforeTaxPercentageSerGrowthTurnover = (
  turnover_before_tax_ser_growth_turnover,
  adjusted_imt_simulation_turnover_before_tax
) => {
  const parsed_turnover_before_tax_ser_growth_turnover = getParsedFloatNumber(
    turnover_before_tax_ser_growth_turnover
  );
  const parsed_adjusted_imt_simulation_turnover_before_tax = getParsedFloatNumber(
    adjusted_imt_simulation_turnover_before_tax
  );
  return toFixedNumber(
    getParsedFloatNumber(
      (parsed_turnover_before_tax_ser_growth_turnover /
        parsed_adjusted_imt_simulation_turnover_before_tax -
        1) *
        100
    ),
    2
  );
};

// E6
export const getSerGrowthTurnoverSimulationVariableCosts = (
  simulation_variable_costs_percentage,
  margin_on_variable_costs_ser_growth_turnover,
  margin_on_variable_costs_adjsuted_imt_percentage
) => {
  const parsed_simulation_variable_costs_percentage = getParsedFloatNumber(
    simulation_variable_costs_percentage
  );
  const parsed_margin_on_variable_costs_ser_growth_turnover = getParsedFloatNumber(
    margin_on_variable_costs_ser_growth_turnover
  );
  const parsed_margin_on_variable_costs_adjsuted_imt_percentage = getParsedFloatNumber(
    margin_on_variable_costs_adjsuted_imt_percentage
  );
  return toFixedNumber(
    getParsedFloatNumber(
      (parsed_simulation_variable_costs_percentage *
        parsed_margin_on_variable_costs_ser_growth_turnover) /
        parsed_margin_on_variable_costs_adjsuted_imt_percentage
    ),
    2
  );
};

// E7
export const getSerGrowthTurnoverSimulationMarginOnVariableCosts = (
  ser_growth_turnover_simulation_fixed_costs,
  ser_growth_turnover_simulation_current_income_before_taxes
) => {
  const parsed_ser_growth_turnover_simulation_fixed_costs = getParsedFloatNumber(
    ser_growth_turnover_simulation_fixed_costs
  );
  const parsed_ser_growth_turnover_simulation_current_income_before_taxes = getParsedFloatNumber(
    ser_growth_turnover_simulation_current_income_before_taxes
  );
  return toFixedNumber(
    getParsedFloatNumber(
      parsed_ser_growth_turnover_simulation_fixed_costs +
        parsed_ser_growth_turnover_simulation_current_income_before_taxes
    ),
    2
  );
};

export const getIndicatorsNumberOfCustomersByDayForProfitabilityThreshold = (
  profitability_threshold_indicators_turnover_day,
  adjusted_imt_indicators_average_price_in_euros
) => {
  const parsed_profitability_threshold_indicators_turnover_day = getParsedFloatNumber(
    profitability_threshold_indicators_turnover_day
  );
  const parsed_adjusted_imt_indicators_average_price_in_euros = getParsedFloatNumber(
    adjusted_imt_indicators_average_price_in_euros
  );
  return toFixedNumber(
    getParsedFloatNumber(
      parsed_profitability_threshold_indicators_turnover_day /
        parsed_adjusted_imt_indicators_average_price_in_euros
    ),
    0
  );
};

export const getIndicatorsNumberOfCustomersByWeekForProfitabilityThreshold = (
  profitability_threshold_indicators_number_of_customers_by_day,
  sig_number_days_open_per_year
) => {
  const parsed_profitability_threshold_indicators_number_of_customers_by_day = getParsedFloatNumber(
    profitability_threshold_indicators_number_of_customers_by_day
  );
  const parsed_sig_number_days_open_per_year = getParsedFloatNumber(
    sig_number_days_open_per_year
  );
  return toFixedNumber(
    getParsedFloatNumber(
      (parsed_profitability_threshold_indicators_number_of_customers_by_day *
        parsed_sig_number_days_open_per_year) /
        ((parsed_sig_number_days_open_per_year / 365) * 52)
    ),
    0
  );
};

export const getSimulationCashfFlowForSerGrowthTurnover = (
  ser_growth_turnover_simulation_loan_repayments,
  ser_growth_turnover_simulation_net_surplus
) => {
  const parsed_ser_growth_turnover_simulation_loan_repayments = getParsedFloatNumber(
    ser_growth_turnover_simulation_loan_repayments
  );
  const parsed_ser_growth_turnover_simulation_net_surplus = getParsedFloatNumber(
    ser_growth_turnover_simulation_net_surplus
  );

  return toFixedNumber(
    getParsedFloatNumber(
      parsed_ser_growth_turnover_simulation_loan_repayments +
        parsed_ser_growth_turnover_simulation_net_surplus
    ),
    2
  );
};

export const getSimulationCurrentIncomeBeforeTaxesForSerGrowthTurnover = (
  sig_depreciation_amortization_devise_part_fixe,
  ser_growth_turnover_simulation_cash_flow,
  sig_tax_lower_tier,
  sig_tax_rate_lower_tier,
  sig_tax_rate_upper_tier
) => {
  const parsed_sig_depreciation_amortization_devise_part_fixe = getParsedFloatNumber(
    sig_depreciation_amortization_devise_part_fixe
  );
  const parsed_ser_growth_turnover_simulation_cash_flow = getParsedFloatNumber(
    ser_growth_turnover_simulation_cash_flow
  );
  const parsed_sig_tax_lower_tier = getParsedFloatNumber(sig_tax_lower_tier);
  const parsed_sig_tax_rate_lower_tier =
    getParsedFloatNumber(sig_tax_rate_lower_tier) / 100;
  const parsed_sig_tax_rate_upper_tier =
    getParsedFloatNumber(sig_tax_rate_upper_tier) / 100;

  if (
    parsed_ser_growth_turnover_simulation_cash_flow -
      parsed_sig_depreciation_amortization_devise_part_fixe >
    parsed_sig_tax_lower_tier * (1 - parsed_sig_tax_rate_lower_tier)
  ) {
    return toFixedNumber(
      getParsedFloatNumber(
        (parsed_ser_growth_turnover_simulation_cash_flow -
          parsed_sig_depreciation_amortization_devise_part_fixe -
          (parsed_sig_tax_rate_upper_tier - parsed_sig_tax_rate_lower_tier) *
            parsed_sig_tax_lower_tier) /
          (1 - parsed_sig_tax_rate_upper_tier)
      ),
      2
    );
  }

  if (
    parsed_ser_growth_turnover_simulation_cash_flow >
    parsed_sig_depreciation_amortization_devise_part_fixe
  ) {
    return toFixedNumber(
      getParsedFloatNumber(
        (parsed_ser_growth_turnover_simulation_cash_flow -
          parsed_sig_depreciation_amortization_devise_part_fixe) /
          0,
        85
      ),
      2
    );
  }

  return (
    parsed_ser_growth_turnover_simulation_cash_flow -
    parsed_sig_depreciation_amortization_devise_part_fixe
  );
};

export const getSimulationNetProfitAfterTaxForSerGrowthTurnover = (
  sig_depreciation_amortization,
  ser_growth_turnover_simulation_current_income_before_taxes,
  ser_growth_turnover_simulation_cash_flow
) => {
  const parsed_sig_depreciation_amortization = getParsedFloatNumber(
    sig_depreciation_amortization
  );
  const parsed_ser_growth_turnover_simulation_current_income_before_taxes = getParsedFloatNumber(
    ser_growth_turnover_simulation_current_income_before_taxes
  );
  const parsed_ser_growth_turnover_simulation_cash_flow = getParsedFloatNumber(
    ser_growth_turnover_simulation_cash_flow
  );

  if (parsed_ser_growth_turnover_simulation_current_income_before_taxes < 0) {
    return toFixedNumber(
      getParsedFloatNumber(
        parsed_ser_growth_turnover_simulation_current_income_before_taxes -
          parsed_sig_depreciation_amortization
      ),
      2
    );
  }
  return toFixedNumber(
    getParsedFloatNumber(
      parsed_ser_growth_turnover_simulation_cash_flow -
        parsed_sig_depreciation_amortization
    ),
    2
  );
};

export const getSimulationCorporateTaxesForSerGrowthTurnover = (
  ser_growth_turnover_simulation_current_income_before_taxes,
  ser_growth_turnover_simulation_net_profit_after_tax
) => {
  const parsed_ser_growth_turnover_simulation_current_income_before_taxes = getParsedFloatNumber(
    ser_growth_turnover_simulation_current_income_before_taxes
  );
  const parsed_ser_growth_turnover_simulation_net_profit_after_tax = getParsedFloatNumber(
    ser_growth_turnover_simulation_net_profit_after_tax
  );
  return toFixedNumber(
    getParsedFloatNumber(
      parsed_ser_growth_turnover_simulation_current_income_before_taxes -
        parsed_ser_growth_turnover_simulation_net_profit_after_tax
    ),
    2
  );
};

export const getNumberOfCustomerByDayForSerGrowthTurnoverAvPriceStable = (
  ser_growth_turnover_indicators_turnover_day,
  ser_growth_turnover_indicators_average_price_stable_average_price_in_euros
) => {
  const parsed_ser_growth_turnover_indicators_turnover_day = getParsedFloatNumber(
    ser_growth_turnover_indicators_turnover_day
  );
  const parsed_ser_growth_turnover_indicators_average_price_stable_average_price_in_euros = getParsedFloatNumber(
    ser_growth_turnover_indicators_average_price_stable_average_price_in_euros
  );

  return toFixedNumber(
    getParsedFloatNumber(
      parsed_ser_growth_turnover_indicators_turnover_day /
        parsed_ser_growth_turnover_indicators_average_price_stable_average_price_in_euros
    ),
    0
  );
};

// G22
export const getAveragePriceForSerGrowthTurnoverWithAveragePriceIncrease = (
  average_price_increase_price_increase,
  adjusted_imt_indicators_average_price_in_euros
) => {
  const parsed_average_price_increase_price_increase =
    getParsedFloatNumber(average_price_increase_price_increase) / 100;
  const parsed_adjusted_imt_indicators_average_price_in_euros = getParsedFloatNumber(
    adjusted_imt_indicators_average_price_in_euros
  );
  return toFixedNumber(
    getParsedFloatNumber(
      parsed_adjusted_imt_indicators_average_price_in_euros *
        (1 + parsed_average_price_increase_price_increase)
    ),
    2
  );
};

// I5
export const getTurnoverBeforeTaxPercentageForSerForecast = (
  adjusted_imt_simulation_turnover_before_tax,
  ser_forecast_simulation_percentage_of_change_in_revenues
) => {
  const parsed_adjusted_imt_simulation_turnover_before_tax = getParsedFloatNumber(
    adjusted_imt_simulation_turnover_before_tax
  );
  const parsed_ser_forecast_simulation_percentage_of_change_in_revenues =
    getParsedFloatNumber(
      ser_forecast_simulation_percentage_of_change_in_revenues
    ) / 100;

  return toFixedNumber(
    getParsedFloatNumber(
      parsed_adjusted_imt_simulation_turnover_before_tax *
        (1 + parsed_ser_forecast_simulation_percentage_of_change_in_revenues)
    ),
    2
  );
};

// I6
export const getSimulationMarginOnVariableCostsForSerForecast = (
  ser_forceast_simulation_margin_on_variable_costs_percentage,
  ser_forecast_simulation_simulation_turnover_before_tax
) => {
  const parsed_ser_forceast_simulation_margin_on_variable_costs_percentage =
    getParsedFloatNumber(
      ser_forceast_simulation_margin_on_variable_costs_percentage
    ) / 100;
  const parsed_ser_forecast_simulation_simulation_turnover_before_tax = getParsedFloatNumber(
    ser_forecast_simulation_simulation_turnover_before_tax
  );
  return toFixedNumber(
    getParsedFloatNumber(
      parsed_ser_forecast_simulation_simulation_turnover_before_tax *
        parsed_ser_forceast_simulation_margin_on_variable_costs_percentage
    ),
    2
  );
};

// H7
export const getSimulationPercentageOfVariableLoadRateForSerForecast = (
  ser_forceast_simulation_margin_on_variable_costs_percentage
) => {
  const parsed_ser_forceast_simulation_margin_on_variable_costs_percentage =
    getParsedFloatNumber(
      ser_forceast_simulation_margin_on_variable_costs_percentage
    ) / 100;
  return toFixedNumber(
    getParsedFloatNumber(
      (1 - parsed_ser_forceast_simulation_margin_on_variable_costs_percentage) *
        100
    ),
    2
  );
};

export const getNewVariableCostsRateFromPercentageReductionMaterialConsumption = (
  percentageReductionMaterialConsumption
) => {
  const { sig } = store.getState().sig;
  const { adjusted_imt } = store.getState().cser.computed_cser;
  const sig_consumption_of_raw_materials = getConsumptionOfRawMaterials(
    getConsumedMaterials(sig),
    sig.production_used
  );
  const parsed_percentageReductionMaterialConsumption =
    getParsedFloatNumber(percentageReductionMaterialConsumption) / 100;
  if (parsed_percentageReductionMaterialConsumption === 0) {
    return 0;
  }
  const parsed_sig_consumption_of_raw_materials = getParsedFloatNumber(
    sig_consumption_of_raw_materials
  );
  const parsed_adjusted_imt_simulation_turnover_before_tax = getParsedFloatNumber(
    adjusted_imt.simulation_turnover_before_tax
  );
  const parsed_adjusted_imt_simulation_variable_costs_percentage =
    getParsedFloatNumber(adjusted_imt.simulation_variable_costs_percentage) /
    100;

  return toFixedNumber(
    getParsedFloatNumber(
      parsed_adjusted_imt_simulation_variable_costs_percentage -
        (parsed_sig_consumption_of_raw_materials *
          parsed_percentageReductionMaterialConsumption) /
          parsed_adjusted_imt_simulation_turnover_before_tax
    ) * 100,
    2
  );
};

// I6

export const getVariableCostsForSerForecast = (
  ser_forecast_simulation_turnover_before_tax,
  ser_forecast_simulation_percentage_of_variable_load_rate
) => {
  const parsed_ser_forecast_simulation_turnover_before_tax = getParsedFloatNumber(
    ser_forecast_simulation_turnover_before_tax
  );
  const parsed_ser_forecast_simulation_percentage_of_variable_load_rate =
    getParsedFloatNumber(
      ser_forecast_simulation_percentage_of_variable_load_rate
    ) / 100;

  return toFixedNumber(
    getParsedFloatNumber(
      parsed_ser_forecast_simulation_turnover_before_tax *
        parsed_ser_forecast_simulation_percentage_of_variable_load_rate
    ),
    2
  );
};

// H7
export const getMarginOnVariableCostsPercentageForSerForecast = (
  ser_forecast_simulation_percentge_of_variable_load_rate
) => {
  const parsed_ser_forecast_simulation_percentge_of_variable_load_rate =
    getParsedFloatNumber(
      ser_forecast_simulation_percentge_of_variable_load_rate
    ) / 100;
  return toFixedNumber(
    getParsedFloatNumber(
      1 - parsed_ser_forecast_simulation_percentge_of_variable_load_rate
    ) * 100,
    2
  );
};

// H30
// I5/(('Répartition des charges'!D7/'Répartition des charges'!D11*Cser!I6))
export const getIndicatorsCoefficientForSerForecast = (
  ser_forecast_simulation_turnover_before_tax,
  sig,
  ser_forecast_variable_costs
) => {
  const { workforce_costs, external_costs } = store.getState().sig;
  // Répartition des charges'!D7
  const sig_consumption_of_raw_materials = getConsumptionOfRawMaterials(
    getConsumedMaterials(sig),
    sig.production_used
  );
  // Répartition des charges'!D11
  const sig_variable_costs_total =
    getConsumptionOfRawMaterials(
      getConsumedMaterials(sig),
      sig.production_used
    ) +
    getExternalCostTotal(workforce_costs) +
    getExternalCostTotal(external_costs) +
    getFinancialExpensesTaxes(
      sig.taxes_duties,
      sig.other_operating_expenses,
      sig.financial_result
    );

  const parsed_sig_consumption_of_raw_materials = getParsedFloatNumber(
    sig_consumption_of_raw_materials
  );

  const parsed_sig_variable_costs_total = getParsedFloatNumber(
    sig_variable_costs_total
  );

  return toFixedNumber(
    getParsedFloatNumber(
      ser_forecast_simulation_turnover_before_tax /
        ((parsed_sig_consumption_of_raw_materials /
          parsed_sig_variable_costs_total) *
          ser_forecast_variable_costs)
    ),
    3
  );
};

// F36
export const getCserK = (
  ser_forecast_indicators_coefficients,
  adjusted_imt_indicators_coefficients
) => {
  const parsed_ser_forecast_indicators_coefficients = getParsedFloatNumber(
    ser_forecast_indicators_coefficients
  );
  const parsed_adjusted_imt_indicators_coefficients = getParsedFloatNumber(
    adjusted_imt_indicators_coefficients
  );
  return toFixedNumber(
    getParsedFloatNumber(
      parsed_ser_forecast_indicators_coefficients /
        parsed_adjusted_imt_indicators_coefficients
    ),
    4
  );
};

// K6
export const getVariableCostsForSerCoeff = (
  ser_forecast_variable_costs,
  ser_forecast_indicators_coefficients,
  ser_coeff_indicators_coefficients
) => {
  const parsed_ser_forecast_variable_costs = getParsedFloatNumber(
    ser_forecast_variable_costs
  );
  const parsed_ser_forecast_indicators_coefficients = getParsedFloatNumber(
    ser_forecast_indicators_coefficients
  );
  const parsed_ser_coeff_indicators_coefficients = getParsedFloatNumber(
    ser_coeff_indicators_coefficients
  );
  return toFixedNumber(
    getParsedFloatNumber(
      (parsed_ser_forecast_variable_costs *
        parsed_ser_forecast_indicators_coefficients) /
        parsed_ser_coeff_indicators_coefficients
    ),
    2
  );
};
