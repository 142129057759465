import {
  FETCH_TOTAL_RAW_COST,
  FETCH_NUMBER_PRODUCED,
  FETCH_TOTAL_PRICE_PORTION,
  FETCH_TOTAL_ENERGY,
  FETCH_PRODUCTS,
  FETCH_PRODUCT,
  CLEAR_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_ERROR,
  FETCH_RAW_MATERIALS,
  FETCH_RAW_MATERIALS_ERROR,
  UPDATE_RAW_MATERIAL,
  POST_RAW_MATERIAL,
  POST_RAW_MATERIAL_ERROR,
  PUT_RAW_MATERIAL,
  PUT_RAW_MATERIAL_ERROR,
  DELETE_RAW_MATERIAL,
  DELETE_RAW_MATERIAL_ERROR,
} from "../types/productActionTypes";

const initialState = {
  products: [],
  product: {},
  raw_materials: [],
  loading: true,
  totalRawCost: null,
  loadingTotalRawCost: true,
  numberProduced: null,
  loadingNumberProduced: true,
  totalPricePortion: null,
  loadingTotalPricePortion: true,
  totalEnergy: null,
  loadingTotalEnergy: true,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TOTAL_RAW_COST:
      return {
        ...state,
        totalRawCost: action.payload,
        loadingTotalRawCost: false,
      };
    case FETCH_NUMBER_PRODUCED:
      return {
        ...state,
        numberProduced: action.payload,
        loadingNumberProduced: false,
      };
    case FETCH_TOTAL_PRICE_PORTION:
      return {
        ...state,
        totalPricePortion: action.payload,
        loadingTotalPricePortion: false,
      };
    case FETCH_TOTAL_ENERGY:
      return {
        ...state,
        totalEnergy: action.payload,
        loadingTotalEnergy: false,
      };
    case FETCH_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case FETCH_PRODUCT:
      return {
        ...state,
        product: action.payload,
        loading: false,
      };
    case CLEAR_PRODUCT:
      return {
        ...state,
        product: action.payload,
        loading: true,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case UPDATE_PRODUCT_ERROR:
      return {
        ...state,
      };
    case FETCH_RAW_MATERIALS:
      return {
        ...state,
        raw_materials: action.payload,
      };
    case FETCH_RAW_MATERIALS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case POST_RAW_MATERIAL:
      return {
        ...state,
        raw_materials: [...state.raw_materials, action.payload],
      };
    case POST_RAW_MATERIAL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_RAW_MATERIAL:
      return {
        ...state,
        raw_materials: [...action.payload],
      };
    case DELETE_RAW_MATERIAL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_RAW_MATERIAL:
      return {
        ...state,
        raw_materials: action.payload,
      };
    case PUT_RAW_MATERIAL:
      return {
        ...state,
        raw_materials: action.payload,
      };
    case PUT_RAW_MATERIAL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default productReducer;
