import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Helmet from "react-helmet";
import {
  Divider as MuiDivider,
  Grid,
  Typography,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";

import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import { OutdoorGrill } from "@material-ui/icons";
import TabPanel from "../../components/TabPanel/TabPanel";

import RawMaterials from "../../components/Products/RawMaterials";
import ProductionTechniques from "../../components/Products/ProductionTechniques";
import MultiplyingCoefficient from "../../components/Products/MultiplyingCoefficient";
import RawCost from "../../components/Products/RawCost";
import { fetchTechnicalSheet } from "../../redux/actions/technicalSheetActions";
import {
  fetchProduct,
  fetchRawMaterials,
} from "../../redux/actions/productActions";
import ProductDetail from "../../components/Products/ProductDetail";

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: "24px",
    marginBottom: "24px",
  },
  tabBar: {
    backgroundColor: "White",
    color: "Black",
  },
  tabContainer: {
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  productionTechniquesContainer: {
    "& .container-production-techniques": {
      backgroundColor: "white",
    },
  },
  customIcon: {
    position: "absolute",
    right: "0px",
    color: "white",
    "& svg": {
      height: "1.3em",
      width: "1.4em",
    },
    "& span": {
      fontSize: "14px",
    },
  },
}));

function ProductDetailView() {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const dispatch = useDispatch();
  const { productId } = useParams();
  const classes = useStyles();
  const loadProduct = async () => {
    await dispatch(fetchProduct(productId, user));
    await dispatch(fetchTechnicalSheet(productId, user.accessToken));
  };
  const [viewTechnicalProduction, setViewTechnicalProduction] = useState({
    sheetTable: 9,
    technicalProduction: 3,
  });
  const { register, errors, control, getValues, setValue } = useForm({
    name: "",
    seasonnality: "",
    number: "",
    line: [],
    "product-image": "",
  });

  const handleVisionTechnicalProduction = () => {
    if (viewTechnicalProduction.technicalProduction === 0) {
      setViewTechnicalProduction({
        sheetTable: 9,
        technicalProduction: 3,
      });
    } else {
      setViewTechnicalProduction({
        sheetTable: 12,
        technicalProduction: 0,
      });
    }
  };

  useEffect(() => {
    dispatch(fetchRawMaterials(productId, user.accessToken));
  }, [productId]);

  useEffect(() => {
    let isCancelled = false;
    const runAsync = async () => {
      try {
        if (!isCancelled) {
          loadProduct();
        }
      } catch (e) {
        if (!isCancelled) {
          throw e;
        }
      }
    };

    runAsync();

    return () => {
      isCancelled = true;
    };
  }, [productId]);

  const [valueTab, setValueTab] = useState(0);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <>
      <Helmet>
        <title>{t("Fiche Produit")}</title>
      </Helmet>

      <Typography variant="h3" gutterBottom display="inline">
        <Trans i18nKey="add product">Fiche produit</Trans>
      </Typography>

      <Divider my={6} mr={24} className={classes.margin} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ProductDetail productId={parseInt(productId, 10)} />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <AppBar position="static" className={classes.tabBar}>
            <Tabs value={valueTab} onChange={handleChange} aria-label="tabs">
              <Tab
                label={
                  <Trans i18nKey="ft de production">FT de Production</Trans>
                }
                id="tab-1"
                aria-controls="tabpanel-1"
              />
              <IconButton
                className={classes.customIcon}
                aria-label="Hide-production-technical"
                onClick={handleVisionTechnicalProduction}
              >
                <Trans i18nKey="ft de production">
                  Afficher/Fermer la fiche de production
                </Trans>
                <OutdoorGrill />
              </IconButton>
            </Tabs>
          </AppBar>
          <TabPanel value={valueTab} index={0} className={classes.tabContainer}>
            <Grid container>
              <Grid item xs={viewTechnicalProduction.sheetTable}>
                <Grid container spacing={10}>
                  <RawMaterials productId={productId} />
                </Grid>
                <Grid container spacing={10}>
                  <Grid item xs={6}>
                    <MultiplyingCoefficient
                      control={control}
                      errors={errors}
                      register={register}
                      getValues={getValues}
                      setValue={setValue}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RawCost
                      control={control}
                      errors={errors}
                      register={register}
                      getValues={getValues}
                      setValue={setValue}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {viewTechnicalProduction.technicalProduction !== 0 ? (
                <Grid
                  item
                  xs={viewTechnicalProduction.technicalProduction}
                  className={classes.productionTechniquesContainer}
                >
                  <ProductionTechniques productId={productId} />
                </Grid>
              ) : null}
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
}

export default ProductDetailView;
