export const getListOffersUuid = (Offers) => {
  const listOffers = [];

  Offers.forEach((element) => {
    listOffers.push(element.uuid);
  });

  return listOffers;
};

export const getListOffersName = (Offers) => {
  const listOffers = [];

  Offers.forEach((element) => {
    listOffers.push(element.name);
  });

  return listOffers;
};
