import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";
import { clearSnackbar } from "../../redux/actions/snackbarActions";

const SnackbarComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const messageSnackbar = useSelector(
    (state) => state.snackbar.messageSnackbar
  );
  const typeMessageSnackbar = useSelector(
    (state) => state.snackbar.typeMessageSnackbar
  );
  const isOpen = useSelector((state) => state.snackbar.isOpen);

  const handleClose = () => {
    dispatch(clearSnackbar());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert severity={typeMessageSnackbar}>{t(messageSnackbar)}</Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
