import axios from "./apiClient";
import { RANGE_PRODUCTS } from "../constants/urls";

export const addRangeProduct = async (accessToken, data) => {
  let result = null;
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_POPSER_API_URL}/product-of-range/`,
      data,
      headers,
    });
    if (res.status === 201) {
      result = res.data;
    }
  } catch (error) {
    result = error;
  }
  return result;
};

export const updateRangeProduct = async (accessToken, data) => {
  let result = null;
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  try {
    const res = await axios({
      method: "patch",
      url: `${RANGE_PRODUCTS}${data.uuid}/`,
      data,
      headers,
    });
    if (res.status === 200) {
      result = res.data;
    }
  } catch (error) {
    result = error;
  }
  return result;
};

export const getListRangeProduct = async (accessToken) => {
  let result = null;
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `/api/rangeProducts`;
  try {
    const res = await axios({
      method: "get",
      url,
      headers,
    });
    if (res.status === 200) {
      result = res.data;
    }
  } catch (error) {
    result = [];
  }
  return result;
};

export const fetchRangeProducts = async (rangeID, accessToken) => {
  let result = null;

  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_PRODUCTS}?range_id=${rangeID}`;
  try {
    const res = await axios({ method: "get", url, headers });
    if (res.status === 200) {
      result = { results: res.data, request: "success" };
    }
  } catch (e) {
    result = { results: e, request: "error" };
  }

  return result;
};

export const deleteRangeProduct = async (accessToken, id) => {
  let result = null;
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `/api/rangeProducts/${id}`;
  try {
    const res = await axios({
      method: "delete",
      url,
      headers,
    });
    if (res.status === 204) {
      result = res;
    }
  } catch (error) {
    result = [];
  }
  return result;
};
