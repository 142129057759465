import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";

import { useDispatch, useSelector } from "react-redux";

import { fetchProjectRanges } from "../../redux/actions/rangeActions";
import {
  registerOfferRanges,
  deleteOfferRanges,
  updateTotalOfferRanges,
} from "../../redux/actions/offerRangeActions";

import FooterAddLine from "../ElementsTable/FooterAddLine";
import PanelAutoComplete from "../ElementsTable/FieldPanelAutoCompleted";
import OfferRangeModel from "../../models/OfferRangeModel";
import OfferModel from "../../models/OfferModel";
import { addOfferRangeInteraction } from "../../services/offerRange/OfferRangeInteractionService";

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: "24px!important",
    marginBottom: "24px!important",
    marginRight: "0px!important",
  },
  nameColumn: {
    // width: "100px",
  },
  buttonAdd: {
    width: "100%",
    borderRadius: "0",
  },
  inputList: {
    "&.MuiInputBase-input::before": {
      borderBottom: "0px",
    },
  },
  HiddenIdShowColumn: {},
}));
const getTableTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          border: "1px solid #ddd",
        },
      },

      MuiFormControlLabel: {
        root: {
          "&:first-child": { display: "none" },
        },
      },
    },
  });

const rangeOfferDetails = ({ offer, offerRanges }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentProject = useSelector((state) => state.projects.currentProject);
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const ranges = useSelector((state) => state.ranges.ranges);
  const totalOfferRanges = useSelector(
    (state) => state.offerRanges.totalOfferRanges
  );

  useEffect(() => {
    dispatch(fetchProjectRanges(currentProject.id, user.accessToken));
  }, [currentProject]);

  const [listOfferRanges, setListOfferRanges] = useState([totalOfferRanges]);

  const calculTotalOfferRanges = async () => {
    const resultTotalOfferRanges = await addOfferRangeInteraction(offerRanges);
    dispatch(updateTotalOfferRanges(resultTotalOfferRanges));
  };

  useEffect(() => {
    setListOfferRanges([totalOfferRanges, ...offerRanges]);
    calculTotalOfferRanges();
  }, [offerRanges]);

  useEffect(() => {
    setListOfferRanges([totalOfferRanges, ...offerRanges]);
  }, [totalOfferRanges]);

  const onChangeRangeName = (event, range) => {
    if (range) {
      dispatch(
        registerOfferRanges(
          currentProject.id,
          range.id,
          offer.id,
          user.accessToken
        )
      );
    }
  };

  const columns = [
    {
      name: "uuid",
      options: {
        display: false,
      },
    },
    {
      name: "range",
      label: t("Offre"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <PanelAutoComplete
              nameColumn={`name${tableMeta.rowData[0]}`}
              value={value}
              onChangeField={onChangeRangeName}
              tableMeta={tableMeta}
              listObject={ranges}
              updateValue={updateValue}
            />
          );
        },
      },
    },
    {
      name: "range.period",
      label: t("Période"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "range.number_custom_by_year_number_check_total",
      label: t("Nombre de clients"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "range.number_day_by_year",
      label: t("Nombre de jour"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "range.sales_revenue_HT",
      label: t("CA HT €"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "range.material_consumption_HT",
      label: t("Consommation de matière"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "range.gross_margin",
      label: t("Marge Brute (MB) €"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "range.range_multiplier_coefficient",
      label: t("Coeffcient Multiplicateur (Coeff)"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "range.average_material_ratio",
      label: t("Ratio matière (%)"),
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const classes = useStyles();

  const deleteRows = async (RowsDeleted) => {
    const ids = RowsDeleted.data.map((d) => d.dataIndex);
    const idsToDeleted = ids.map((index) => listOfferRanges[index]);
    const listOfferRangeUuid = [];
    idsToDeleted.map(async (dataDelete) => {
      listOfferRanges.splice(listOfferRanges.indexOf(dataDelete), 1);
      setListOfferRanges(listOfferRanges.concat([]));
      listOfferRangeUuid.push(dataDelete.uuid);
    });
    dispatch(deleteOfferRanges(listOfferRangeUuid, user.accessToken));
  };

  const addLine = () => {
    setListOfferRanges(
      listOfferRanges.concat({
        offer: {},
        range: {
          id: 0,
          uuid: null,
          name: null,
          period: null,
          number_custom_by_year_number_check_total: null,
          number_day_by_year: null,
          sales_revenue_HT: null,
          material_consumption_HT: null,
          gross_margin: null,
          range_multiplier_coefficient: null,
          average_material_ratio: null,
        },
        project: {},
      })
    );
  };
  const options = {
    sort: false,
    filter: false,
    filterType: "checkbox",
    responsive: "simple",
    enableNestedDataAccess: ".",
    onRowsDelete: deleteRows,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
      <FooterAddLine
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        // eslint-disable-next-line no-shadow
        onChangePage={(_, page) => changePage(page)}
        onChangeRowsPerPage={(event) => changeRowsPerPage(event.target.value)}
        rowsPerPageOptions={[10, 15, 100]}
        addLine={addLine}
        title={t("ajouter une gamme d'offre")}
      />
    ),
  };

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <MuiThemeProvider theme={getTableTheme()}>
            <MUIDataTable
              className={classes.HiddenIdShowColumn}
              data={listOfferRanges}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </>
  );
};

rangeOfferDetails.propTypes = {
  offerRanges: PropTypes.objectOf(OfferRangeModel).isRequired,
  offer: PropTypes.objectOf(OfferModel).isRequired,
};

export default rangeOfferDetails;
