/* eslint-disable */

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import Helmet from "react-helmet";

import {
  Divider as MuiDivider,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";

import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../services/api/apiClient";

import { addProduct } from "../../services/api/productAPI";
import AddProductDetail from "../../components/AddProduct/AddProductDetail";
import { addProductToRange } from "../../redux/actions/rangeProductActions";
import { openSnackbar } from "../../redux/actions/snackbarActions";
import { useHistory } from "react-router-dom";;
import { clearProduct } from "../../redux/actions/productActions";
import { clearTechnicalSheet } from "../../redux/actions/technicalSheetActions";
import { copyProductToRangeProduct } from "../../services/range/RangeProductService";
const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: "24px",
    marginBottom: "24px",
  },
}));

function AddProduct() {
  const { t } = useTranslation();
  const classes = useStyles();
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const currentProject = useSelector((state) => state.projects.currentProject);
  const dispatch = useDispatch();
  const { handleSubmit, register, errors, control } = useForm();

  const [img, setImg] = useState("");
  const [listRange, setListRange] = useState([]);
  const history = useHistory();
  const loadListRange = async (idProject) => {
    let listRes = [];
    const headers = {
      "Content-Type": `application/json;`,
      Authorization: `Bearer ${user.accessToken}`,
    };
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_POPSER_API_URL}/range/?project_id=${idProject}`,
        headers,
      });
      if (res.status === 200) {
        listRes = res.data.results;
        setListRange(listRes);
      }
    } catch (error) {
      listRes = [];
    }
    return listRes;
  };

  useEffect(() => {
    loadListRange(currentProject.id);
  }, [currentProject]);

  const submitData = async (data) => {
    const product = await addProduct(user, data, img, currentProject);
    

    if (!("error" in product)){
      dispatch(openSnackbar("Produit ajouté avec succès", "Success"));
      if ("range" in data) {
        if (typeof data.range !== "undefined"){
          if (data.range.length > 0){
            const rangeProduct = copyProductToRangeProduct(product, {});
            await data.range.forEach(_range => dispatch(addProductToRange(currentProject.id, _range.id, product.id, user.accessToken, rangeProduct)));
          }
        }
      }
      await dispatch(clearProduct());
      await dispatch(clearTechnicalSheet());
      history.push(`/product/${product.id}`);
        
    }else {
      dispatch(openSnackbar("Une erreur est survenue. Merci de réessayer plus tard." , "Error"));
    }
    

  };
  return (
    <>
      <Helmet>
        <title>{t("Ajouter un Produit")}</title>
      </Helmet>

      <Typography variant="h3" gutterBottom display="inline">
        <Trans i18nKey="add product">Créer un produit</Trans>
      </Typography>

      <Divider my={6} mr={24} className={classes.margin} />
      <form onSubmit={handleSubmit(submitData)}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <AddProductDetail
              register={register}
              setImg={setImg}
              errors={errors}
              control={control}
              listRange={listRange}
            />
          </Grid>
        </Grid>
        <Button type="submit" fullWidth variant="contained" color="primary">
          <Trans i18nKey="add">Créer</Trans>
        </Button>
      </form>
    </>
  );
}

export default AddProduct;
