import {
  FETCH_PROJECT_OFFERS,
  FETCH_PROJECT_OFFERS_ERROR,
  FETCH_OFFER_BY_UUID,
  FETCH_RANGES_OF_AN_OFFER,
  DELETE_OFFERS,
  DELETE_OFFERS_ERROR,
  ADD_OFFER,
  FETCH_OFFER_ERROR,
  UPDATE_OFFER,
  UPDATE_OFFER_ERROR,
  CLEAN_OFFERS,
} from "../types/offerActionTypes";

const initialState = {
  offers: {
    error: null,
    results: [],
    links: {},
    page: null,
    pageSize: null,
    total: null,
  },
  currentOffer: {},
  currentOfferRanges: [],
  loading: false,
  error: {},
};
const deleteListOffers = (deletedOffersUUID, state) => {
  const stateOffers = state.offers.results;
  deletedOffersUUID.forEach((deletedOfferUUID) => {
    stateOffers.splice(
      stateOffers.findIndex((offer) => offer.uuid === deletedOfferUUID),
      1
    );
  });

  return {
    ...state.offers,
    results: stateOffers,
  };
};
const offerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROJECT_OFFERS:
      return {
        ...state,
        offers: action.payload,
        loading: false,
      };
    case FETCH_PROJECT_OFFERS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case FETCH_OFFER_BY_UUID:
      return {
        ...state,
        currentOffer: action.payload,
        loading: false,
      };
    case FETCH_RANGES_OF_AN_OFFER:
      return {
        ...state,
        currentOfferRanges: action.payload,
        loading: false,
      };
    case DELETE_OFFERS:
      return {
        ...state,
        offers: deleteListOffers(action.payload, state),
        loading: false,
      };
    case DELETE_OFFERS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ADD_OFFER:
      return {
        ...state,
        currentOffer: action.payload,
        loading: false,
      };
    case FETCH_OFFER_ERROR:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_OFFER:
      return {
        ...state,
        currentOffer: action.payload,
        loading: false,
      };
    case UPDATE_OFFER_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CLEAN_OFFERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default offerReducer;
