/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../redux/actions/snackbarActions";
import {interactionMultiplyingFactor, interactionSellingPriceTTC, interactionTvaRate} from "../../services/product/TechnicalSheetCalculInteractionService"
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";
import { updateTechnicalSheet } from "../../redux/actions/technicalSheetActions";

const useStyles = makeStyles(() => ({
  input: {
    "&::before": {},
    width: "70px",
  },
  root: {
    "& .MuiInput-underline:before": {
      borderBottom: "solid 1px", //
    },
  },
}));

 function MultiplyingCoefficient({
  control,
  errors,
  register,
  getValues,
  setValue,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const { productId } = useParams();
  const currentProject = useSelector((state) => state.projects.currentProject);
  const technicalSheet = useSelector((state) => state.technicalSheet.technicalSheet);
  const product = useSelector((state) => state.product.product);

  const { t } = useTranslation();

  const onChangeMultiplyingFactor = (event) => {
    const resultTechnicalSheet = interactionMultiplyingFactor(event.target.value)
    dispatch(updateTechnicalSheet(resultTechnicalSheet, user.accessToken, productId, currentProject.id))
    dispatch(openSnackbar("Fiche technique modifiée avec succès", "Success"));
    };

  const onChangeSellingPriceTTC = (event) => {
    const resultTechnicalSheet = interactionSellingPriceTTC(event.target.value)
    dispatch(updateTechnicalSheet(resultTechnicalSheet, user.accessToken, productId, currentProject.id))
    dispatch(openSnackbar("Fiche technique modifiée avec succès", "Success"));
    };

  const onChangeTvaRate = (event) => {
    const resultTechnicalSheet = interactionTvaRate(event.target.value)
    dispatch(updateTechnicalSheet(resultTechnicalSheet, user.accessToken, productId, currentProject.id))
    dispatch(openSnackbar("Fiche technique modifiée avec succès", "Success"));
    };
   
  return (
    <Card mb={6}>
      <CardContent>
        <Grid container spacing={2}>
          <Table>
            <TableBody className={classes.root}>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Trans i18nKey="multiplying coefficient">
                    Coefficient multiplicateur
                  </Trans>
                </TableCell>
                <TableCell>
                  <FieldWithSuffix
                    disabled={false}
                    name="coefficient"
                    control={control}
                    onChangeField={onChangeMultiplyingFactor}
                    errors={errors}
                    suffix={t("")}
                    register={register}
                    defaultValue={technicalSheet.multiplyingFactor.value}
                    value={technicalSheet.multiplyingFactor.value}

                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Trans i18nKey="selling price HT">Prix de vente HT</Trans>
                </TableCell>
                <TableCell>
                  <FieldWithSuffix
                    disabled
                    name="selling_price_ht"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    register={register}
                    value={technicalSheet.sellingPriceHT.value}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Trans i18nKey="Rate TVA">Taux de TVA</Trans>
                </TableCell>
                <TableCell>
                  <FieldWithSuffix
                    disabled={false}
                    name="tva_rate"
                    control={control}
                    onChangeField={onChangeTvaRate}
                    errors={errors}
                    suffix={t("%")}
                    register={register}
                    defaultValue={technicalSheet.tvaRate.value}
                    value={technicalSheet.tvaRate.value}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Trans i18nKey="TVA">TVA</Trans>
                </TableCell>
                <TableCell>
                  <FieldWithSuffix
                    disabled
                    name="tva"
                    control={control}
                    errors={errors}
                    suffix={t("")}
                    register={register}
                    value={technicalSheet.tva.value}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Trans i18nKey="selling price TTC">Prix de vente TTC</Trans>
                </TableCell>
                <TableCell>
                  <FieldWithSuffix
                    disabled={false}
                    name="ttc_price"
                    control={control}
                    onChangeField={onChangeSellingPriceTTC}
                    errors={errors}
                    suffix={t("€")}
                    register={register}
                    defaultValue={technicalSheet.sellingPriceTTC.value}
                    value={technicalSheet.sellingPriceTTC.value}

                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </CardContent>
    </Card>
  );
}

MultiplyingCoefficient.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default MultiplyingCoefficient;
