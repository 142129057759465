export const FETCH_PROJECT_OFFERS = "FETCH_PROJECT_OFFERS";
export const FETCH_PROJECT_OFFERS_ERROR = "FETCH_PROJECT_OFFERS_ERROR";
export const FETCH_OFFER_BY_UUID = "FETCH_OFFER_BY_UUID";
export const FETCH_RANGES_OF_AN_OFFER = "FETCH_RANGES_OF_AN_OFFER";
export const DELETE_OFFERS = "DELETE_OFFERS";
export const DELETE_OFFERS_ERROR = "DELETE_OFFERS_ERROR";
export const ADD_OFFER = "ADD_OFFER";
export const FETCH_OFFER_ERROR = "FETCH_OFFER_ERROR";
export const UPDATE_OFFER = "UPDATE_OFFER";
export const UPDATE_OFFER_ERROR = "UPDATE_OFFER_ERROR";

export const CLEAN_OFFERS = "CLEAN_OFFERS";
