import { putCser } from "../../redux/actions/cserActions";
import { getParsedFloatNumber } from "../range/RangeService";
import {
  getAveragePriceForSerGrowthTurnoverWithAveragePriceIncrease,
  getLoanRepaymentPercentageForAdjustedImt,
  getNetSurplusForAdjustedImt,
  getNetSurplusPercentageForAdjustedImt,
  getNewVariableCostsRateFromPercentageReductionMaterialConsumption,
  getSimulationCashfFlowForSerGrowthTurnover,
  getSimulationMarginOnVariableCostsForSerForecast,
  getSimulationPercentageOfVariableLoadRateForSerForecast,
  getTurnoverBeforeTaxPercentageForSerForecast,
} from "./cserService";
import { store } from "../../redux/store";
import { UPDATE_COMPUTED_CSER } from "../../redux/types/cserActionTypes";
import { openSnackbar } from "../../redux/actions/snackbarActions";

const showSuccessMessage = () =>
  store.dispatch(openSnackbar("CSER Sauvegardé"));

export const handleChangeLoanRepaymentProfitabilityThreshold = async (e) => {
  const { profitability_threshold } = store.getState().cser.computed_cser;
  const { cser_data } = store.getState().cser;
  const { auth } = store.getState().firebase;
  const user = auth.stsTokenManager;
  const simulation_loan_repayments = getParsedFloatNumber(e.target.value);
  const simulation_net_surplus = getNetSurplusForAdjustedImt(
    profitability_threshold.simulation_cash_flow,
    getParsedFloatNumber(e.target.value)
  );
  await store.dispatch({
    type: UPDATE_COMPUTED_CSER,
    payload: {
      profitability_threshold: {
        ...profitability_threshold,
        simulation_loan_repayments,
        simulation_net_surplus,
      },
    },
  });
  await store.dispatch(
    putCser(
      {
        ...cser_data,
        simulation_profitability_threshold_loan_repayments: simulation_loan_repayments,
      },
      user.accessToken
    )
  );
  showSuccessMessage();
};

export const handleChangeLoanRepaymentAdjustedImt = async (e) => {
  const { adjusted_imt } = store.getState().cser.computed_cser;
  const { cser_data } = store.getState().cser;
  const { auth } = store.getState().firebase;
  const user = auth.stsTokenManager;

  const simulation_loan_repayments = getParsedFloatNumber(e.target.value);
  const simulation_net_surplus = getNetSurplusForAdjustedImt(
    adjusted_imt.simulation_cash_flow,
    getParsedFloatNumber(e.target.value)
  );
  await store.dispatch({
    type: UPDATE_COMPUTED_CSER,
    payload: {
      adjusted_imt: {
        ...adjusted_imt,
        simulation_loan_repayments,
        simulation_loan_repayments_percentage: getLoanRepaymentPercentageForAdjustedImt(
          simulation_loan_repayments,
          adjusted_imt.simulation_turnover_before_tax_percentage,
          adjusted_imt.simulation_turnover_before_tax
        ),
        simulation_net_surplus,
        simulation_net_surplus_percentage: getNetSurplusPercentageForAdjustedImt(
          simulation_net_surplus,
          adjusted_imt.simulation_turnover_before_tax_percentage,
          adjusted_imt.simulation_turnover_before_tax
        ),
      },
    },
  });
  await store.dispatch(
    putCser(
      {
        ...cser_data,
        simulation_adjusted_imt_loan_repayments: simulation_loan_repayments,
      },
      user.accessToken
    )
  );
  showSuccessMessage();
};

export const handleChangeLoanRepaymentsForSerGrowthTurnover = async (e) => {
  const { ser_growth_turnover } = store.getState().cser.computed_cser;
  const { cser_data } = store.getState().cser;
  const { auth } = store.getState().firebase;
  const user = auth.stsTokenManager;
  const simulation_loan_repayments = getParsedFloatNumber(e.target.value);
  const simulation_cash_flow = getSimulationCashfFlowForSerGrowthTurnover(
    simulation_loan_repayments,
    ser_growth_turnover.simulation_net_surplus
  );
  await store.dispatch({
    type: UPDATE_COMPUTED_CSER,
    payload: {
      ser_growth_turnover: {
        ...ser_growth_turnover,
        simulation_loan_repayments,
        simulation_cash_flow,
      },
    },
  });
  await store.dispatch(
    putCser(
      {
        ...cser_data,
        simulation_ser_growth_turnover_loan_repayments: simulation_loan_repayments,
      },
      user.accessToken
    )
  );
  showSuccessMessage();
};

export const handleChangeNetSurplusForSerGrowthTurnover = async (e) => {
  const { ser_growth_turnover } = store.getState().cser.computed_cser;
  const { cser_data } = store.getState().cser;
  const { auth } = store.getState().firebase;
  const user = auth.stsTokenManager;
  const simulation_net_surplus = getParsedFloatNumber(e.target.value);
  const simulation_cash_flow = getSimulationCashfFlowForSerGrowthTurnover(
    ser_growth_turnover.simulation_loan_repayments,
    simulation_net_surplus
  );
  await store.dispatch({
    type: UPDATE_COMPUTED_CSER,
    payload: {
      ser_growth_turnover: {
        ...ser_growth_turnover,
        simulation_net_surplus,
        simulation_cash_flow,
      },
    },
  });
  await store.dispatch(
    putCser(
      {
        ...cser_data,
        simulation_ser_growth_turnover_net_surplus: simulation_net_surplus,
      },
      user.accessToken
    )
  );
  showSuccessMessage();
};

// G18
export const handleChangeAveragePriceIncreasedForSerGrowTurnover = async (
  average_price_increased_by
) => {
  const parsed_average_price_increased_by = getParsedFloatNumber(
    average_price_increased_by.target.value
  );
  const { ser_growth_turnover } = store.getState().cser.computed_cser;
  const { adjusted_imt } = store.getState().cser.computed_cser;
  const { cser_data } = store.getState().cser;
  const { auth } = store.getState().firebase;
  const user = auth.stsTokenManager;

  const indicators_average_price_in_euros = getAveragePriceForSerGrowthTurnoverWithAveragePriceIncrease(
    parsed_average_price_increased_by,
    adjusted_imt.indicators_average_price_in_euros
  );
  await store.dispatch({
    type: UPDATE_COMPUTED_CSER,
    payload: {
      ser_growth_turnover: {
        ...ser_growth_turnover,
        average_price_increase: {
          ...ser_growth_turnover.average_price_increase,
          price_increase: parsed_average_price_increased_by,
          indicators_average_price_in_euros,
        },
      },
    },
  });
  await store.dispatch(
    putCser(
      {
        ...cser_data,
        indicators_ser_growth_turnover_average_price_increase: parsed_average_price_increased_by,
      },
      user.accessToken
    )
  );
  showSuccessMessage();
};

// H5
export const handleChangePercentageOfChangeInRevenues = async (
  percentage_of_change_in_revenues
) => {
  const parsed_percentage_of_change_in_revenues = getParsedFloatNumber(
    percentage_of_change_in_revenues.target.value
  );
  const { ser_forecast } = store.getState().cser.computed_cser;
  const { adjusted_imt } = store.getState().cser.computed_cser;
  const { cser_data } = store.getState().cser;
  const { auth } = store.getState().firebase;
  const user = auth.stsTokenManager;

  const ser_forecast_simulation_simulation_turnover_before_tax = getTurnoverBeforeTaxPercentageForSerForecast(
    adjusted_imt.simulation_turnover_before_tax,
    parsed_percentage_of_change_in_revenues
  );
  const simulation_margin_on_variable_costs = getSimulationMarginOnVariableCostsForSerForecast(
    ser_forecast.simulation_margin_on_variable_costs_percentage,
    ser_forecast_simulation_simulation_turnover_before_tax
  );
  await store.dispatch({
    type: UPDATE_COMPUTED_CSER,
    payload: {
      ser_forecast: {
        ...ser_forecast,
        simulation_turnover_before_tax: ser_forecast_simulation_simulation_turnover_before_tax,
        simulation_percentage_of_change_in_revenues: parsed_percentage_of_change_in_revenues,
        simulation_margin_on_variable_costs,
      },
    },
  });
  await store.dispatch(
    putCser(
      {
        ...cser_data,
        simulation_ser_forecast_percentage_of_change_in_revenues: parsed_percentage_of_change_in_revenues,
      },
      user.accessToken
    )
  );
  showSuccessMessage();
};

// H6
export const handleChangeSerForecastMarginOnVariableCosts = async (
  simulation_ser_forecast_margin_on_variable_costs
) => {
  const parsed_simulation_ser_forecast_margin_on_variable_costs = getParsedFloatNumber(
    simulation_ser_forecast_margin_on_variable_costs.target.value
  );
  const { ser_forecast } = store.getState().cser.computed_cser;
  const { cser_data } = store.getState().cser;
  const { auth } = store.getState().firebase;
  const user = auth.stsTokenManager;

  const simulation_margin_on_variable_costs = getSimulationMarginOnVariableCostsForSerForecast(
    parsed_simulation_ser_forecast_margin_on_variable_costs,
    ser_forecast.simulation_turnover_before_tax
  );

  const simulation_percentage_of_variable_load_rate = getSimulationPercentageOfVariableLoadRateForSerForecast(
    parsed_simulation_ser_forecast_margin_on_variable_costs
  );

  await store.dispatch({
    type: UPDATE_COMPUTED_CSER,
    payload: {
      ser_forecast: {
        ...ser_forecast,
        simulation_margin_on_variable_costs_percentage: parsed_simulation_ser_forecast_margin_on_variable_costs,
        simulation_margin_on_variable_costs,
        simulation_percentage_of_variable_load_rate,
      },
    },
  });
  await store.dispatch(
    putCser(
      {
        ...cser_data,
        simulation_ser_forecast_margin_on_variable_costs: parsed_simulation_ser_forecast_margin_on_variable_costs,
      },
      user.accessToken
    )
  );
  showSuccessMessage();
};

// H8
export const handleChangeFixedCosts = async (
  simulation_ser_forecast_fixed_costs
) => {
  const parsed_simulation_ser_forecast_fixed_costs = getParsedFloatNumber(
    simulation_ser_forecast_fixed_costs.target.value
  );
  const { ser_forecast } = store.getState().cser.computed_cser;
  const { cser_data } = store.getState().cser;
  const { auth } = store.getState().firebase;
  const user = auth.stsTokenManager;

  await store.dispatch({
    type: UPDATE_COMPUTED_CSER,
    payload: {
      ser_forecast: {
        ...ser_forecast,
        simulation_fixed_costs_variation_in_euros: parsed_simulation_ser_forecast_fixed_costs,
        simulation_fixed_costs: parsed_simulation_ser_forecast_fixed_costs,
      },
    },
  });
  await store.dispatch(
    putCser(
      {
        ...cser_data,
        simulation_ser_forecast_fixed_costs: parsed_simulation_ser_forecast_fixed_costs,
      },
      user.accessToken
    )
  );
  showSuccessMessage();
};

export const handleChangePercentageOfVariableLoadRateForSerForecast = async (
  simulation_percentge_of_variable_load_rate
) => {
  const { ser_forecast } = store.getState().cser.computed_cser;
  const { cser_data } = store.getState().cser;
  const { auth } = store.getState().firebase;
  const user = auth.stsTokenManager;
  const parsed_simulation_percentge_of_variable_load_rate = getParsedFloatNumber(
    simulation_percentge_of_variable_load_rate.target.value
  );
  await store.dispatch({
    type: UPDATE_COMPUTED_CSER,
    payload: {
      ser_forecast: {
        ...ser_forecast,
        simulation_percentge_of_variable_load_rate: parsed_simulation_percentge_of_variable_load_rate,
      },
    },
  });
  await store.dispatch(
    putCser(
      {
        ...cser_data,
        simulation_ser_forecast_percentage_of_variable_load_rate: parsed_simulation_percentge_of_variable_load_rate,
      },
      user.accessToken
    )
  );
  showSuccessMessage();
};

export const handleChangePercentageReductionMaterialConsumption = async (
  percentageReductionMaterialConsumption
) => {
  const parsed_percentageReductionMaterialConsumption = getParsedFloatNumber(
    percentageReductionMaterialConsumption.target.value
  );
  const new_variable_costs_rate = getNewVariableCostsRateFromPercentageReductionMaterialConsumption(
    parsed_percentageReductionMaterialConsumption
  );

  const { ser_forecast } = store.getState().cser.computed_cser;
  const { cser_data } = store.getState().cser;
  const { auth } = store.getState().firebase;
  const user = auth.stsTokenManager;

  await store.dispatch({
    type: UPDATE_COMPUTED_CSER,
    payload: {
      ser_forecast: {
        ...ser_forecast,
        percentage_reduction_material_consumption: parsed_percentageReductionMaterialConsumption,
        new_variable_costs_rate,
      },
    },
  });
  await store.dispatch(
    putCser(
      {
        ...cser_data,
        percentage_reduction_material_consumption: parsed_percentageReductionMaterialConsumption,
      },
      user.accessToken
    )
  );
  showSuccessMessage();
};

export const copyNewVariableCostRateToSerForecastSimulationPercentageOfVariableLoadRate = (
  new_variable_costs_rate
) => {
  // The handleChangePercentageOfVariableLoadRateForSerForecast methods expects a form input value which is formed like this. Creating the object here is a shortcut to use it this way.
  return handleChangePercentageOfVariableLoadRateForSerForecast({
    target: { value: new_variable_costs_rate },
  });
};

// I13
export const handleChangeLoanRepaymentSerForecast = async (e) => {
  const { ser_forecast } = store.getState().cser.computed_cser;
  const { cser_data } = store.getState().cser;
  const { auth } = store.getState().firebase;
  const user = auth.stsTokenManager;
  const simulation_loan_repayments = getParsedFloatNumber(e.target.value);
  const simulation_net_surplus = getNetSurplusForAdjustedImt(
    ser_forecast.simulation_cash_flow,
    getParsedFloatNumber(e.target.value)
  );
  await store.dispatch({
    type: UPDATE_COMPUTED_CSER,
    payload: {
      ser_forecast: {
        ...ser_forecast,
        simulation_loan_repayments,
        simulation_net_surplus,
      },
    },
  });
  await store.dispatch(
    putCser(
      {
        ...cser_data,
        simulation_ser_forecast_loan_repayments: simulation_loan_repayments,
      },
      user.accessToken
    )
  );
  showSuccessMessage();
};

// J18
export const handleChangeAveragePriceIncreasedForSerForecast = async (
  average_price_increased_by
) => {
  const parsed_average_price_increased_by = getParsedFloatNumber(
    average_price_increased_by.target.value
  );
  const { ser_forecast } = store.getState().cser.computed_cser;
  const { adjusted_imt } = store.getState().cser.computed_cser;
  const { cser_data } = store.getState().cser;
  const { auth } = store.getState().firebase;
  const user = auth.stsTokenManager;

  const indicators_average_price_in_euros = getAveragePriceForSerGrowthTurnoverWithAveragePriceIncrease(
    parsed_average_price_increased_by,
    adjusted_imt.indicators_average_price_in_euros
  );
  await store.dispatch({
    type: UPDATE_COMPUTED_CSER,
    payload: {
      ser_forecast: {
        ...ser_forecast,
        average_price_increase: {
          ...ser_forecast.average_price_increase,
          price_increase: parsed_average_price_increased_by,
          indicators_average_price_in_euros,
        },
      },
    },
  });
  await store.dispatch(
    putCser(
      {
        ...cser_data,
        indicators_ser_forecast_average_price_increase: parsed_average_price_increased_by,
      },
      user.accessToken
    )
  );
  showSuccessMessage();
};

// K30
export const handleChangeCoefficientSerCoeff = async (
  indicators_coefficients_ser_coeff
) => {
  const parsed_indicators_coefficients_ser_coeff = getParsedFloatNumber(
    indicators_coefficients_ser_coeff.target.value
  );
  const { ser_coeff } = store.getState().cser.computed_cser;
  const { cser_data } = store.getState().cser;
  const { auth } = store.getState().firebase;
  const user = auth.stsTokenManager;

  await store.dispatch({
    type: UPDATE_COMPUTED_CSER,
    payload: {
      ser_coeff: {
        ...ser_coeff,
        indicators_coefficients: parsed_indicators_coefficients_ser_coeff,
      },
    },
  });
  await store.dispatch(
    putCser(
      {
        ...cser_data,
        indicators_ser_coeff_coefficient: parsed_indicators_coefficients_ser_coeff,
      },
      user.accessToken
    )
  );
  showSuccessMessage();
};

// K13

export const handleChangeLoanRepaymentSerCoeff = async (e) => {
  const { ser_coeff } = store.getState().cser.computed_cser;
  const { cser_data } = store.getState().cser;
  const { auth } = store.getState().firebase;
  const user = auth.stsTokenManager;

  const simulation_loan_repayments = getParsedFloatNumber(e.target.value);
  const simulation_net_surplus = getNetSurplusForAdjustedImt(
    ser_coeff.simulation_cash_flow,
    getParsedFloatNumber(e.target.value)
  );
  await store.dispatch({
    type: UPDATE_COMPUTED_CSER,
    payload: {
      ser_coeff: {
        ...ser_coeff,
        simulation_loan_repayments,
        simulation_net_surplus,
      },
    },
  });
  await store.dispatch(
    putCser(
      {
        ...cser_data,
        simulation_ser_coeff_loan_repayments: simulation_loan_repayments,
      },
      user.accessToken
    )
  );
  showSuccessMessage();
};
