// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";

import { Typography, makeStyles, TableCell, TableRow } from "@material-ui/core";
import { Trans } from "react-i18next";
// import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { useSelector } from "react-redux";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";
import { handleChangeCoefficientSerCoeff } from "../../services/cser/cserInteractions";

const useStyles = makeStyles(() => ({
  titleHeaderCell: {
    fontSize: "0.875rem",
  },
  width100: {
    width: "100%",
  },
  borderRight: {
    borderRight: "solid 2px #1876d2",
  },
  borderArrow: {
    borderRight: "solid 5px #1876d2",
  },
  selectArrow: {
    border: "solid 5px #1876d2",
    "& input": { color: "white" },
    backgroundColor: "#1876d2",
  },
}));

const YearlyIndicator = () => {
  const { register, control, errors } = useForm();

  const classes = useStyles();
  const adjusted_imt = useSelector(
    (state) => state.cser.computed_cser.adjusted_imt
  );
  const profitability_threshold = useSelector(
    (state) => state.cser.computed_cser.profitability_threshold
  );
  const ser_growth_turnover = useSelector(
    (state) => state.cser.computed_cser.ser_growth_turnover
  );

  const ser_forecast = useSelector(
    (state) => state.cser.computed_cser.ser_forecast
  );

  const ser_coeff = useSelector((state) => state.cser.computed_cser.ser_coeff);

  return (
    <>
      <TableRow key="yearly_indicators">
        <TableCell colSpan={11} component="th" scope="row" align="center">
          <Typography
            variant="h6"
            className={classes.titleHeaderCell}
            gutterBottom
            display="inline"
          >
            <Trans i18nKey="Indicateurs Annuels">Indicateurs Annuels</Trans>
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow key="coefficients">
        <TableCell component="th" scope="row" align="center">
          <Typography variant="h9" gutterBottom display="inline">
            <Trans i18nKey="Coefficients">Coefficients</Trans>
          </Typography>
        </TableCell>
        <TableCell align="left" colSpan={2} className={classes.selectArrow}>
          <FieldWithSuffix
            disabled
            name="coefficients_adjsuted_imt"
            control={control}
            errors={errors}
            suffix=""
            value={adjusted_imt.indicators_coefficients}
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderRight}>
          <FieldWithSuffix
            disabled
            name="coefficients_profitability_threshold"
            control={control}
            errors={errors}
            suffix=""
            value={profitability_threshold.indicators_coefficients}
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderRight} colSpan={3}>
          <FieldWithSuffix
            disabled
            name="coefficients_ser_growth_turnover_average_price_increase"
            control={control}
            errors={errors}
            suffix=""
            value={ser_growth_turnover.indicators_coefficients}
            register={register}
          />
        </TableCell>
        <TableCell align="center" colSpan={3} className={classes.selectArrow}>
          <FieldWithSuffix
            disabled
            name="coefficients_ser_forecast"
            control={control}
            errors={errors}
            suffix=""
            value={ser_forecast.indicators_coefficients}
            register={register}
          />
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            isEdit
            name="coefficients_ser_coeff"
            control={control}
            classesProps={classes.width100}
            errors={errors}
            suffix=""
            value={ser_coeff.indicators_coefficients}
            onChangeField={handleChangeCoefficientSerCoeff}
            register={register}
          />
        </TableCell>
      </TableRow>
      <TableRow key="ratio_of_materials">
        <TableCell
          component="th"
          scope="row"
          align="center"
          className={classes.borderArrow}
        >
          <Typography variant="h9" gutterBottom display="inline">
            <Trans i18nKey="Ratio matières">Ratio matières</Trans>
          </Typography>
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="ratio_of_materials"
            control={control}
            errors={errors}
            suffix="%"
            value={adjusted_imt.indicators_ratio_of_materials}
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderRight} />
        <TableCell align="center" className={classes.borderRight}>
          <FieldWithSuffix
            disabled
            name="ratio_of_materials_profitability_threshold"
            control={control}
            errors={errors}
            suffix="%"
            value={profitability_threshold.indicators_ratio_of_materials}
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderRight} colSpan={3}>
          <FieldWithSuffix
            disabled
            name="ratio_of_materials_ser_growth_turnover_average_price_increase"
            control={control}
            errors={errors}
            suffix="%"
            value={ser_growth_turnover.indicators_ratio_of_materials}
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderArrow} colSpan={3}>
          <FieldWithSuffix
            disabled
            name="ratio_of_materials_ser_forecast"
            control={control}
            errors={errors}
            suffix="%"
            value={ser_forecast.indicators_ratio_of_materials}
            register={register}
          />
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="ratio_of_materials_ser_coeff_average_price_increase"
            control={control}
            errors={errors}
            suffix="%"
            value={ser_coeff.indicators_ratio_of_materials}
            register={register}
          />
        </TableCell>
      </TableRow>
      <TableRow key="gross_margin_in_euros_excluding_consumed_production">
        <TableCell
          component="th"
          scope="row"
          align="center"
          className={classes.borderArrow}
        >
          <Typography variant="h9" gutterBottom display="inline">
            <Trans i18nKey="Marge brute (hors production consommée)</Trans>">
              Marge brute (hors production consommée)
            </Trans>
          </Typography>
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="gross_margin_in_euros_excluding_consumed_production"
            control={control}
            errors={errors}
            suffix="€"
            value={
              adjusted_imt.indicators_gross_margin_in_euros_excluding_consumed_production
            }
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderRight} />
        <TableCell align="center" className={classes.borderRight}>
          <FieldWithSuffix
            disabled
            name="gross_margin_in_euros_excluding_consumed_production_profitability_threshold"
            control={control}
            errors={errors}
            suffix="€"
            value={
              profitability_threshold.indicators_gross_margin_in_euros_excluding_consumed_production
            }
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderRight} colSpan={3}>
          <FieldWithSuffix
            disabled
            name="gross_margin_in_euros_excluding_consumed_production_ser_growth_turnover_average_price_increase"
            control={control}
            errors={errors}
            suffix="€"
            value={
              ser_growth_turnover.indicators_gross_margin_in_euros_excluding_consumed_production
            }
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderArrow} colSpan={3}>
          <FieldWithSuffix
            disabled
            name="gross_margin_in_euros_excluding_consumed_production_ser_forecast_average_price_increase"
            control={control}
            errors={errors}
            suffix="€"
            value={
              ser_forecast.indicators_gross_margin_in_euros_excluding_consumed_production
            }
            register={register}
          />
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="gross_margin_in_euros_excluding_consumed_production_ser_coeff_average_price_increase"
            control={control}
            classesProps={classes.width100}
            errors={errors}
            suffix="€"
            value={
              ser_coeff.indicators_gross_margin_in_euros_excluding_consumed_production
            }
            register={register}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default YearlyIndicator;
