import {
  FETCH_RANGE_PRODUCTS,
  DELETE_RANGE_PRODUCT_SUCCESS,
  ADD_PRODUCT_TO_RANGE_SUCCESS,
  FETCH_RANGES_OF_A_PRODUCT_SUCCESS,
  UPDATE_RANGE_PRODUCT_SUCCESS,
  UPDATE_RANGE_PRODUCTS_SUCCESS,
  SWITCH_POPSER,
  CLEAN_RANGE_PRODUCTS,
} from "../types/rangeProductsActionTypes";

const initialState = {
  rangeProducts: [],
  loading: true,
  popser: { isPopser: true, name: "Classement du Popser" },
};

const replaceRangeProduct = (updatedRangeProduct, state) => {
  const newRangeProductList = state.rangeProducts.filter(
    (rangeProduct) => rangeProduct.uuid !== updatedRangeProduct.uuid
  );
  newRangeProductList.push(updatedRangeProduct);
  return newRangeProductList;
};

const rangeProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RANGE_PRODUCTS:
      return {
        ...state,
        rangeProducts: action.payload,
        loading: false,
      };
    case FETCH_RANGES_OF_A_PRODUCT_SUCCESS:
      return {
        ...state,
        rangeProducts: action.payload,
        loading: false,
      };
    case DELETE_RANGE_PRODUCT_SUCCESS:
      return {
        ...state,
        rangeProducts: state.rangeProducts.filter(
          (rangeProduct) => rangeProduct.uuid !== action.payload
        ),
        loading: false,
      };
    case ADD_PRODUCT_TO_RANGE_SUCCESS:
      return {
        ...state,
        rangeProducts: [...state.rangeProducts, action.payload],
        loading: false,
      };
    case UPDATE_RANGE_PRODUCT_SUCCESS:
      return {
        ...state,
        rangeProducts: replaceRangeProduct(action.payload, state),
      };
    case UPDATE_RANGE_PRODUCTS_SUCCESS:
      return {
        ...state,
        rangeProducts: action.payload,
      };
    case SWITCH_POPSER:
      return {
        ...state,
        popser: action.payload,
      };
    case CLEAN_RANGE_PRODUCTS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default rangeProductsReducer;
