import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import rangeProductsReducer from "./rangeProductReducer";
import projectReducer from "./projectReducer";
import snackbarReducer from "./snackbarReducer";
import productReducer from "./productReducer";
import rangeReducer from "./rangeReducer";
import technicalSheetReducer from "./technicalSheetReducer";
import offerReducer from "./offerReducer";
import offerRangesReducer from "./offerRangeReducer";
import sigReducer from "./sigReducer";
import userReducer from "./userReducer";
import cserReducer from "./cserReducer";
import helperTextReducer from "./helperTextReducer";
import managerFeedbackReducer from "./managerFeedbackReducer";

export default combineReducers({
  product: productReducer,
  projects: projectReducer,
  ranges: rangeReducer,
  rangeProducts: rangeProductsReducer,
  technicalSheet: technicalSheetReducer,
  offer: offerReducer,
  offerRanges: offerRangesReducer,
  sig: sigReducer,
  snackbar: snackbarReducer,
  firebase: firebaseReducer,
  user: userReducer,
  cser: cserReducer,
  helperText: helperTextReducer,
  managerFeedback: managerFeedbackReducer,
});
