/* eslint-disable react/no-unescaped-entities */
import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import {
  Card as MuiCard,
  CardContent,
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updateUserAction } from "../../redux/actions/userActions";
import { editingProjectIntermediateManagementThreshold } from "../../redux/actions/sigActions";
import {
  getSigWithPercentagetByValue,
  getSigWithPercentagePartFixeByDevisePartFixe,
  getSigWithDevisePartFixeByPercentagePartFixe,
} from "../../services/sig/SigMethodService";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles({
  margin: {
    marginTop: "12px",
  },
  input: {
    width: "90px!important",
  },
  gridInput: {
    borderLeft: "solid 1px",
    paddingLeft: "30px",
    borderBottom: "solid 1px",
  },
  gridTitle: {
    borderBottom: "solid 1px",
    paddingBottom: "11px",
    paddingTop: "21px",
  },
  tableEqualWidth: {
    "& th": { width: "20%!important" },
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  card: {
    paddingBottom: "0px!important",
  },
  gridColor: {
    backgroundColor: "#1876d121",
  },
});
const Taxes = () => {
  const classes = useStyles();
  const { register, control, errors } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sig = useSelector((state) => state.sig.sig);
  const handleUserUpdateValue = (ProjectIntermediateManagementThreshold) => {
    dispatch(
      editingProjectIntermediateManagementThreshold(
        getSigWithPercentagetByValue(ProjectIntermediateManagementThreshold)
      )
    );
    dispatch(updateUserAction(true));
  };
  const handleUserUpdateDevisePartFixe = (
    ProjectIntermediateManagementThreshold
  ) => {
    dispatch(
      editingProjectIntermediateManagementThreshold(
        getSigWithPercentagePartFixeByDevisePartFixe(
          ProjectIntermediateManagementThreshold
        )
      )
    );
    dispatch(updateUserAction(true));
  };
  const handleUserUpdatePercentagePartFixe = (
    ProjectIntermediateManagementThreshold
  ) => {
    dispatch(
      editingProjectIntermediateManagementThreshold(
        getSigWithDevisePartFixeByPercentagePartFixe(
          ProjectIntermediateManagementThreshold
        )
      )
    );
    dispatch(updateUserAction(true));
  };
  return (
    <>
      <Card mb={12}>
        <CardContent className={classes.card}>
          <TableContainer component={Paper}>
            <Table
              className={[classes.table, classes.tableEqualWidth]}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="right">
                    {" "}
                    <Trans i18nKey="Amount money">Montant en (en €) </Trans>
                  </TableCell>
                  <TableCell align="right">%</TableCell>
                  <TableCell align="right">
                    <Trans>Part fixe (en €)</Trans>
                  </TableCell>
                  <TableCell align="right">
                    <Trans>Part fixe (en %)</Trans>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="taxes_duties">
                  <TableCell component="th" scope="row">
                    <Typography variant="h9" gutterBottom display="inline">
                      <Typography variant="h5" gutterBottom display="inline">
                        <Trans i18nKey="Taxes and fees">Impots et taxes</Trans>
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      isEdit
                      disabled={false}
                      name="taxes_duties"
                      control={control}
                      errors={errors}
                      suffix={t("€")}
                      value={sig.taxes_duties}
                      onChangeField={
                        (e) =>
                          handleUserUpdateValue({
                            ...sig,
                            taxes_duties: e.target.value.slice(
                              0,
                              e.target.value.length - 1
                            ),
                          })
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      defaultValue={0}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      disabled
                      name="taxes_duties_percentage"
                      control={control}
                      errors={errors}
                      suffix={t("%")}
                      value={sig.taxes_duties_percentage}
                      defaultValue={0}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      isEdit
                      disabled={false}
                      name="taxes_duties_devise_part_fixe"
                      control={control}
                      errors={errors}
                      suffix={t("€")}
                      value={sig.taxes_duties_devise_part_fixe}
                      onChangeField={
                        (e) =>
                          handleUserUpdateDevisePartFixe({
                            ...sig,
                            taxes_duties_devise_part_fixe: e.target.value.slice(
                              0,
                              e.target.value.length - 1
                            ),
                          })
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      defaultValue={0}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      isEdit
                      disabled={false}
                      name="taxes_duties_percentage_part_fixe"
                      control={control}
                      errors={errors}
                      suffix={t("%")}
                      value={sig.taxes_duties_percentage_part_fixe}
                      onChangeField={
                        (e) =>
                          handleUserUpdatePercentagePartFixe({
                            ...sig,
                            taxes_duties_percentage_part_fixe: e.target.value.slice(
                              0,
                              e.target.value.length - 1
                            ),
                          })
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      defaultValue={0}
                      register={register}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key="other_operating_expenses">
                  <TableCell component="th" scope="row">
                    <Typography variant="h9" gutterBottom display="inline">
                      <Typography variant="h5" gutterBottom display="inline">
                        <Trans i18nKey="other operating costs">
                          Autres charges d'exploitation (redevances…)
                        </Trans>
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      isEdit
                      disabled={false}
                      name="other_operating_expenses"
                      control={control}
                      errors={errors}
                      suffix={t("€")}
                      value={sig.other_operating_expenses}
                      onChangeField={
                        (e) =>
                          handleUserUpdateValue({
                            ...sig,
                            other_operating_expenses: e.target.value.slice(
                              0,
                              e.target.value.length - 1
                            ),
                          })
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      defaultValue={0}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      disabled
                      name="other_operating_expenses_percentage"
                      control={control}
                      errors={errors}
                      suffix={t("%")}
                      value={sig.other_operating_expenses_percentage}
                      defaultValue={0}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      isEdit
                      disabled={false}
                      name="other_operating_expenses_devise_part_fixe"
                      control={control}
                      errors={errors}
                      suffix={t("€")}
                      value={sig.other_operating_expenses_devise_part_fixe}
                      onChangeField={
                        (e) =>
                          handleUserUpdateDevisePartFixe({
                            ...sig,
                            other_operating_expenses_devise_part_fixe: e.target.value.slice(
                              0,
                              e.target.value.length - 1
                            ),
                          })
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      defaultValue={0}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      isEdit
                      disabled={false}
                      name="other_operating_expenses_percentage_part_fixe"
                      control={control}
                      errors={errors}
                      suffix={t("%")}
                      value={sig.other_operating_expenses_percentage_part_fixe}
                      onChangeField={
                        (e) =>
                          handleUserUpdatePercentagePartFixe({
                            ...sig,
                            other_operating_expenses_percentage_part_fixe: e.target.value.slice(
                              0,
                              e.target.value.length - 1
                            ),
                          })
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      defaultValue={0}
                      register={register}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
};

export default Taxes;
