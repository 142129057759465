/* eslint-disable radix */
import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import {
  Card as MuiCard,
  CardContent,
  Typography,
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import { useForm } from "react-hook-form";

import { spacing } from "@material-ui/system";

import { useSelector } from "react-redux";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";
import {
  getTotalProduct,
  getConsumedMaterials,
  getGrossMarginPercentage,
} from "../../services/sig/SigCalculService";

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles({
  margin: {
    marginTop: "12px",
  },
  input: {
    width: "90px!important",
  },
  gridInput: {
    paddingLeft: "30px",
  },
  gridTitle: {
    paddingBottom: "11px",
    paddingTop: "21px",
  },
  tableEqualWidth: {
    "& th": { width: "20%!important", border: "0px!important" },
    "& td": { border: "0px!important" },
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  card: {
    paddingBottom: "0px!important",
  },
  gridColor: {
    backgroundColor: "#1b2430",
  },
  noPadding: {
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  noPaddingTh: {
    "& th": {
      padding: "0px",
    },
  },
  paddingThTd: {
    "& th": {
      paddingTop: "5px!important",
      paddingBottom: "5px!important",
    },
    "& td": {
      paddingTop: "5px!important",
      paddingBottom: "5px!important",
    },
  },
  colorShadow: {
    backgroundColor: "#1b2430",

    "& input": {
      color: "white",
      fontSize: "medium",
      fontWeight: "500",
      marginBottom: "5px",
    },
    "& h5": {
      color: "white",
    },
  },
});
const GrossMargin = () => {
  const { register, control, errors } = useForm();
  const classes = useStyles();
  const { t } = useTranslation();
  const sig = useSelector((state) => state.sig.sig);

  return (
    <Card mb={12}>
      <CardContent
        className={[classes.card, classes.noPadding, classes.colorShadow]}
      >
        <TableContainer component={Paper}>
          <Table
            className={[classes.table, classes.tableEqualWidth]}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow className={[classes.noPaddingTh]}>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody className={classes.paddingThTd}>
              <TableRow key="gross_margin" className={classes.gridColor}>
                <TableCell component="th" scope="row">
                  <Typography variant="h5" gutterBottom display="inline">
                    <Trans i18nKey="Gross Margin">Marge Brute :</Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="gross_margin"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    value={getTotalProduct(sig) - getConsumedMaterials(sig)}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="gross_margin"
                    control={control}
                    errors={errors}
                    suffix={t("%")}
                    value={getGrossMarginPercentage(
                      getTotalProduct(sig),
                      getTotalProduct(sig) - getConsumedMaterials(sig)
                    )}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
export default GrossMargin;
