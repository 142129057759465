import {
  CLEAR_RANGE,
  CLEAR_RANGES,
  FETCH_RANGE,
  FETCH_RANGES_SUCCESS,
  UPDATE_RANGE_SUCCESS,
  CLEAN_RANGE,
} from "../types/rangeActionTypes";

const initialState = {
  range: {},
  ranges: [],
  rangeIsLoading: false,
  rangeListIsLoading: false,
};

const rangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RANGE:
      return {
        ...state,
        range: action.payload,
        rangeIsLoading: false,
      };
    case FETCH_RANGES_SUCCESS:
      return {
        ...state,
        ranges: action.payload,
        loading: false,
      };
    case UPDATE_RANGE_SUCCESS:
      return {
        ...state,
        range: action.payload,
        loading: false,
      };
    case CLEAR_RANGES:
      return {
        ...state,
        ranges: [],
      };
    case CLEAR_RANGE:
      return {
        ...state,
        range: {},
      };
    case CLEAN_RANGE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default rangeReducer;
