/*
    RangeBalanceService helps with computing the balance of a range, either from local data or from remote API (to fetch / save data)
*/

import { getParsedFloatNumber } from "./RangeService";

const ROUND = 2;

function toFixedNumber(num, digits, base) {
  const pow = (base || 10) ** digits;
  return Math.round(num * pow) / pow;
}

const getSumOfProductsPrices = (rangeBalanceModel) => {
  return getParsedFloatNumber(
    rangeBalanceModel.allProductPrices.reduce((a, b) => a + b, 0)
  );
};

const getSumOfAllProductSales = (rangeBalanceModel) => {
  return rangeBalanceModel.allQuantitiesSold.reduce((a, b) => a + b, 0);
};

const getMostExpensivePrice = (rangeBalanceModel) => {
  return Math.max(...rangeBalanceModel.allProductPrices);
};

const getLeastExpensivePrice = (rangeBalanceModel) => {
  return Math.min(...rangeBalanceModel.allProductPrices);
};

const getSumOfArithmeticProductsOfPricesAndSells = (rangeBalanceModel) => {
  return rangeBalanceModel.allProductPrices.reduce((r, a, i) => {
    return r + a * rangeBalanceModel.allQuantitiesSold[i];
  }, 0);
};

const getOpeningRatio = (rangeBalanceModel) => {
  const high = getMostExpensivePrice(rangeBalanceModel);
  const low = getLeastExpensivePrice(rangeBalanceModel);
  if (low === 0) {
    return 0;
  }
  return toFixedNumber(high / low, ROUND);
};

const getFirstPrincipleInterpretation = (rangeBalanceData) => {
  const numberOfProducts = rangeBalanceData.allProductPrices.length;
  const openingRatio = getOpeningRatio(rangeBalanceData);

  const OK_INTERPRETATION =
    "L'ouverture de la gamme est correcte : l'offre proposée dans cette gamme est homogène";
  const OK_CONCENTRATED_INTERPRETATION =
    "L'ouverture de la gamme est correcte : l'offre proposée dans cette gamme est homogène et/mais très concentrée";
  const KO_INTERPRETATION =
    "L'ouverture de la gamme n'est pas correcte : trop d'écart entre les prix peut provoquer l'indécision du client";

  if (openingRatio < 1.5) {
    return OK_CONCENTRATED_INTERPRETATION;
  }

  if (numberOfProducts < 9) {
    if (openingRatio < 2.5) {
      return OK_INTERPRETATION;
    }
    return KO_INTERPRETATION;
  }

  if (numberOfProducts >= 9) {
    if (openingRatio < 3) {
      return OK_INTERPRETATION;
    }
    return KO_INTERPRETATION;
  }

  return "Vos données n'entrent dans aucun critère";
};

// SECOND PRINCIPE

const getLowSlice = (rangeBalanceModel) => {
  return getLeastExpensivePrice(rangeBalanceModel);
};

const getLowMiddleSlice = (rangeBalanceModel) => {
  const mostExpensive = getMostExpensivePrice(rangeBalanceModel);
  const lessExpensive = getLeastExpensivePrice(rangeBalanceModel);
  return toFixedNumber(
    lessExpensive + (mostExpensive - lessExpensive) / 3,
    ROUND
  );
};

const getHighMiddleSlice = (rangeBalanceModel) => {
  const mostExpensive = getMostExpensivePrice(rangeBalanceModel);
  const lessExpensive = getLeastExpensivePrice(rangeBalanceModel);
  return toFixedNumber(
    mostExpensive - (mostExpensive - lessExpensive) / 3,
    ROUND
  );
};

const getTopSlice = (rangeBalanceModel) => {
  return getMostExpensivePrice(rangeBalanceModel);
};

const getNumberOfProductsInFirstSlice = (rangeBalanceModel) => {
  const lowMidSlice = getLowMiddleSlice(rangeBalanceModel);
  const countOfProductsUnderLowMidSlice = rangeBalanceModel.allProductPrices.filter(
    (productPrice) => productPrice < lowMidSlice
  ).length;
  const countOfProductsEqualLowMidSlice = rangeBalanceModel.allProductPrices.filter(
    (productPrice) => productPrice === lowMidSlice
  ).length;
  const result =
    countOfProductsUnderLowMidSlice + 0.5 * countOfProductsEqualLowMidSlice;
  return result > 0 ? result : 0;
};

const getNumberOfProductsInSecondSlice = (rangeBalanceModel) => {
  const lowMidSlice = getLowMiddleSlice(rangeBalanceModel);
  const highMiddleSlice = getHighMiddleSlice(rangeBalanceModel);

  const countOfProductsUnderHighMidSlice = rangeBalanceModel.allProductPrices.filter(
    (productPrice) => productPrice < highMiddleSlice
  ).length;
  const countOfProductsUnderOrEqualLowMidSlice = rangeBalanceModel.allProductPrices.filter(
    (productPrice) => productPrice <= lowMidSlice
  ).length;
  const countOfProductsEqualLowMidSlice = rangeBalanceModel.allProductPrices.filter(
    (productPrice) => productPrice === lowMidSlice
  ).length;
  const countOfProductsEqualHighMidSlice = rangeBalanceModel.allProductPrices.filter(
    (productPrice) => productPrice === lowMidSlice
  ).length;

  const result =
    countOfProductsUnderHighMidSlice -
    countOfProductsUnderOrEqualLowMidSlice +
    0.5 * (countOfProductsEqualHighMidSlice + countOfProductsEqualLowMidSlice);

  return result > 0 ? result : 0;
};

const getNumberOfProductsInThirdSlice = (rangeBalanceModel) => {
  const highMiddleSlice = getHighMiddleSlice(rangeBalanceModel);
  const topSlice = getTopSlice(rangeBalanceModel);

  const countOfProductsUnderOrEqualTopSlice = rangeBalanceModel.allProductPrices.filter(
    (productPrice) => productPrice <= topSlice
  ).length;
  const countOfProductsUnderOrEqualHighMidSlice = rangeBalanceModel.allProductPrices.filter(
    (productPrice) => productPrice <= highMiddleSlice
  ).length;

  const countOfProductsEqualHighMidSlice = rangeBalanceModel.allProductPrices.filter(
    (productPrice) => productPrice === highMiddleSlice
  ).length;

  const result =
    countOfProductsUnderOrEqualTopSlice -
    countOfProductsUnderOrEqualHighMidSlice +
    0.5 * countOfProductsEqualHighMidSlice;
  return result > 0 ? result : 0;
};

const getSecondPrincipleInterpretation = (rangeBalanceModel) => {
  let dispersion = "La dispersion n'est pas correcte";
  if (
    getNumberOfProductsInSecondSlice(rangeBalanceModel) >=
    getNumberOfProductsInFirstSlice(rangeBalanceModel) +
      getNumberOfProductsInThirdSlice(rangeBalanceModel)
  ) {
    dispersion = "La dispersion est pas correcte";
  }

  let productBalance =
    "La tranche médiane ne contient pas assez de produits par rapport aux 2 autres réunies";
  if (
    getNumberOfProductsInSecondSlice(rangeBalanceModel) ===
    getNumberOfProductsInFirstSlice(rangeBalanceModel) +
      getNumberOfProductsInThirdSlice(rangeBalanceModel)
  ) {
    productBalance =
      "Il y a autant de produits dans la tranche médiane que dans les 2 autres réunies";
  }

  if (
    getNumberOfProductsInSecondSlice(rangeBalanceModel) >
    getNumberOfProductsInFirstSlice(rangeBalanceModel) +
      getNumberOfProductsInThirdSlice(rangeBalanceModel)
  ) {
    productBalance =
      "Il y a plus de produits dans la tranche médiane que dans les 2 autres réunies";
  }

  let writtenQuantityOfProductInSecondSliceRecommanded = "";
  if (
    getNumberOfProductsInSecondSlice(rangeBalanceModel) <
    getNumberOfProductsInFirstSlice(rangeBalanceModel) +
      getNumberOfProductsInThirdSlice(rangeBalanceModel)
  ) {
    const quantityOfProductInSecondSliceRecommanded = toFixedNumber(
      rangeBalanceModel.allProductPrices.length / 2,
      0
    );
    writtenQuantityOfProductInSecondSliceRecommanded = `La tranche moyenne devrait comporter au moins ${quantityOfProductInSecondSliceRecommanded} produit`;
  }

  return {
    dispersion,
    productBalance,
    writtenQuantityOfProductInSecondSliceRecommanded,
  };
};

// Third principle

const getAveragePrice = (rangeBalanceModel) => {
  return getParsedFloatNumber(
    toFixedNumber(
      rangeBalanceModel.allProductPrices.reduce((p, c) => p + c, 0) /
        rangeBalanceModel.allProductPrices.length,
      2
    )
  );
};

const getAvarageRequestedPrice = (rangeBalanceModel) => {
  let sumprod = 0;
  for (let i = 0; i < rangeBalanceModel.allProductPrices.length; i += 1) {
    sumprod +=
      rangeBalanceModel.allProductPrices[i] *
      rangeBalanceModel.allProductQuantitiesSold[i];
  }

  const sumOfSoldQuantities = rangeBalanceModel.allProductQuantitiesSold.reduce(
    (a, b) => a + b,
    0
  );

  if (sumOfSoldQuantities > 0) {
    return getParsedFloatNumber(
      toFixedNumber(sumprod / sumOfSoldQuantities, 2)
    );
  }

  return 0;
};

const getIndiceReponsePrix = (rangeBalanceModel) =>
  getParsedFloatNumber(
    toFixedNumber(
      getAvarageRequestedPrice(rangeBalanceModel) /
        getAveragePrice(rangeBalanceModel),
      2
    )
  );

const getThirdPrincipleInterpretation = (rangeBalanceModel) => {
  const indiceReponsePrix = getIndiceReponsePrix(rangeBalanceModel);
  if (indiceReponsePrix < 0.89) {
    return "L'indice réponse/prix est trop faible. Les clients tirent la carte vers le bas. Votre gamme propose des prix trop élevés pour les clients.";
  }

  if (indiceReponsePrix <= 0.9) {
    return "L'indice réponse/prix est un peu trop faible. Les clients tirent légèrement la carte vers le bas. Votre gamme propose des prix sensiblement trop élevés pour les clients";
  }

  if (indiceReponsePrix <= 1) {
    return "L'indice réponse/prix est correct. Vous tirez légèrement les clients vers le haut de la gamme. Bonne adéquation de l'offre à la demande. Si vous devez augmenter vos prix, assurez-vous que l'indice réponse/prix reste stable.";
  }

  if (indiceReponsePrix <= 1.01) {
    return "L'indice réponse/prix est un peu trop élevé. Les client tirent légèrement la gamme vers le haut. Votre offre de prix dans cette gamme est sensiblement en_dessous des prix demandés par les clients";
  }

  return "L'inice réponse prix est trop élevé. Les clients tirent la carte vers le haut. Votre offre de prix dans cette gamme est en-dessous des prix demandés par les clients.";
};

const getRecommandedAveragePriceRange = (rangeBalanceModel) => {
  const indiceReponsePrix = getIndiceReponsePrix(rangeBalanceModel);
  const minAveragePrice = getAvarageRequestedPrice(rangeBalanceModel);
  const maxAveragePrice = toFixedNumber(
    (1 / 0.9) * getAvarageRequestedPrice(rangeBalanceModel),
    2
  );
  if (indiceReponsePrix < 0.9 || indiceReponsePrix > 1) {
    return `Votre Prix moyen offert devrait se situer entre ${minAveragePrice}€ et ${maxAveragePrice} €`;
  }
  return "";
};

export {
  toFixedNumber,
  getSumOfProductsPrices,
  getSumOfAllProductSales,
  getMostExpensivePrice,
  getLeastExpensivePrice,
  getSumOfArithmeticProductsOfPricesAndSells,
  getFirstPrincipleInterpretation,
  getOpeningRatio,
  getLowSlice,
  getLowMiddleSlice,
  getHighMiddleSlice,
  getTopSlice,
  getNumberOfProductsInFirstSlice,
  getNumberOfProductsInSecondSlice,
  getNumberOfProductsInThirdSlice,
  getSecondPrincipleInterpretation,
  getAveragePrice,
  getAvarageRequestedPrice,
  getIndiceReponsePrix,
  getThirdPrincipleInterpretation,
  getRecommandedAveragePriceRange,
};
