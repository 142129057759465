import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

import { Divider, Typography, makeStyles, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import RangeOfferDetails from "../../components/Offers/rangeOfferDetails";
import OfferDetails from "../../components/Offers/offerDetails";
import OfferGoal from "../../components/Offers/offerGoal";

import { fetchOffer } from "../../redux/actions/offerActions";
import { fetchOfferRanges } from "../../redux/actions/offerRangeActions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  margin: {
    marginTop: "24px",
    marginBottom: "24px",
  },
}));

const ShowOffer = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { offerId } = useParams();
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const offer = useSelector((state) => state.offer.currentOffer);
  const offerRanges = useSelector((state) => state.offerRanges.offerRanges);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOffer(offerId, user.accessToken));
    dispatch(fetchOfferRanges(offerId, user.accessToken));
  }, [offerId]);

  const getOfferName = (name) => `${t("Offre")}: ${name}`;

  return (
    <>
      <Helmet>
        <title>{t("Offre")}</title>
      </Helmet>

      <Typography variant="h3" gutterBottom display="inline">
        {getOfferName(offer.name)}
      </Typography>

      <Divider my={6} mr={24} className={classes.margin} />
      <OfferDetails offer={offer} />
      <Divider my={6} mr={24} className={classes.margin} />

      <Grid container spacing={2}>
        <Grid item xs={9}>
          <RangeOfferDetails offer={offer} offerRanges={offerRanges} />
        </Grid>
        <Grid item xs={3}>
          <OfferGoal />
        </Grid>
      </Grid>
    </>
  );
};

export default ShowOffer;
