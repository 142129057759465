import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Card as MuiCard,
  CardContent,
  makeStyles,
  TextField as MuiTextField,
} from "@material-ui/core";
import PropTypes from "prop-types";

import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { spacing } from "@material-ui/system";
import { updateOffer } from "../../redux/actions/offerActions";
import { openSnackbar } from "../../redux/actions/snackbarActions";
import OfferModel from "../../models/OfferModel";
import useDebounce from "../../services/useDebounce";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  margin: {
    marginTop: "24px",
    marginBottom: "24px",
  },
}));

const offerDetails = ({ offer }) => {
  const classes = useStyles();
  const [offerState, setOfferState] = useState({
    name: offer.name,
  });
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const debouncedOfferState = useDebounce(offerState, 1000);
  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    await dispatch(updateOffer(data, offer.uuid, user.accessToken));
    await dispatch(openSnackbar("Offre éditée avec succès", "success"));
  };
  useEffect(() => {
    const offerData = { name: offer.name };
    if (JSON.stringify(offerData) !== JSON.stringify(debouncedOfferState)) {
      onSubmit(debouncedOfferState);
    }
  }, [debouncedOfferState]);
  useEffect(() => {
    setOfferState({
      name: offer.name,
    });
  }, [offer]);
  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card mb={6}>
          <CardContent>
            <Grid className={classes.root} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="name"
                  label={
                    <Trans i18nKey="offer name">Nom de l&apos;offre *</Trans>
                  }
                  name="name"
                  value={offerState.name}
                  // eslint-disable-next-line prettier/prettier
                  onChange={(e) => setOfferState({ ...offerState, name: e.target.value })}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

offerDetails.propTypes = {
  offer: PropTypes.objectOf(OfferModel).isRequired,
};

export default offerDetails;
