export const FETCH_TOTAL_RAW_COST = "FETCH_TOTAL_RAW_COST";
export const FETCH_NUMBER_PRODUCED = "FETCH_NUMBER_PRODUCED";
export const FETCH_TOTAL_PRICE_PORTION = "FETCH_TOTAL_PRICE_PORTION";
export const FETCH_TOTAL_ENERGY = "FETCH_TOTAL_ENERGY";
export const ERROR_FETCHING_PRODUCT = "ERROR_FETCHING_PRODUCT";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const CLEAR_PRODUCT = "FETCH_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_ERROR = "UPDATE_PRODUCT_ERROR";
export const FETCH_PRODUCTS_ERROR = "FETCH_PRODUCTS_ERROR";
// Raw material action types
export const FETCH_RAW_MATERIALS = "FETCH_RAW_MATERIALS";
export const FETCH_RAW_MATERIALS_ERROR = "FETCH_RAW_MATERIALS_ERROR";
export const POST_RAW_MATERIAL = "POST_RAW_MATERIAL";
export const POST_RAW_MATERIAL_ERROR = "POST_RAW_MATERIAL_ERROR";
export const PUT_RAW_MATERIAL = "PUT_RAW_MATERIAL";
export const PUT_RAW_MATERIAL_ERROR = "PUT_RAW_MATERIAL_ERROR";
export const UPDATE_RAW_MATERIAL = "UPDATE_RAW_MATERIAL";
export const DELETE_RAW_MATERIAL = "DELETE_RAW_MATERIAL";
export const DELETE_RAW_MATERIAL_ERROR = "DELETE_RAW_MATERIAL_ERROR";

export const CLEAN_PRODUCT = "CLEAN_PRODUCT";
