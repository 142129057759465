import {
  interactionListRawMaterialsTotalPrice,
  interactionTotalEnergy,
} from "./TechnicalSheetCalculInteractionService";
import {
  getListTotalEnergy,
  getListTotalPrice,
} from "./TechnicalSheetMethodService";

import { store } from "../../redux/store";
import { updateTechnicalSheet } from "../../redux/actions/technicalSheetActions";
import { openSnackbar } from "../../redux/actions/snackbarActions";
import { getParsedFloatNumber, toFixedNumber } from "../range/RangeService";

const updateIndicators = async (rawMaterial, accessToken) => {
  const rawMaterials = store.getState().product.raw_materials;
  const { currentProject } = store.getState().projects;
  const listTotalPrice = getListTotalPrice(rawMaterials);
  const listTotalEnergy = getListTotalEnergy(rawMaterials);

  let resultTechnicalSheet = interactionListRawMaterialsTotalPrice(
    listTotalPrice
  );
  const resultTechnicalSheetTotalEnergy = interactionTotalEnergy(
    listTotalEnergy
  );

  resultTechnicalSheet = {
    ...resultTechnicalSheet,
    totalEnergy: {
      ...resultTechnicalSheetTotalEnergy.totalEnergy,
    },
    perUnitEnergy: {
      ...resultTechnicalSheetTotalEnergy.perUnitEnergy,
    },
  };
  await store.dispatch(
    updateTechnicalSheet(
      resultTechnicalSheet,
      accessToken,
      rawMaterial.product_id,
      currentProject.id
    )
  );
  store.dispatch(
    openSnackbar("Fiche technique modifiée avec succès ", "Success")
  );
};

export const getNetQuantityFromRawQuantityAndLoss = (rawQuantity, loss) => {
  const parsedRawQuantity = toFixedNumber(getParsedFloatNumber(rawQuantity), 2);
  // Loss is written in whole percentage format (10 => 10%) so we need to divide it by 100
  const parsedLoss = toFixedNumber(getParsedFloatNumber(loss) / 100.0, 2);
  return toFixedNumber(parsedRawQuantity - parsedRawQuantity * parsedLoss, 2);
};

export const getTotalPriceFromRawQuantityAndUnitPrice = (
  rawQuantity,
  unitPrice
) => {
  const parsedRawQuantity = toFixedNumber(getParsedFloatNumber(rawQuantity), 2);
  const parsedUnitPrice = toFixedNumber(getParsedFloatNumber(unitPrice), 2);
  return toFixedNumber(
    getParsedFloatNumber(parsedRawQuantity * parsedUnitPrice),
    2
  );
};

export default updateIndicators;
