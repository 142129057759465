// eslint-disable-next-line no-unused-vars
function toFixedNumber(num, digits, base) {
  const pow = (base || 10) ** digits;
  return Math.round(num * pow) / pow;
}

// Total Chiffre d'affaires TTC
export function getTotalTurnoverTTC(listTurnoverTTC, listQuantitySold) {
  let result = 0;
  let i = 0;
  listTurnoverTTC.forEach((turnoverTTC) => {
    result += turnoverTTC * listQuantitySold[i];
    i += 1;
  });
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Total Chiffre d'affaires HT
export function getTotalTurnoverHT(listTurnoverHT, listQuantitySold) {
  let result = 0;
  let i = 0;
  listTurnoverHT.forEach((turnoverHT) => {
    result += turnoverHT * listQuantitySold[i];
    i += 1;
  });
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Total Consommation de Matières HT
export function getTotalconsumptionMaterialsHT(
  listMaterialConsumptionHT,
  listQuantitySold
) {
  let result = 0;
  let i = 0;
  listMaterialConsumptionHT.forEach((element) => {
    result += element * listQuantitySold[i];
    i += 1;
  });
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Coefficient moyenne gamme
export function getMultiplyingCoefficientRange(
  turnoverHT,
  materialConsumptionHT
) {
  let result = turnoverHT / materialConsumptionHT;
  result = toFixedNumber(result, 2);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Marge Brute HT
export function getGrossMarginHT(turnoverHT, materialConsumptionHT) {
  let result = turnoverHT - materialConsumptionHT;
  result = toFixedNumber(result, 2);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Ratio moyenne Gamme
export function getAverageMaterialRatio(multiplyingCoefficientRange) {
  let result = 1 / multiplyingCoefficientRange;
  result = toFixedNumber(result * 100, 2);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Cser Gamme
export function getCserRange(multiplyingCoefficientRange, coefficientK) {
  let result = multiplyingCoefficientRange * coefficientK;
  result = toFixedNumber(result, 2);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}
