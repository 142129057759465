/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  Divider,
  InputAdornment,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import Edit from "@material-ui/icons/Edit";
import {
  interactionCoefficentK,
  interactionVariationPopularity,
  interactionVariationRentability,
} from "../../services/range/RangeCalculInteractionService";
import { updateRangeAction } from "../../redux/actions/rangeActions";
import {
  putRangeProducts,
  switchPopser,
} from "../../redux/actions/rangeProductActions";
import { openSnackbar } from "../../redux/actions/snackbarActions";

const useStyles = makeStyles(() => ({
  ButtonRight: {
    width: "100%",
  },
  underlineTextField: {
    "& .MuiInput-underline:before": {
      borderBottom: "0px",
    },
  },
  input: {
    "& input": {
      width: "25px",
    },
    "& i.MuiInputBase-input::after": {
      content: "%",
    },
  },
  margin: {
    marginTop: "20px",
  },

  popserStyle: {
    fontWeight: "600",
    color: "#1876d1",
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
}));

function TurnoverRangeDashboard() {
  const classes = useStyles();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const { rangeId } = useParams();
  const popser = useSelector((state) => state.rangeProducts.popser);

  const dispatch = useDispatch();
  const range = useSelector((state) => state.ranges.range);
  const [rangeEditing, setRangeEditing] = useState(range);
  const handleChangeCoefficientK = (event) => {
    const result = interactionCoefficentK(event.target.value);
    dispatch(updateRangeAction(result.range, range.id, user.accessToken));
    dispatch(putRangeProducts(result.rangeProducts, user.accessToken));

    dispatch(openSnackbar("Gamme modifiée avec succès", "success"));
  };
  const handleChangeVariationPopularity = (event) => {
    const result = interactionVariationPopularity(event.target.value);

    dispatch(updateRangeAction(result.range, range.id, user.accessToken));
    dispatch(putRangeProducts(result.rangeProducts, user.accessToken));

    dispatch(openSnackbar("Gamme modifiée avec succès", "success"));
  };
  const handleChangeVariationRentability = (event) => {
    const result = interactionVariationRentability(event.target.value);
    dispatch(updateRangeAction(result.range, range.id, user.accessToken));
    dispatch(putRangeProducts(result.rangeProducts, user.accessToken));

    dispatch(openSnackbar("Gamme modifiée avec succès", "success"));
  };

  const onChangeIsPopser = () => {
    const reversePopser = {
      isPopser: !popser.isPopser,
      name:
        popser.name === "Classement du Popser"
          ? "Classement du Popser"
          : "Classement du Boston Consulting Group",
    };
    dispatch(switchPopser(reversePopser));
  };

  useEffect(() => {
    setRangeEditing({
      coefficient_k: range.coefficient_k,
      variation_popularity: range.variation_popularity,
      variation_rentability: range.variation_rentability,
    });
  }, [range]);

  return (
    <>
      <Grid container spacing={0}>
        <Table>
          <TableBody className={classes.root}>
            <TableRow>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Turnover incl. VAT">
                  Chiffre d&apos;affaires TTC
                </Trans>
              </TableCell>
              <TableCell>
                <TextField
                  disabled
                  className={(classes.input, classes.underlineTextField)}
                  id="turnoverTTC"
                  name="turnoverTTC"
                  value={range.sales_revenue_TTC}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Average material ratio">
                  Ratio matière moyen
                </Trans>
              </TableCell>
              <TableCell>
                <NumberFormat
                  disabled
                  value={range.average_material_ratio}
                  id="averageMaterialRatio"
                  name="averageMaterialRatio"
                  className={classes.underlineTextField}
                  customInput={TextField}
                  suffix="%"
                />
              </TableCell>
              <TableCell rowSpan={2} colSpan={2}>
                <FormGroup row>
                  <Grid container>
                    <Grid item xs={3} />

                    <Grid item xs={4}>
                      {" "}
                      Classement du Boston Consulting Group
                    </Grid>
                    <Grid item xs={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={popser.isPopser}
                            onChange={onChangeIsPopser}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.popserStyle}>
                      {" "}
                      Classement du Popser
                    </Grid>
                  </Grid>
                </FormGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Turnover incl. HT">
                  Chiffre d&apos;affaires HT
                </Trans>
              </TableCell>
              <TableCell>
                <TextField
                  disabled
                  className={(classes.input, classes.underlineTextField)}
                  id="turnoverHT"
                  name="turnoverHT"
                  value={range.sales_revenue_HT}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Trans i18nKey="multiplying Coefficient Range">
                  Coefficient multiplicateur de gamme
                </Trans>
              </TableCell>
              <TableCell>
                <TextField
                  disabled
                  className={(classes.input, classes.underlineTextField)}
                  id="multiplyingCoefficientRange"
                  name="multiplyingCoefficientRange"
                  value={range.range_multiplier_coefficient}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Material consumption excl. VAT">
                  Consommation de Matières HT
                </Trans>
              </TableCell>
              <TableCell>
                <TextField
                  disabled
                  className={(classes.input, classes.underlineTextField)}
                  id="consumptionMaterialsHT"
                  name="consumptionMaterialsHT"
                  value={range.material_consumption_HT}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Trans i18nKey="coefficient k">coefficient k</Trans>
              </TableCell>
              <TableCell>
                <TextField
                  className={(classes.input, classes.underlineTextField)}
                  id="coefficientK"
                  type="number"
                  onBlur={(e) => handleChangeCoefficientK(e)}
                  name="coefficientK"
                  defaultValue={range.coefficient_k}
                  value={rangeEditing.coefficient_k}
                  onChange={
                    (e) =>
                      setRangeEditing({
                        rangeEditing,
                        coefficient_k: e.target.value,
                      })
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  InputProps={{
                    endAdornment: (
                      <Edit className={classes.iconEdit} onClick={() => 0}>
                        x
                      </Edit>
                    ),
                  }}
                />
              </TableCell>
              <TableCell component="th" scope="row" colSpan={2}>
                <Trans i18nKey="Degree of slice tolerance">
                  Degré de tolérence des tranches &quot;moyennes&quot;
                </Trans>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Gross margin excl. tax">Marge brute HT</Trans>
              </TableCell>
              <TableCell>
                <TextField
                  disabled
                  className={(classes.input, classes.underlineTextField)}
                  id="grossMarginHT"
                  name="grossMarginHT"
                  value={range.gross_margin}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Cser">Cser Gamme</Trans>
              </TableCell>
              <TableCell>
                <TextField
                  disabled
                  className={(classes.input, classes.underlineTextField)}
                  id="cserRange"
                  name="cserRange"
                  value={range.cser_range}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Trans i18nKey="+ or -">+ ou -</Trans>
              </TableCell>
              <TableCell>
                <TextField
                  className={(classes.input, classes.underlineTextField)}
                  id="variation_popularity"
                  onBlur={(e) => handleChangeVariationPopularity(e)}
                  type="number"
                  name="variation_popularity"
                  defaultValue={range.variation_popularity}
                  value={rangeEditing.variation_popularity}
                  onChange={
                    (e) =>
                      setRangeEditing({
                        rangeEditing,
                        variation_popularity: e.target.value,
                      })
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end">% </InputAdornment>
                        <Edit className={classes.iconEdit} onClick={() => 0}>
                          x
                        </Edit>
                      </>
                    ),
                  }}
                />
                <Trans i18nKey="in popularity"> en popularité</Trans>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Average price incl. VAT">
                  Prix moyen offert TTC
                </Trans>
              </TableCell>
              <TableCell>
                <TextField
                  disabled
                  className={(classes.input, classes.underlineTextField)}
                  id="averagePriceTTC"
                  name="averagePriceTTC"
                  value={range.average_price_offered_TTC}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Average asking price incl. VAT">
                  Prix moyen demandé TTC
                </Trans>
              </TableCell>
              <TableCell>
                <TextField
                  disabled
                  className={(classes.input, classes.underlineTextField)}
                  id="averagePriceRequested"
                  name="averagePriceRequested"
                  value={range.average_price_requested_TTC}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Trans i18nKey="+ or -">+ ou -</Trans>
              </TableCell>
              <TableCell>
                <TextField
                  className={(classes.input, classes.underlineTextField)}
                  id="variation_rentability"
                  type="number"
                  onBlur={(e) => handleChangeVariationRentability(e)}
                  name="variation_rentability"
                  defaultValue={range.variation_rentability}
                  value={rangeEditing.variation_rentability}
                  onChange={
                    (e) =>
                      setRangeEditing({
                        rangeEditing,
                        variation_rentability: e.target.value,
                      })
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end">% </InputAdornment>
                        <Edit className={classes.iconEdit} onClick={() => 0}>
                          x
                        </Edit>
                      </>
                    ),
                  }}
                />
                <Trans i18nKey="rentability"> de rentabilité</Trans>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Divider my={6} mr={24} className={classes.margin} />
    </>
  );
}

export default TurnoverRangeDashboard;
