import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Card,
  CardContent,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Divider,
} from "@material-ui/core";

import { Trans } from "react-i18next";
import NumberFormat from "react-number-format";

const useStyles = makeStyles(() => ({
  input: {
    "&::before": {},
    width: "70px",
  },
  root: {
    "& .MuiInput-underline:before": {
      borderBottom: "solid 1px", //
    },
  },
}));

const offerGoal = () => {
  const classes = useStyles();

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom display="inline">
            <Trans i18nKey="Goal">Objectifs</Trans>
          </Typography>
          <Divider my={6} mr={24} className={classes.margin} />

          <Grid container spacing={2}>
            <Table>
              <TableBody className={classes.root}>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Trans i18nKey="MBser Annual">MBser annuel</Trans>
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      disabled
                      value={0}
                      name="MBserAnnual"
                      customInput={TextField}
                      className={classes.input}
                      suffix="€"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Trans i18nKey="MBser Week">MBser semaine</Trans>
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      disabled
                      value={0}
                      name="MBserWeek"
                      customInput={TextField}
                      className={classes.input}
                      suffix="€"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Trans i18nKey="Cser">Cser</Trans>
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      disabled
                      value={0}
                      name="Cser"
                      customInput={TextField}
                      className={classes.input}
                      suffix=""
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Trans i18nKey="RatioSer">Ratio ser</Trans>
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      disabled
                      value={0}
                      name="Ratio ser"
                      customInput={TextField}
                      className={classes.input}
                      suffix=""
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Trans i18nKey="Number Customers Weeks">
                      Nombre de clients / semaine du ser
                    </Trans>
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      disabled
                      value={0}
                      name="NumberCustomersWeeks"
                      customInput={TextField}
                      className={classes.input}
                      suffix=""
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default offerGoal;
