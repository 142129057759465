import { getParsedFloatNumber, toFixedNumber } from "../range/RangeService";
import { getTotalProduct } from "../sig/SigCalculService";

export const getExternalCostTotal = (external_costs) =>
  external_costs.reduce((sum, external_cost) => {
    return toFixedNumber(
      sum + getParsedFloatNumber(external_cost.indicator),
      2
    );
  }, 0.0);

export const getExternalCostTotalPercentage = (external_cost_total, sig) =>
  toFixedNumber(
    getParsedFloatNumber((external_cost_total / getTotalProduct(sig)) * 100),
    2
  );

export const getExternalCostTotalFixedPart = (external_costs) =>
  external_costs.reduce((sum, external_cost) => {
    return toFixedNumber(
      sum + getParsedFloatNumber(external_cost.indicator_amount_fixed_part),
      2
    );
  }, 0.0);

export const getExternalCostTotalFixedPartPercentage = (external_costs) =>
  toFixedNumber(
    getParsedFloatNumber(
      (getExternalCostTotalFixedPart(external_costs) /
        getExternalCostTotal(external_costs)) *
        100
    ),
    2
  );
