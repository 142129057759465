import { addOffer } from "../api/offerAPI";

import { openSnackbar } from "../../redux/actions/snackbarActions";

export const addOfferInteraction = async (
  project_id,
  accessToken,
  offer,
  dispatch
) => {
  let resultOffer = null;

  resultOffer = await addOffer(project_id, accessToken, offer);

  if (resultOffer.isError) {
    dispatch(
      openSnackbar("Erreur lors de l'ajout, veuillez reéssayer", "Error")
    );
  } else {
    dispatch(openSnackbar("Offre ajouté avec succès", "Success"));
    return resultOffer.result;
  }
  return resultOffer.result;
};

export default addOfferInteraction;
