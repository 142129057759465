import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import MainRouter from "./router";
import Loading from "./components/Loading/Loading";
import Snackbar from "./components/SnackbarComponent/SnackbarComponent";

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return <Loading />;
  return children;
}

function App() {
  const auth = useSelector((state) => state.firebase.auth);
  useEffect(() => {}, auth);

  return (
    <AuthIsLoaded>
      <Snackbar />
      <MainRouter />
    </AuthIsLoaded>
  );
}

export default App;
