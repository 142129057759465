import { HELPER_DECIMAL_2_ACTION } from "../types/helperTextActionTypes";

const initialState = {
  helper_text_decimal_2: "",
};

const helperTextReducer = (state = initialState, action) => {
  switch (action.type) {
    case HELPER_DECIMAL_2_ACTION:
      return {
        ...state,
        helper_text_decimal_2: action.payload,
      };
    default:
      return state;
  }
};

export default helperTextReducer;
