import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Edit from "@material-ui/icons/Edit";

import {
  Card as MuiCard,
  CardContent,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { updateUserAction } from "../../redux/actions/userActions";
import { openSnackbar } from "../../redux/actions/snackbarActions";
import useDebounce from "../../services/useDebounce";
import { updateRangeAction } from "../../redux/actions/rangeActions";
import {
  toFixedNumber,
  getParsedFloatNumber,
} from "../../services/range/RangeService";
import {
  interactionCustomersADay,
  interactionSurveyedCustomers,
  interactionChangePollQuantityByRangeProjectedMode,
} from "../../services/range/RangeCalculInteractionService";
import {
  putRangeProducts,
  updateRangeProducts,
} from "../../redux/actions/rangeProductActions";

const useStyles = makeStyles(() => ({
  underline: {
    "&.MuiInput-input": {
      border: "none",
    },
    "&.MuiInput-input::after": {
      borderBottom: "none",
    },
  },
  height56: {
    height: "56px",
  },
  width90: {
    width: "90px",
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  disableField: {
    "& td div": {
      color: "black!important",
    },
  },
}));

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);

const PreviewRange = () => {
  const range = useSelector((state) => state.ranges.range);
  const [rangeState, setRangeState] = useState(range);
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const debouncedgeneralInformationForm = useDebounce(rangeState, 600);
  const userAction = useSelector((state) => state.user.user_action);
  const rangeProducts = useSelector(
    (state) => state.rangeProducts.rangeProducts
  );
  const handleInputChanged = (event) => console.log(event);
  useEffect(() => {
    if (range) {
      setRangeState(range);
    }
  }, [range]);

  useEffect(async () => {
    if (debouncedgeneralInformationForm && userAction) {
      dispatch(updateRangeAction(rangeState, range.id, user.accessToken));
      dispatch(putRangeProducts(rangeProducts, user.accessToken));
      dispatch(updateUserAction(false));
      const updateMessage = `Informations sauvegardées`;

      dispatch(openSnackbar(updateMessage, "success"));
    }
  }, [debouncedgeneralInformationForm]);

  const handleChangeNumberCustomersByDay = (event) => {
    const customers_by_day = getParsedFloatNumber(event.target.value);

    const customers_by_year = interactionCustomersADay(
      customers_by_day,
      rangeState.days_a_year,
      range
    );
    setRangeState({
      ...rangeState,
      customers_by_day,
      ...customers_by_year,
    });
    const updatedRangeProducts = interactionChangePollQuantityByRangeProjectedMode(
      {
        ...rangeState,
        customers_by_day,
        ...customers_by_year,
      }
    );
    dispatch(
      updateRangeProducts(updatedRangeProducts.rangeProducts, user.accessToken)
    );
    dispatch(updateUserAction(true));
  };

  const handleChangeSurveyedCustomers = (event) => {
    const surveyed_customers = getParsedFloatNumber(event.target.value);
    const customer_choices_in_survey_by_year = interactionSurveyedCustomers(
      range.customers_by_year,
      range.count_of_choices_in_survey,
      surveyed_customers
    );

    setRangeState({
      ...rangeState,
      surveyed_customers,
      customer_choices_in_survey_by_year,
    });
    const updatedRangeProducts = interactionChangePollQuantityByRangeProjectedMode(
      {
        ...rangeState,
        surveyed_customers,
        customer_choices_in_survey_by_year,
      }
    );
    dispatch(
      putRangeProducts(updatedRangeProducts.rangeProducts, user.accessToken)
    );
    dispatch(updateUserAction(true));
  };

  const handleChangeDaysAYear = (event) => {
    const days_a_year = getParsedFloatNumber(event.target.value);
    const customers_by_year = interactionCustomersADay(
      rangeState.customers_by_day,
      days_a_year
    );
    setRangeState({
      ...rangeState,
      days_a_year,
      ...customers_by_year,
    });
    const updatedRangeProducts = interactionChangePollQuantityByRangeProjectedMode(
      {
        ...rangeState,
        days_a_year,
        ...customers_by_year,
      }
    );
    dispatch(
      putRangeProducts(updatedRangeProducts.rangeProducts, user.accessToken)
    );
    dispatch(updateUserAction(true));
  };

  return (
    <Card mb={12}>
      <CardContent>
        <Grid container spacing={10}>
          <Grid item xs={9}>
            <TableContainer component={Paper}>
              <Table className={classes.disableField} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align="left">
                      <Trans i18nKey="Nombre de sondés">Nombre de sondés</Trans>
                    </TableCell>
                    <TableCell align="left">
                      <Trans i18nKey="Nombre de client / an">
                        Nombre de client / an
                      </Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Trans i18nKey="Nombre de sondés au total">
                        Nombre de sondés au total
                      </Trans>
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        className={classes.underline}
                        margin="normal"
                        type="number"
                        fullWidth
                        id="number_check_total"
                        onChange={handleChangeSurveyedCustomers}
                        name="number_check_total"
                        value={
                          toFixedNumber(rangeState.surveyed_customers, 2) || 0
                        }
                        InputProps={{
                          endAdornment: (
                            <Edit
                              className={classes.iconEdit}
                              onClick={() => 0}
                            >
                              x
                            </Edit>
                          ),
                        }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        margin="normal"
                        disabled
                        type="number"
                        fullWidth
                        id="number_custom_by_year_number_check_total"
                        onChange={handleInputChanged}
                        name="number_custom_by_year_number_check_total"
                        value={
                          toFixedNumber(rangeState.customers_by_year, 2) || 0
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Trans i18nKey="Nombre de choix des sondés dans cette gamme">
                        Nombre de choix des sondés dans cette gamme
                      </Trans>
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        margin="normal"
                        disabled
                        fullWidth
                        type="number"
                        id="number_option_check_range"
                        onChange={handleInputChanged}
                        name="number_option_check_range"
                        value={
                          toFixedNumber(
                            rangeState.count_of_choices_in_survey,
                            2
                          ) || 0
                        }
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        margin="normal"
                        disabled
                        type="number"
                        fullWidth
                        id="number_custom_by_year_number_option_check_range"
                        onChange={handleInputChanged}
                        name="number_custom_by_year_number_option_check_range"
                        value={
                          toFixedNumber(
                            rangeState.customer_choices_in_survey_by_year,
                            2
                          ) || 0
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={3}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.height56}>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Trans i18nKey="Nombre de clients par jour">
                        Nombre de clients par jour
                      </Trans>
                    </TableCell>
                    <TableCell className={classes.width90}>
                      <TextField
                        margin="normal"
                        fullWidth
                        type="number"
                        id="number_custom_by_day"
                        onChange={handleChangeNumberCustomersByDay}
                        name="number_custom_by_day"
                        value={
                          toFixedNumber(rangeState.customers_by_day, 2) || 0
                        }
                        InputProps={{
                          endAdornment: (
                            <Edit
                              className={classes.iconEdit}
                              onClick={() => 0}
                            >
                              x
                            </Edit>
                          ),
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Trans i18nKey="Nombre de jours par an">
                        Nombre de jours par an
                      </Trans>
                    </TableCell>
                    <TableCell className={classes.width90}>
                      <TextField
                        margin="normal"
                        type="number"
                        fullWidth
                        id="number_day_by_year"
                        onChange={handleChangeDaysAYear}
                        name="number_day_by_year"
                        value={toFixedNumber(rangeState.days_a_year, 2) || 0}
                        InputProps={{
                          endAdornment: (
                            <Edit
                              className={classes.iconEdit}
                              onClick={() => 0}
                            >
                              x
                            </Edit>
                          ),
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default PreviewRange;
