// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";

import { Typography, makeStyles, TableCell, TableRow } from "@material-ui/core";
import { Trans } from "react-i18next";
// import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { useSelector } from "react-redux";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";

const useStyles = makeStyles(() => ({
  titleHeaderCell: {
    fontSize: "0.875rem",
  },
  borderRight: {
    borderRight: "solid 2px #1876d2",
  },
}));

const WeeklyIndicator = () => {
  const { register, control, errors } = useForm();
  const adjusted_imt = useSelector(
    (state) => state.cser.computed_cser.adjusted_imt
  );
  const profitability_threshold = useSelector(
    (state) => state.cser.computed_cser.profitability_threshold
  );
  const ser_growth_turnover = useSelector(
    (state) => state.cser.computed_cser.ser_growth_turnover
  );
  const ser_forecast = useSelector(
    (state) => state.cser.computed_cser.ser_forecast
  );

  const classes = useStyles();

  return (
    <>
      <TableRow key="weekly_indicators">
        <TableCell colSpan={11} component="th" scope="row" align="center">
          <Typography
            variant="h6"
            className={classes.titleHeaderCell}
            gutterBottom
            display="inline"
          >
            <Trans i18nKey="Indicateurs hebdomadaires">
              Indicateurs hebdomadaires
            </Trans>
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow key="number_of_customers_by_week">
        <TableCell
          component="th"
          scope="row"
          align="center"
          className={classes.borderRight}
        >
          <Typography variant="h9" gutterBottom display="inline">
            <Trans i18nKey="Nombre de clients/semaine">
              Nombre de clients/semaine
            </Trans>
          </Typography>
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="number_of_customers_by_week_adjsuted_imt"
            control={control}
            errors={errors}
            suffix=""
            value={adjusted_imt.indicators_number_of_customers_by_week}
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderRight} />
        <TableCell align="center" className={classes.borderRight}>
          <FieldWithSuffix
            disabled
            name="number_of_customers_by_week_profitability_threshold"
            control={control}
            errors={errors}
            suffix=""
            value={
              profitability_threshold.indicators_number_of_customers_by_week
            }
            register={register}
          />
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="number_of_customers_by_week_ser_growth_turnover_average_price_stable"
            control={control}
            errors={errors}
            suffix=""
            value={
              ser_growth_turnover.average_price_stable
                .indicators_number_of_customers_by_week
            }
            register={register}
          />
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="number_of_customers_by_week_ser_growth_turnover_occupancy_rate_stable"
            control={control}
            errors={errors}
            suffix=""
            value={
              ser_growth_turnover.occupancy_rate_stable
                .indicators_number_of_customers_by_week
            }
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderRight}>
          <FieldWithSuffix
            disabled
            name="number_of_customers_by_week_ser_growth_turnover_average_price_increase"
            control={control}
            errors={errors}
            suffix=""
            value={
              ser_growth_turnover.average_price_increase
                .indicators_number_of_customers_by_week
            }
            register={register}
          />
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="number_of_customers_by_week_ser_forecast_average_price_stable"
            control={control}
            errors={errors}
            suffix=""
            value={
              ser_forecast.average_price_stable
                .indicators_number_of_customers_by_week
            }
            register={register}
          />
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="number_of_customers_by_week_ser_forecast_occupancy_rate_stable"
            control={control}
            errors={errors}
            suffix=""
            value={
              ser_forecast.occupancy_rate_stable
                .indicators_number_of_customers_by_week
            }
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderRight}>
          <FieldWithSuffix
            disabled
            name="number_of_customers_by_week_ser_forecast_average_price_increase"
            control={control}
            errors={errors}
            suffix=""
            value={
              ser_forecast.average_price_increase
                .indicators_number_of_customers_by_week
            }
            register={register}
          />
        </TableCell>
        <TableCell align="center" />
      </TableRow>
      <TableRow key="gross_margin_in_euros">
        <TableCell
          component="th"
          scope="row"
          align="center"
          className={classes.borderRight}
        >
          <Typography variant="h9" gutterBottom display="inline">
            <Trans i18nKey="Marge Brute  (en €)">Marge Brute (en €)</Trans>
          </Typography>
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="gross_margin_in_euros"
            control={control}
            errors={errors}
            suffix="€"
            value={adjusted_imt.indicators_gross_margin_in_euros}
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderRight} />
        <TableCell align="center" className={classes.borderRight}>
          <FieldWithSuffix
            disabled
            name="gross_margin_in_euros_profitability_threshold"
            control={control}
            errors={errors}
            suffix="€"
            value={profitability_threshold.indicators_gross_margin_in_euros}
            register={register}
          />
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="gross_margin_in_euros_ser_growth_turnover_average_price_stable"
            control={control}
            errors={errors}
            suffix="€"
            value={
              ser_growth_turnover.average_price_stable
                .indicators_gross_margin_in_euros
            }
            register={register}
          />
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="gross_margin_in_euros_ser_growth_turnover_occupancy_rate_stable"
            control={control}
            errors={errors}
            suffix=""
            value=""
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderRight}>
          <FieldWithSuffix
            disabled
            name="gross_margin_in_euros_ser_growth_turnover_average_price_increase"
            control={control}
            errors={errors}
            suffix="€"
            value={
              ser_growth_turnover.average_price_increase
                .indicators_gross_margin_in_euros
            }
            register={register}
          />
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="gross_margin_in_euros_ser_forecast_average_price_stable"
            control={control}
            errors={errors}
            suffix="€"
            value={
              ser_forecast.average_price_stable.indicators_gross_margin_in_euros
            }
            register={register}
          />
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="gross_margin_in_euros_ser_forecast_occupancy_rate_stable"
            control={control}
            errors={errors}
            suffix=""
            value=""
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderRight}>
          <FieldWithSuffix
            disabled
            name="gross_margin_in_euros_ser_forecast_average_price_increase"
            control={control}
            errors={errors}
            suffix="€"
            value={
              ser_forecast.average_price_increase
                .indicators_gross_margin_in_euros
            }
            register={register}
          />
        </TableCell>
        <TableCell align="center" />
      </TableRow>
      <TableRow key="turnover_week">
        <TableCell
          component="th"
          scope="row"
          align="center"
          className={classes.borderRight}
        >
          <Typography variant="h9" gutterBottom display="inline">
            <Trans i18nKey="CAHT (en €)">CAHT (en €)</Trans>
          </Typography>
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="turnover_week"
            control={control}
            errors={errors}
            suffix="€"
            value={adjusted_imt.indicators_turnover_week}
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderRight} />
        <TableCell align="center" className={classes.borderRight}>
          <FieldWithSuffix
            disabled
            name="turnover_week_profitability_threshold"
            control={control}
            errors={errors}
            suffix="€"
            value={profitability_threshold.indicators_turnover_week}
            register={register}
          />
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="turnover_week_ser_growth_turnover_average_price_stable"
            control={control}
            errors={errors}
            suffix="€"
            value={ser_growth_turnover.indicators_turnover_week}
            register={register}
          />
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="turnover_week_ser_growth_turnover_occupancy_rate_stable"
            control={control}
            errors={errors}
            suffix=""
            value=""
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderRight}>
          <FieldWithSuffix
            disabled
            name="turnover_week_ser_growth_turnover_average_price_increase"
            control={control}
            errors={errors}
            suffix=""
            value=""
            register={register}
          />
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="turnover_week_ser_forecast_average_price_stable"
            control={control}
            errors={errors}
            suffix="€"
            value={ser_forecast.indicators_turnover_week}
            register={register}
          />
        </TableCell>
        <TableCell align="center">
          <FieldWithSuffix
            disabled
            name="turnover_week_ser_forecast_occupancy_rate_stable"
            control={control}
            errors={errors}
            suffix=""
            value=""
            register={register}
          />
        </TableCell>
        <TableCell align="center" className={classes.borderRight}>
          <FieldWithSuffix
            disabled
            name="turnover_week_ser_forecast_average_price_increase"
            control={control}
            errors={errors}
            suffix="%"
            value=""
            register={register}
          />
        </TableCell>
        <TableCell align="center" />
      </TableRow>
    </>
  );
};

export default WeeklyIndicator;
