import {
  UPDATE_IMT,
  UPDATE_IMT_ERROR,
  FETCH_SIG,
  FETCH_SIG_ERROR,
  EDITING_IMT,
  FETCH_EXTERNAL_COSTS,
  FETCH_EXTERNAL_COSTS_ERROR,
  ADD_EXTERNAL_COST,
  REMOVE_EXTERNAL_COST,
  POST_EXTERNAl_COST,
  DELETE_EXTERNAL_COST,
  PUT_EXTERNAL_COST,
  PUT_EXTERNAL_COST_ERROR,
  UPDATE_EXTERNAL_COSTS_SUMMARY,
  FETCH_WORKFORCE_COSTS,
  FETCH_WORKFORCE_COSTS_ERROR,
  POST_WORKFORCE_COST,
  UPDATE_WORKFORCE_COSTS_SUMMARY,
  DELETE_WORKFORCE_COST,
  PUT_WORKFORCE_COST,
  PUT_WORKFORCE_COST_ERROR,
  CLEAN_SIG,
} from "../types/sigActionTypes";

const initialState = {
  sig: {},
  loading: false,
  external_costs: [],
  workforce_costs: [],
  external_costs_summary: {
    external_cost_total: 0.0,
    external_cost_total_percentage: 0.0,
    external_cost_total_fixed_part: 0.0,
    external_cost_total_fixed_part_percentage: 0.0,
  },
  workforce_costs_summary: {
    workforce_costs_total: 0.0,
    workforce_costs_total_percentage: 0.0,
    workforce_costs_total_fixed_part: 0.0,
    workforce_costs_total_fixed_part_percentage: 0.0,
  },
  error: {},
};

const sigReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SIG:
      return {
        ...state,
        sig: action.payload,
        loading: false,
      };
    case FETCH_SIG_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UPDATE_IMT:
      return {
        ...state,
        sig: action.payload,
        loading: false,
      };
    case UPDATE_IMT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case EDITING_IMT:
      return {
        ...state,
        sig: action.payload,
        loading: false,
      };
    case FETCH_EXTERNAL_COSTS:
      return {
        ...state,
        external_costs: action.payload,
        loading: false,
      };
    case FETCH_EXTERNAL_COSTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ADD_EXTERNAL_COST:
      return {
        ...state,
        external_costs: [...state.external_costs, action.payload],
        loading: false,
      };
    case REMOVE_EXTERNAL_COST:
      return {
        ...state,
        external_costs: state.external_costs.filter(
          (ec) => ec.id !== action.payload
        ),
        loading: false,
      };
    case POST_EXTERNAl_COST:
      return {
        ...state,
        external_costs: [...state.external_costs, action.payload],
        loading: false,
      };
    case DELETE_EXTERNAL_COST:
      return {
        ...state,
        external_costs: state.external_costs.filter(
          (ec) => ec.uuid !== action.payload
        ),
        loading: false,
      };
    case PUT_EXTERNAL_COST:
      return {
        ...state,
        external_costs: action.payload,
        loading: false,
      };
    case PUT_EXTERNAL_COST_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UPDATE_EXTERNAL_COSTS_SUMMARY:
      return {
        ...state,
        external_costs_summary: action.payload,
      };
    case UPDATE_WORKFORCE_COSTS_SUMMARY:
      return {
        ...state,
        workforce_costs_summary: action.payload,
      };
    case FETCH_WORKFORCE_COSTS:
      return {
        ...state,
        workforce_costs: action.payload,
        loading: false,
      };
    case FETCH_WORKFORCE_COSTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case POST_WORKFORCE_COST:
      return {
        ...state,
        workforce_costs: [...state.workforce_costs, action.payload],
        loading: false,
      };
    case DELETE_WORKFORCE_COST:
      return {
        ...state,
        workforce_costs: state.workforce_costs.filter(
          (ec) => ec.uuid !== action.payload
        ),
        loading: false,
      };
    case PUT_WORKFORCE_COST:
      return {
        ...state,
        workforce_costs: action.payload,
        loading: false,
      };
    case PUT_WORKFORCE_COST_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CLEAN_SIG:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default sigReducer;
