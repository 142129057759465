import { getParsedFloatNumber } from "../range/RangeService";

function toFixedNumber(num, digits, base) {
  const pow = (base || 10) ** digits;
  return Math.round(num * pow) / pow;
}

// !!! CONSUMED MATERIALS !!!

// Retourne Le Montant HT de la consommations de matières premières
export function getConsumptionOfRawMaterials(
  consumedMaterials,
  productionUsed
) {
  return (
    getParsedFloatNumber(consumedMaterials) -
    getParsedFloatNumber(productionUsed)
  );
}

// Retourne Le pourcentage charge variable de la consommations de matières premières
export function getConsumptionOfRawMaterialsPercentageVariableExpenses(
  consumptionOfRawMaterials,
  consumptionOfRawMaterialsAmountVariableExpenses
) {
  return (
    toFixedNumber(
      getParsedFloatNumber(consumptionOfRawMaterialsAmountVariableExpenses) /
        getParsedFloatNumber(consumptionOfRawMaterials),
      2
    ) * 100
  );
}

// Retourne Le Montant charge variable de la consommations de matières premières
export function getConsumptionOfRawMaterialsAmountVariableExpenses(
  consumptionOfRawMaterials,
  consumptionOfRawMaterialsAmountFixedExpenses
) {
  return toFixedNumber(
    getParsedFloatNumber(consumptionOfRawMaterials) -
      getParsedFloatNumber(consumptionOfRawMaterialsAmountFixedExpenses),
    2
  );
}

// Retourne Le pourcentage charge fixe de la consommations de matières premières
export function getConsumptionOfRawMaterialsPercentageFixedExpenses(
  consumptionOfRawMaterials,
  consumptionOfRawMaterialsAmountFixedExpenses
) {
  return (
    toFixedNumber(
      getParsedFloatNumber(consumptionOfRawMaterialsAmountFixedExpenses) /
        getParsedFloatNumber(consumptionOfRawMaterials),
      2
    ) * 100
  );
}

// !!! EXTERNAL COSTS !!!

// Retourne Le pourcentage charge variable des autres achats et charges externes
export function getExternalCostsPercentageVariableExpenses(
  externalCostsTotal,
  externalCostsAmountVariableExpenses
) {
  return (
    toFixedNumber(
      getParsedFloatNumber(externalCostsAmountVariableExpenses) /
        getParsedFloatNumber(externalCostsTotal),
      2
    ) * 100
  );
}

// Retourne Le Montant charge variable des autres achats et charges externes
export function getExternalCostsAmountVariableExpenses(
  externalCostsTotal,
  externalCostTotalFixedPart
) {
  return toFixedNumber(
    getParsedFloatNumber(externalCostsTotal) -
      getParsedFloatNumber(externalCostTotalFixedPart),
    2
  );
}

// Retourne Le pourcentage charge fixe des autres achats et charges externes
export function getExternalCostsPercentageFixedExpenses(
  externalCostsTotal,
  externalCostTotalFixedPart
) {
  return (
    toFixedNumber(
      getParsedFloatNumber(externalCostTotalFixedPart) /
        getParsedFloatNumber(externalCostsTotal),
      2
    ) * 100
  );
}

// !!! WORKFOCER COST !!!

// Retourne Le pourcentage charge variable des charges personnel
export function getWorkforceCostPercentageVariableExpenses(
  workforceTotal,
  workforceCostAmountVariableExpenses
) {
  return (
    toFixedNumber(
      getParsedFloatNumber(workforceCostAmountVariableExpenses) /
        getParsedFloatNumber(workforceTotal),
      2
    ) * 100
  );
}

// Retourne Le Montant charge variable des charges personnel
export function getWorkforceCostAmountVariableExpenses(
  workforceTotal,
  workforceTotalFixedPart
) {
  return toFixedNumber(
    getParsedFloatNumber(workforceTotal) -
      getParsedFloatNumber(workforceTotalFixedPart),
    2
  );
}

// Retourne Le pourcentage charge fixe des charges personnel
export function getWorkforceCostPercentageFixedExpenses(
  workforceTotal,
  workforceTotalFixedPart
) {
  return (
    toFixedNumber(
      getParsedFloatNumber(workforceTotalFixedPart) /
        getParsedFloatNumber(workforceTotal),
      2
    ) * 100
  );
}

// !!! Financial expenses taxes !!!

// Retourne Le Montant HT des charges financières, impôt
export function getFinancialExpensesTaxes(
  taxesDuties,
  otherOperatingExpenses,
  financialResult
) {
  return (
    getParsedFloatNumber(taxesDuties) +
    getParsedFloatNumber(otherOperatingExpenses) -
    getParsedFloatNumber(financialResult)
  );
}

// Retourne Le pourcentage charge variable des charges financières, impôt
export function getFinancialExpensesTaxesPercentageVariableExpenses(
  financialExpensesTaxes,
  financialExpensesAmountVariableExpenses
) {
  return (
    toFixedNumber(
      getParsedFloatNumber(financialExpensesAmountVariableExpenses) /
        getParsedFloatNumber(financialExpensesTaxes),
      2
    ) * 100
  );
}

// Retourne Le Montant charge variable des charges financières, impôt
export function getFinancialExpensesAmountVariableExpenses(
  financialExpensesTaxes,
  financialExpensesTaxesAmountFixedExpenses
) {
  return toFixedNumber(
    getParsedFloatNumber(financialExpensesTaxes) -
      getParsedFloatNumber(financialExpensesTaxesAmountFixedExpenses),
    2
  );
}

// Retourne Le pourcentage charge fixe des charges financières, impôt
export function getFinancialExpensesPercentageFixedExpenses(
  financialExpensesTaxes,
  financialExpensesTaxesAmountFixedExpenses
) {
  return (
    toFixedNumber(
      getParsedFloatNumber(financialExpensesTaxesAmountFixedExpenses) /
        getParsedFloatNumber(financialExpensesTaxes),
      2
    ) * 100
  );
}

// Retourne Le montant charge fixe des charges financières, impôt
export function getFinancialExpensesTaxesAmountFixedExpenses(
  taxesDutiesDevisePartFixed,
  otherOperatingExpensesDevisePartFixed,
  financialResultDevisePartFixed
) {
  return (
    getParsedFloatNumber(taxesDutiesDevisePartFixed) +
    getParsedFloatNumber(otherOperatingExpensesDevisePartFixed) -
    getParsedFloatNumber(financialResultDevisePartFixed)
  );
}
