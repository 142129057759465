/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Helmet from "react-helmet";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";

import { Button as MuiButton, Paper, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Trans } from "react-i18next";
import EmailVerification from "../../components/AuthComponent/EmailVerificationComponent";
import { auth } from "../../services/firebase";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function SignUp() {
  const { handleSubmit, register, errors, watch } = useForm();
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [notificationSignUp, setNotificationSignUp] = useState(null);
  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

  const createUserWithEmailAndPasswordHandler = async (data) => {
    // event.preventDefault();
    try {
      await auth.createUserWithEmailAndPassword(data.email, data.password);
      await auth.currentUser.sendEmailVerification({
        // url: "http://localhost:3000",
        url: `${process.env.REACT_APP_POPSER_DOMAIN}`,
      });

      // generateUserDocument(user, { data });
      // setIsSignIn(true);
      setIsSendEmail(true);

      auth.signOut();
    } catch (error) {
      // setError("Error Signing up with email and password");

      setNotificationSignUp("Erreur Inscription avec e-mail et mot de passe");
    }
  };
  const submitData = (data) => {
    createUserWithEmailAndPasswordHandler(data);
  };

  return (
    <Wrapper>
      {!isSendEmail && (
        <div>
          <Helmet title="Sign Up" />
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Inscription
          </Typography>
          <form onSubmit={handleSubmit(submitData)}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="prenom"
                  label={<Trans i18nKey="firstname">Prénom</Trans>}
                  name="prenom"
                  autoFocus
                  inputRef={register({
                    required: "Required",
                  })}
                  error={"prenom" in errors}
                  helperText={
                    "prenom" in errors ? (
                      <Trans i18nKey="Please enter your firstname">
                        Veuillez entrer votre prénom
                      </Trans>
                    ) : (
                      ""
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="nom"
                  label={<Trans i18nKey="lastname">Nom</Trans>}
                  name="nom"
                  autoFocus
                  inputRef={register({
                    required: "Required",
                  })}
                  error={"nom" in errors}
                  helperText={
                    "nom" in errors ? (
                      <Trans i18nKey="Please enter your lastname">
                        Veuillez entrer votre nom
                      </Trans>
                    ) : (
                      ""
                    )
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  label={<Trans i18nKey="lastname">Adresse email</Trans>}
                  name="email"
                  inputRef={register({
                    required: "Required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    },
                  })}
                  error={"email" in errors}
                  helperText={
                    "email" in errors ? (
                      <Trans i18nKey="Please enter your email">
                        Veuillez entrer votre email
                      </Trans>
                    ) : (
                      ""
                    )
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="password"
                  label={<Trans i18nKey="password">Mot de passe</Trans>}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={"password" in errors}
                  inputRef={register({
                    required: (
                      <Trans i18nKey="Please enter your password">
                        Veuillez entrer votre mot de passe
                      </Trans>
                    ),
                    pattern: {
                      value: strongRegex,
                      message: (
                        <Trans i18nKey="low password">
                          Low password. Must contain 8 characters, a number, a
                          uppercase and lowercase.
                        </Trans>
                      ),
                    },
                  })}
                  helperText={
                    "password" in errors ? <>{errors.password.message}</> : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="password_repeat"
                  label={
                    <Trans i18nKey="password confirmation">
                      Confirmation de mot de passe
                    </Trans>
                  }
                  type="password"
                  id="password_repeat"
                  autoComplete="current-password"
                  error={"password_repeat" in errors}
                  inputRef={register({
                    validate: (value) =>
                      value === watch("password", "") ||
                      "The passwords do not match",
                  })}
                  helperText={
                    "password_repeat" in errors ? (
                      <Trans i18nKey="Password does not match">
                        Le mot de passe ne corresponds pas
                      </Trans>
                    ) : (
                      ""
                    )
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  name="condition"
                  id="condition"
                  control={
                    <Checkbox value="condition" color="primary" required />
                  }
                  label={
                    <Trans i18nKey="conditions of use">
                      J’accepte les conditions d'utilisation.
                    </Trans>
                  }
                />
                <p> </p>
              </Grid>
            </Grid>
            {notificationSignUp && <p>{notificationSignUp}</p>}
            <Button type="submit" fullWidth variant="contained" color="primary">
              <Trans i18nKey="sign up">S'inscrire</Trans>
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Button color="primary" href="/auth/sign-in">
                  <Trans i18nKey="Already have an account">
                    Déjà un compte ? Connectez-vous.
                  </Trans>
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
      {isSendEmail && <EmailVerification setIsSendEmail={setIsSendEmail} />}
    </Wrapper>
  );
}

export default SignUp;
