function toFixedNumber(num, digits, base) {
  const pow = (base || 10) ** digits;
  return Math.round(num * pow) / pow;
}

// Total Chiffre d'affaire HT
export function getTotalSalesRevenueHT(listSalesRevenueHT) {
  let result = 0;
  if (listSalesRevenueHT) {
    if (listSalesRevenueHT.length > 0) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      result = Array.from(listSalesRevenueHT).reduce(reducer);
    }
  }
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Total Nombre de client par an
export function getTotalNumberCustomByYearNumberCheckTotal(
  listNumberCustomByYearNumberCheckTotal
) {
  let result = 0;
  if (listNumberCustomByYearNumberCheckTotal) {
    if (listNumberCustomByYearNumberCheckTotal.length > 0) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      result = Array.from(listNumberCustomByYearNumberCheckTotal).reduce(
        reducer
      );
    }
  }
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Total Nombre de jour par an
export function getTotalNumberDayByYear(listNumberDayByYear) {
  let result = 0;
  if (listNumberDayByYear) {
    if (listNumberDayByYear.length > 0) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      result = Array.from(listNumberDayByYear).reduce(reducer);
    }
  }
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Total Consommation de matériaux HT
export function getTotalMaterialConsumptionHT(listMaterialConsumptionHT) {
  let result = 0;
  if (listMaterialConsumptionHT) {
    if (listMaterialConsumptionHT.length > 0) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      result = Array.from(listMaterialConsumptionHT).reduce(reducer);
    }
  }
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Total Marge
export function getTotalGrossMargin(listGrossMargin) {
  let result = 0;
  if (listGrossMargin) {
    if (listGrossMargin.length > 0) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      result = Array.from(listGrossMargin).reduce(reducer);
    }
  }
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}

// Moyenne Coefficient multiplicateur
export function getAverageRangeMultiplierCoefficient(
  salesRevenueHT,
  materialConsumptionHT
) {
  let result = 0;
  result = toFixedNumber(salesRevenueHT / materialConsumptionHT, 2);
  if (Number.isNaN(Number(result))) {
    return 0;
  }
  return result;
}
