import { USER_ACTION } from "../types/userActionTypes";

const initialState = {
  user_action: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTION:
      return {
        ...state,
        user_action: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
