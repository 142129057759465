import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import {
  Card as MuiCard,
  CardContent,
  Grid,
  Typography,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { useForm } from "react-hook-form";

import { spacing } from "@material-ui/system";

import { useDispatch, useSelector } from "react-redux";
import { updateUserAction } from "../../redux/actions/userActions";
import { editingProjectIntermediateManagementThreshold } from "../../redux/actions/sigActions";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles({
  margin: {
    marginTop: "12px",
  },
  input: {
    width: "90px!important",
  },
  gridInput: {
    borderLeft: "solid 1px",
    paddingLeft: "30px",
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  card: {
    paddingBottom: "0px!important",
  },
});
const GeneralInformation = () => {
  const { register, control, errors } = useForm();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleUserUpdate = (ProjectIntermediateManagementThreshold) => {
    dispatch(
      editingProjectIntermediateManagementThreshold(
        ProjectIntermediateManagementThreshold
      )
    );
    dispatch(updateUserAction(true));
  };
  const sig = useSelector((state) => state.sig.sig);

  return (
    <Card mb={12}>
      <CardContent className={classes.card}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom display="inline">
              <Trans i18nKey="Generals Informations">
                Informations générales
              </Trans>
            </Typography>
            <Divider my={6} mr={24} className={classes.margin} />
          </Grid>

          <Grid item xs={3}>
            <Trans i18nKey="number of days open per year">
              Nombre de jours d&apos;ouverture par an
            </Trans>
          </Grid>
          <Grid item xs={9} className={classes.gridInput}>
            <FieldWithSuffix
              isEdit
              disabled={false}
              name="number_days_open_per_year"
              control={control}
              errors={errors}
              onChangeField={
                (e) =>
                  handleUserUpdate({
                    ...sig,
                    number_days_open_per_year: e.target.value,
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
              suffix={t("")}
              value={sig.number_days_open_per_year}
              register={register}
            />
          </Grid>
          <Grid item xs={3}>
            <Trans i18nKey="number of weeks open per year">
              Nombre de semaines d&apos;ouverture par an
            </Trans>
          </Grid>
          <Grid item xs={9} className={classes.gridInput}>
            <FieldWithSuffix
              isEdit
              disabled={false}
              name="number_weeks_open_per_year"
              control={control}
              errors={errors}
              onChangeField={
                (e) =>
                  handleUserUpdate({
                    ...sig,
                    number_weeks_open_per_year: e.target.value,
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
              suffix={t("")}
              value={sig.number_weeks_open_per_year}
              register={register}
            />
          </Grid>
          <Grid item xs={3}>
            <Trans i18nKey="capacity per day">
              Capacité d&apos;accueil par jour
            </Trans>
          </Grid>
          <Grid item xs={9} className={classes.gridInput}>
            <FieldWithSuffix
              isEdit
              disabled={false}
              name="capacity_per_day"
              control={control}
              errors={errors}
              onChangeField={
                (e) =>
                  handleUserUpdate({
                    ...sig,
                    capacity_per_day: e.target.value,
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
              suffix={t("")}
              value={sig.capacity_per_day}
              register={register}
            />
          </Grid>
          <Grid item xs={3}>
            <Trans i18nKey="average price excl tax">Prix moyen HT</Trans>
          </Grid>
          <Grid item xs={9} className={classes.gridInput}>
            <FieldWithSuffix
              isEdit
              disabled={false}
              name="average_price_excl_tax"
              control={control}
              errors={errors}
              onChangeField={
                (e) =>
                  handleUserUpdate({
                    ...sig,
                    average_price_excl_tax: e.target.value.slice(
                      0,
                      e.target.value.length - 1
                    ),
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
              suffix={t("€")}
              value={sig.average_price_excl_tax}
              register={register}
            />
          </Grid>
          <Grid item xs={3}>
            <Trans i18nKey="number of customers per day">
              Nombre de clients par jour
            </Trans>
          </Grid>
          <Grid item xs={9} className={classes.gridInput}>
            <FieldWithSuffix
              isEdit
              disabled={false}
              name="number_customers_per_day"
              control={control}
              errors={errors}
              onChangeField={
                (e) =>
                  handleUserUpdate({
                    ...sig,
                    number_customers_per_day: e.target.value,
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
              suffix={t("")}
              value={sig.number_customers_per_day}
              register={register}
            />
          </Grid>
          <Grid item xs={3}>
            <Trans i18nKey="number of customers per year">
              Nombre de clients par an
            </Trans>
          </Grid>
          <Grid item xs={9} className={classes.gridInput}>
            <FieldWithSuffix
              isEdit
              disabled={false}
              name="number_customers_per_year"
              control={control}
              errors={errors}
              onChangeField={
                (e) =>
                  handleUserUpdate({
                    ...sig,
                    number_customers_per_year: e.target.value,
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
              suffix={t("")}
              value={sig.number_customers_per_year}
              register={register}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default GeneralInformation;
