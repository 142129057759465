/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { authLayoutRoutes, dashboardLayoutRoutes } from "./index";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";

import DashboardLayout from "../../layouts/Dashboard";
import AuthLayout from "../../layouts/Auth";
import Page404 from "../../pages/auth/Page404";

const generateRoute = (Layout, Component, path, isProtected) => {
  if (isProtected) {
    return (
      <ProtectedRoute
        key={path}
        path={path}
        exact
        layout={Layout}
        component={Component}
      />
    );
  }

  return (
    <Route
      key={path}
      path={path}
      exact
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component, isProtected }) =>
    children
      ? children.map(
          ({ path: childrenPath, component: ChildrenRouteComponent }) =>
            generateRoute(
              Layout,
              ChildrenRouteComponent,
              childrenPath,
              isProtected
            )
        )
      : generateRoute(Layout, Component, path, isProtected)
  );

const DashboardRoute = () => (
  <Router>
    <Switch>
      {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
      {childRoutes(AuthLayout, authLayoutRoutes)}
      <Route exact path="/">
        <Redirect to="/projects" />
      </Route>
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  </Router>
);

export default DashboardRoute;
