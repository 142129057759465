import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  inputList: {
    width: "100%",
    border: "none",
    backgroundColor: "transparent",
  },
  width100: {
    width: "100px",
  },
}));

const FieldAutoCompleted = ({
  nameColumn,
  value,
  listObject,
  updateValue,
  onChangeField,
  tableMeta,
}) => {
  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };
  const classes = useStyles();
  const defaultProps = {
    options: listObject,
    getOptionLabel: (option) => option.name,
  };

  const handleChangeAutoComplete = (event, newValue) => {
    updateValue(newValue.name);
    onChangeField(tableMeta, newValue);
  };
  const handleChangeInput = (event) => {
    updateValue(event.target.value);
    onChangeField(tableMeta, null);
  };
  return (
    <div className={classes.width100}>
      {value ? (
        <input
          type="text"
          id={nameColumn}
          className={classes.inputList}
          name={nameColumn}
          value={value}
          disabled
          onChange={(event) => {
            handleChangeInput(event);
          }}
        />
      ) : (
        <Autocomplete
          {...defaultProps}
          filterOptions={filterOptions}
          className={classes.inputAutoComplete}
          id="autoComplete"
          onChange={(event, newValue) => {
            handleChangeAutoComplete(event, newValue);
          }}
          limitTags={1}
          autoComplete
          value={value}
          includeInputInList
          renderInput={(params) => <TextField {...params} margin="dense" />}
        />
      )}
    </div>
  );
};
FieldAutoCompleted.propTypes = {
  nameColumn: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  listObject: PropTypes.array.isRequired,
  updateValue: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tableMeta: PropTypes.object.isRequired,
};

export default FieldAutoCompleted;
