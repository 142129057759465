export const FETCH_RANGE = "FETCH_RANGE";
export const FETCH_RANGE_SUCCESS = "FETCH_RANGE_SUCCESS";
export const FETCH_RANGE_ERROR = "FETCH_RANGE_EROOR";
export const FETCH_RANGES_SUCCESS = "FETCH_RANGES_SUCCESS";
export const FETCH_RANGES_ERROR = "FETCH_RANGES_EROOR";
export const UPDATE_RANGE = "UPDATE_RANGE";
export const UPDATE_RANGE_SUCCESS = "UPDATE_RANGE";
export const UPDATE_RANGE_ERROR = "UPDATE_RANGE";
export const CLEAR_RANGES = "CLEAR_RANGES";
export const CLEAR_RANGE = "CLEAR_RANGE";

export const CLEAN_RANGE = "CLEAN_RANGE";
