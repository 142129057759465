import React, { useEffect } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import {
  Card as MuiCard,
  CardContent,
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Typography,
  IconButton,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import AddIcon from "@material-ui/icons/Add";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import ExternalCost from "./ExternalCost";
import ExternalCostModel from "../../models/ExternalCostModel";
import {
  postNewExternalCost,
  putExternalCost,
} from "../../redux/actions/sigActions";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";
import {
  getExternalCostTotal,
  getExternalCostTotalFixedPart,
  getExternalCostTotalFixedPartPercentage,
  getExternalCostTotalPercentage,
} from "../../services/external_costs/external_costs_summary";
import { UPDATE_EXTERNAL_COSTS_SUMMARY } from "../../redux/types/sigActionTypes";
import { getIndicatorPercentage } from "../../services/external_costs/external_costs_service";
import { getTotalProduct } from "../../services/sig/SigCalculService";
import AddedValue from "./AddedValue";

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles({
  margin: {
    marginTop: "12px",
  },
  componentSig: {
    marginBottom: "0px!important",
  },
  input: {
    width: "90px!important",
  },
  gridInput: {
    borderLeft: "solid 1px",
    paddingLeft: "30px",
    borderBottom: "solid 1px",
  },
  gridTitle: {
    borderBottom: "solid 1px",
    paddingBottom: "11px",
    paddingTop: "21px",
  },
  tableEqualWidth: {
    "& th": { width: "20%!important" },
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  card: {
    paddingBottom: "0px!important",
  },
  gridColor: {
    backgroundColor: "#1876d121",
  },
  disableField: {
    "& td div": {
      color: "black!important",
      fontWeight: "600",
    },
  },
  buttonStyle: {
    height: "100%",
    padding: "16px",
    background: "transparent",
    width: "100%",
    borderRadius: "0",
  },
});
const ExternalCosts = () => {
  const classes = useStyles();
  const external_costs = useSelector((state) => state.sig.external_costs);
  const sig = useSelector((state) => state.sig.sig);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const currentProject = useSelector((state) => state.projects.currentProject);
  const { register, control, errors } = useForm();
  const { t } = useTranslation();
  const external_costs_summary = useSelector(
    (state) => state.sig.external_costs_summary
  );

  useEffect(async () => {
    await dispatch({
      type: UPDATE_EXTERNAL_COSTS_SUMMARY,
      payload: {
        ...external_costs_summary,
        external_cost_total_percentage: getExternalCostTotalPercentage(
          external_costs_summary.external_cost_total,
          sig
        ),
      },
    });
    // Pour chaque cout externe, modifier le pourcentage de l'indicateur
    const external_costs_copy = [...external_costs];
    external_costs_copy.map(async (ec) => {
      const indicator_percentage = getIndicatorPercentage(
        ec.indicator,
        getTotalProduct(sig)
      );
      await dispatch(
        putExternalCost(
          {
            ...ec,
            indicator_percentage,
          },
          external_costs,
          user.accessToken
        )
      );
    });
  }, [sig.net_turnover_excl_tax, sig.others_products]);

  useEffect(() => {
    if (external_costs.length > 0) {
      const external_cost_total = getExternalCostTotal(external_costs);
      dispatch({
        type: UPDATE_EXTERNAL_COSTS_SUMMARY,
        payload: {
          external_cost_total,
          external_cost_total_percentage: getExternalCostTotalPercentage(
            external_cost_total,
            sig
          ),
          external_cost_total_fixed_part: getExternalCostTotalFixedPart(
            external_costs
          ),
          external_cost_total_fixed_part_percentage: getExternalCostTotalFixedPartPercentage(
            external_costs
          ),
        },
      });
    }
  }, [external_costs.length]);

  const add_external_costs = () => {
    const externalCost = new ExternalCostModel();
    externalCost.line_name = "Nouvelle Charge Externe";
    externalCost.project = currentProject.id;
    externalCost.intermediate_management_threshold = sig.id;

    dispatch(postNewExternalCost(externalCost, user.accessToken));
  };

  return (
    <>
      <Card mb={12} className={classes.componentSig}>
        <CardContent className={classes.card}>
          <TableContainer component={Paper}>
            <Table
              className={[classes.table, classes.tableEqualWidth]}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="right">
                    {" "}
                    <Trans i18nKey="Amount money">Montant en (en €) </Trans>
                  </TableCell>
                  <TableCell align="right">%</TableCell>
                  <TableCell align="right">
                    <Trans>Part fixe (en €)</Trans>
                  </TableCell>
                  <TableCell align="right">
                    <Trans>Part fixe (en %)</Trans>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key="total_product"
                  className={[classes.gridColor, classes.disableField]}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant="h5" gutterBottom display="inline">
                      <Trans i18nKey="External Costs">Charges externes</Trans>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      disabled
                      name="external_cost_total"
                      control={control}
                      errors={errors}
                      suffix={t("€")}
                      value={external_costs_summary.external_cost_total}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      disabled
                      name="external_cost_total_percentage"
                      control={control}
                      errors={errors}
                      suffix={t("%")}
                      value={
                        external_costs_summary.external_cost_total_percentage
                      }
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      disabled
                      name="external_cost_total_fixed_part"
                      control={control}
                      errors={errors}
                      suffix={t("€")}
                      value={
                        external_costs_summary.external_cost_total_fixed_part
                      }
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      disabled
                      name="external_cost_total_fixed_part_percentage"
                      control={control}
                      errors={errors}
                      suffix={t("%")}
                      value={
                        external_costs_summary.external_cost_total_fixed_part_percentage
                      }
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right" />
                </TableRow>
                {external_costs.map((external_cost) => {
                  return (
                    <ExternalCost
                      external_cost_uuid={external_cost.uuid}
                      key={external_cost.uuid}
                    />
                  );
                })}
                <TableRow key="add_external_cost">
                  <TableCell colspan={6} component="th" scope="row">
                    <Tooltip
                      disableFocusListener
                      title={t("Ajouter une charge externe")}
                    >
                      <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={add_external_costs}
                        className={classes.buttonStyle}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <AddedValue />
    </>
  );
};

export default ExternalCosts;
