import { getParsedFloatNumber } from "./RangeService";

export const getValue = (currentColumn, targetColumnName, value, row) => {
  if (currentColumn === targetColumnName) {
    if (value !== null) {
      return value;
    }
  }
  return row[targetColumnName];
};

export const copyProductToRangeProduct = (product, rangeProduct) => {
  return {
    ...rangeProduct,
    vat: product.tva_rate,
    selling_price_vat_excl: product.selling_price_ht,
    selling_price_vat_incl: product.ttc_price,
    raw_material_cost_vat_excl: product.per_unit_price,
  };
};

function toFixedNumber(num, digits, base) {
  const pow = (base || 10) ** digits;
  return Math.round(num * pow) / pow;
}

export const getSellingPriceVatExcl = (sellingPriceVatIncl, vatRate) => {
  const parsedSellingPriceVatIncl = parseFloat(sellingPriceVatIncl);
  const parsedVatRate = parseFloat(vatRate);
  if (parsedSellingPriceVatIncl.isNaN || parsedVatRate.isNaN) {
    return 0;
  }

  return toFixedNumber(
    parsedSellingPriceVatIncl / (1 + parsedVatRate / 100),
    2
  );
};

export const getQuantiSoldFromSurvey = (
  poll_quantity,
  customer_choices_in_survey_by_year,
  count_of_choices_in_survey
) => {
  const parsed_poll_quantity = toFixedNumber(
    getParsedFloatNumber(poll_quantity),
    2
  );
  const parsed_customer_choices_in_survey_by_year = toFixedNumber(
    getParsedFloatNumber(customer_choices_in_survey_by_year),
    2
  );
  const parsed_count_of_choices_in_survey = toFixedNumber(
    getParsedFloatNumber(count_of_choices_in_survey),
    2
  );
  return toFixedNumber(
    getParsedFloatNumber(
      (parsed_poll_quantity * parsed_customer_choices_in_survey_by_year) /
        parsed_count_of_choices_in_survey
    ),
    2
  );
};

export default getValue;
