import {
  UPDATE_TECHNICAL_SHEET,
  UPDATE_TECHNICAL_SHEET_ERROR,
  FETCH_TECHNICAL_SHEET,
  FETCH_TECHNICAL_SHEET_ERROR,
  CLEAR_TECHNICAL_SHEET,
  CLEAN_TECHNICAL_SHEET,
} from "../types/technicalSheetActionTypes";

const initialState = {
  technicalSheet: {
    listTotalPrice: {
      value: [],
      loading: true,
    },
    materialCosts: {
      value: 0,
      loading: true,
    },
    extraCharge: {
      value: 0,
      loading: true,
    },
    totalMaterialCost: {
      value: 0,
      loading: true,
    },
    totalPricePortion: {
      value: 0,
      loading: true,
    },
    totalEnergy: {
      value: 0,
      loading: true,
    },
    perUnitEnergy: {
      value: 0,
      loading: true,
    },
    multiplyingFactor: {
      isInput: false,
      value: 0,
      loading: true,
    },
    sellingPriceHT: {
      value: 0,
      loading: true,
    },
    tva: {
      value: 0,
      loading: true,
    },
    tvaRate: {
      isInput: false,
      value: 0,
      loading: true,
    },
    sellingPriceTTC: {
      isInput: false,
      value: 0,
      loading: true,
    },
    loading: true,
  },
};

const technicalSheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TECHNICAL_SHEET:
      return {
        ...state,
        technicalSheet: action.payload,
      };
    case UPDATE_TECHNICAL_SHEET_ERROR:
      return {
        ...state,
        technicalSheet: action.payload,
      };
    case FETCH_TECHNICAL_SHEET:
      return {
        ...state,
        technicalSheet: {
          ...state.technicalSheet,
          ...action.payload,
        },
      };
    case FETCH_TECHNICAL_SHEET_ERROR:
      return {
        ...state,
      };
    case CLEAR_TECHNICAL_SHEET:
      return {
        ...state,
        technicalSheet: action.payload,
      };
    case CLEAN_TECHNICAL_SHEET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default technicalSheetReducer;
