import { SIG_URL, EXTERNAL_COSTS_URL } from "../../services/constants/urls";
import {
  FETCH_SIG,
  FETCH_SIG_ERROR,
  UPDATE_IMT,
  UPDATE_IMT_ERROR,
  EDITING_IMT,
  FETCH_EXTERNAL_COSTS,
  FETCH_EXTERNAL_COSTS_ERROR,
  POST_EXTERNAl_COST,
  POST_EXTERNAl_COST_ERROR,
  DELETE_EXTERNAL_COST,
  DELETE_EXTERNAL_COST_ERROR,
  PUT_EXTERNAL_COST,
  PUT_EXTERNAL_COST_ERROR,
  FETCH_WORKFORCE_COSTS,
  FETCH_WORKFORCE_COSTS_ERROR,
  POST_WORKFORCE_COST,
  POST_WORKFORCE_COST_ERROR,
  PUT_WORKFORCE_COST,
  PUT_WORKFORCE_COST_ERROR,
  DELETE_WORKFORCE_COST,
  DELETE_WORKFORCE_COST_ERROR,
} from "../types/sigActionTypes";
import apiClient from "../../services/api/apiClient";

const getHeaders = (accessToken) => ({
  "Content-Type": `application/json;`,
  Authorization: `Bearer ${accessToken}`,
});

export const fetchExternalCosts = (project_id, accessToken, costType) => async (
  dispatch
) => {
  const url = `${EXTERNAL_COSTS_URL}?project_id=${project_id}&cost_type=${costType}`;
  try {
    const res = await apiClient({
      method: "get",
      url,
      headers: getHeaders(accessToken),
    });
    if (res.data.length > 0) {
      dispatch({
        type: FETCH_EXTERNAL_COSTS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: FETCH_EXTERNAL_COSTS,
        payload: [],
      });
    }
  } catch (e) {
    dispatch({
      type: FETCH_EXTERNAL_COSTS_ERROR,
      payload: { error: e },
    });
  }
};

export const fetchWorkforceCosts = (
  project_id,
  accessToken,
  costType
) => async (dispatch) => {
  const url = `${EXTERNAL_COSTS_URL}?project_id=${project_id}&cost_type=${costType}`;
  try {
    const res = await apiClient({
      method: "get",
      url,
      headers: getHeaders(accessToken),
    });
    if (res.data.length > 0) {
      dispatch({
        type: FETCH_WORKFORCE_COSTS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: FETCH_WORKFORCE_COSTS,
        payload: [],
      });
    }
  } catch (e) {
    dispatch({
      type: FETCH_WORKFORCE_COSTS_ERROR,
      payload: { error: e },
    });
  }
};

export const fetchSig = (project_id, accessToken) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${SIG_URL}?project_id=${project_id}`;
  try {
    const res = await apiClient({ method: "get", url, headers });
    if (res.data.length > 0) {
      dispatch({
        type: FETCH_SIG,
        payload: res.data[0],
      });
    } else {
      dispatch({
        type: FETCH_SIG,
        payload: {},
      });
    }
  } catch (e) {
    dispatch({
      type: FETCH_SIG_ERROR,
      payload: { error: e },
    });
  }
};

export const updateProjectIntermediateManagementThreshold = (
  imtInformations,
  accessToken
) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };

  const url = `${SIG_URL}${imtInformations.uuid}/`;
  try {
    const res = await apiClient({
      method: "put",
      data: imtInformations,
      url,
      headers,
    });
    dispatch({
      type: UPDATE_IMT,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: UPDATE_IMT_ERROR,
      payload: { error: e },
    });
  }
};

export const editingProjectIntermediateManagementThreshold = (
  imtInformations
) => async (dispatch) => {
  dispatch({
    type: EDITING_IMT,
    payload: imtInformations,
  });
};

export const postNewExternalCost = (externalCost, accessToken) => async (
  dispatch
) => {
  const url = `${EXTERNAL_COSTS_URL}`;
  try {
    const res = await apiClient({
      method: "post",
      data: externalCost,
      url,
      headers: getHeaders(accessToken),
    });
    dispatch({
      type: POST_EXTERNAl_COST,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: POST_EXTERNAl_COST_ERROR,
      payload: { error: e },
    });
  }
};

export const postNewWorkforceCost = (externalCost, accessToken) => async (
  dispatch
) => {
  const url = `${EXTERNAL_COSTS_URL}`;
  try {
    const res = await apiClient({
      method: "post",
      data: externalCost,
      url,
      headers: getHeaders(accessToken),
    });
    dispatch({
      type: POST_WORKFORCE_COST,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: POST_WORKFORCE_COST_ERROR,
      payload: { error: e },
    });
  }
};
export const putExternalCost = (
  externalCost,
  external_costs_state,
  accessToken
) => async (dispatch) => {
  const url = `${EXTERNAL_COSTS_URL}${externalCost.uuid}/`;
  try {
    const res = await apiClient({
      method: "put",
      data: externalCost,
      url,
      headers: getHeaders(accessToken),
    });

    const index = external_costs_state.findIndex(
      (ec) => ec.uuid === res.data.uuid
    );
    external_costs_state.splice(index, 1, res.data);
    dispatch({
      type: PUT_EXTERNAL_COST,
      payload: external_costs_state,
    });
  } catch (e) {
    dispatch({
      type: PUT_EXTERNAL_COST_ERROR,
      payload: { error: e },
    });
  }
};

export const putWorkforceCost = (
  workforce_cost,
  workforce_costs,
  accessToken
) => async (dispatch) => {
  const url = `${EXTERNAL_COSTS_URL}${workforce_cost.uuid}/`;
  try {
    const res = await apiClient({
      method: "put",
      data: workforce_cost,
      url,
      headers: getHeaders(accessToken),
    });

    const index = workforce_costs.findIndex((ec) => ec.uuid === res.data.uuid);
    workforce_costs.splice(index, 1, res.data);
    dispatch({
      type: PUT_WORKFORCE_COST,
      payload: workforce_costs,
    });
  } catch (e) {
    dispatch({
      type: PUT_WORKFORCE_COST_ERROR,
      payload: { error: e },
    });
  }
};

export const deleteExternalCost = (externalCostUuid, accessToken) => async (
  dispatch
) => {
  const url = `${EXTERNAL_COSTS_URL}${externalCostUuid}`;
  try {
    await apiClient({
      method: "delete",
      url,
      headers: getHeaders(accessToken),
    });
    dispatch({
      type: DELETE_EXTERNAL_COST,
      payload: externalCostUuid,
    });
  } catch (e) {
    dispatch({
      type: DELETE_EXTERNAL_COST_ERROR,
      payload: { error: e },
    });
  }
};

export const deleteWorkforceCost = (externalCostUuid, accessToken) => async (
  dispatch
) => {
  const url = `${EXTERNAL_COSTS_URL}${externalCostUuid}`;
  try {
    await apiClient({
      method: "delete",
      url,
      headers: getHeaders(accessToken),
    });
    dispatch({
      type: DELETE_WORKFORCE_COST,
      payload: externalCostUuid,
    });
  } catch (e) {
    dispatch({
      type: DELETE_WORKFORCE_COST_ERROR,
      payload: { error: e },
    });
  }
};

export default fetchSig;
