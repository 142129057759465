import React from "react";
import { Trans } from "react-i18next";
import {
  Typography,
  makeStyles,
  Modal,
  Fade,
  Backdrop,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Divider,
} from "@material-ui/core";
import PropTypes from "prop-types";
import ShareIcon from "@material-ui/icons/Share";
import { useForm } from "react-hook-form";
import { Delete as DeleteIcon, Add as AddIcon } from "@material-ui/icons";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "5px",
    border: "solid",
    width: "400px",
  },
  floatRight: {
    float: "right",
  },
  width100: {
    width: "100%",
  },
  emailUserRole: {
    paddingTop: "23px!important",
  },
  margin12: {
    marginTop: "12px",
  },
  blueBackground: {
    marginTop: "12px",
    marginBottom: "12px",
    backgroundColor: "#1975d1",
  },
});
const ShareProject = ({ setOpenShareProjectModal, openShareProjectModal }) => {
  const classes = useStyles();
  const { register, errors } = useForm();
  const [role, setRole] = React.useState("");
  const [roleUser, setRoleUser] = React.useState("Editeur");

  const handleChange = (event) => {
    setRole(event.target.value);
  };
  const handleClose = () => {
    setOpenShareProjectModal(false);
  };
  const handleChangeRoleUser = (event) => {
    if (event.target.value === "Supprimer") {
      handleClose();
    } else {
      setRoleUser(event.target.value);
    }
  };

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={classes.modal}
      open={openShareProjectModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openShareProjectModal} className={classes.paper}>
        <div className={classes.paper}>
          <Typography variant="h5" gutterBottom display="inline">
            <Trans i18nKey="partager avec des personnes">
              Partager avec des personnes
            </Trans>
          </Typography>
          <ShareIcon className={classes.floatRight} />
          <Grid container spacing={1} className={classes.margin12}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="email"
                label={<Trans i18nKey="lastname">Adresse email</Trans>}
                name="email"
                inputRef={register({
                  required: "Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  },
                })}
                error={"email" in errors}
                helperText={
                  "email" in errors ? (
                    <Trans i18nKey="Please enter your email">
                      Veuillez entrer votre email
                    </Trans>
                  ) : (
                    ""
                  )
                }
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="standard" className={classes.width100}>
                <InputLabel id="demo-simple-select-standard-label">
                  <Trans i18nKey="Rôle">Rôle</Trans>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={role}
                  onChange={handleChange}
                  label="Role"
                >
                  <MenuItem value="Editeur">Editeur</MenuItem>
                  <MenuItem value="Superviseur">Superviseur</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="delete">
                <AddIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Divider my={9} className={classes.blueBackground} />
            </Grid>
            <Grid item xs={9} className={classes.emailUserRole}>
              Clemgirel@gmail.com
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="standard" className={classes.width100}>
                <InputLabel id="demo-simple-select-standard-label">
                  <Trans i18nKey="Rôle">Rôle</Trans>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={roleUser}
                  onChange={handleChangeRoleUser}
                  label="roleUser"
                >
                  <MenuItem value="Editeur">Editeur</MenuItem>
                  <MenuItem value="Superviseur">Superviseur</MenuItem>
                  <Divider my={9} />
                  <MenuItem value="Supprimer">
                    <DeleteIcon />
                    Supprimer
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

ShareProject.propTypes = {
  setOpenShareProjectModal: PropTypes.func.isRequired,
  openShareProjectModal: PropTypes.bool.isRequired,
};
export default ShareProject;
