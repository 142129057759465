import {
  getListSalesRevenueHT,
  getListGrossMargin,
  getListMaterialConsumptionHT,
  getListNumberCustomByYearNumberCheckTotal,
  getListNumberDayByYear,
  getToFixedNumber,
} from "./OfferRangeMethodService";
import {
  getTotalSalesRevenueHT,
  getAverageRangeMultiplierCoefficient,
  getTotalGrossMargin,
  getTotalMaterialConsumptionHT,
  getTotalNumberCustomByYearNumberCheckTotal,
  getTotalNumberDayByYear,
} from "./OfferRangeCalculService";
import { getAverageMaterialRatio } from "../range/RangeTurnoverCalculService";

export const addOfferRangeInteraction = async (rangeOffers) => {
  const listSalesRevenueHT = getListSalesRevenueHT(rangeOffers);
  const listNumberDayByYear = getListNumberDayByYear(rangeOffers);
  const listGrossMargin = getListGrossMargin(rangeOffers);

  const listMaterialConsumptionHT = getListMaterialConsumptionHT(rangeOffers);
  const listNumberCustomByYearNumberCheckTotal = getListNumberCustomByYearNumberCheckTotal(
    rangeOffers
  );

  const sales_revenue_HT = getTotalSalesRevenueHT(listSalesRevenueHT);
  const number_custom_by_year_number_check_total = getTotalNumberCustomByYearNumberCheckTotal(
    listNumberCustomByYearNumberCheckTotal
  );
  const number_day_by_year = getTotalNumberDayByYear(listNumberDayByYear);
  const material_consumption_HT = getTotalMaterialConsumptionHT(
    listMaterialConsumptionHT
  );
  const gross_margin = getTotalGrossMargin(listGrossMargin);
  const range_multiplier_coefficient = getAverageRangeMultiplierCoefficient(
    getToFixedNumber(sales_revenue_HT, 2),
    getToFixedNumber(material_consumption_HT, 2)
  );
  const average_material_ratio = getAverageMaterialRatio(
    getToFixedNumber(range_multiplier_coefficient, 2)
  );

  return {
    range: {
      id: 0,
      name: "Total Gamme étudiées",
      period: null,
      number_custom_by_year_number_check_total: getToFixedNumber(
        number_custom_by_year_number_check_total,
        2
      ),
      number_day_by_year: getToFixedNumber(number_day_by_year, 2),
      sales_revenue_HT: getToFixedNumber(sales_revenue_HT, 2),
      material_consumption_HT: getToFixedNumber(material_consumption_HT, 2),
      gross_margin: getToFixedNumber(gross_margin, 2),
      range_multiplier_coefficient: getToFixedNumber(
        range_multiplier_coefficient,
        2
      ),
      average_material_ratio: getToFixedNumber(average_material_ratio, 2),
    },
  };
};

export default addOfferRangeInteraction;
