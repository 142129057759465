/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styled, { withTheme } from "styled-components";
import { Redirect } from "react-router-dom";
import {
  Grid,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Menu as MenuIcon } from "@material-ui/icons";
import Comment from "@material-ui/icons/Comment";

import { Power } from "react-feather";
import { auth } from "../services/firebase";
import ProjectSelect from "./HeaderComponents/ProjectSelect";
import {
  show_comments,
  hide_comments,
} from "../redux/actions/managerFeedbackAction";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: white;
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
    color: white;
  }
`;
const ToolbarTitle = styled.div`
  min-width: 150px;
`;
function UserMenu() {
  const [anchorMenu, setAnchorMenu] = useState(null);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };
  const signOut = () => {
    auth.signOut().then(() => {
      return <Redirect to="/auth/sign-in" />;
    });
  };
  return (
    <>
      <IconButton
        aria-owns={anchorMenu ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Power />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={closeMenu}>Profil</MenuItem>
        <MenuItem onClick={signOut}>Déconnexion</MenuItem>
      </Menu>
    </>
  );
}

const Header = ({ onDrawerToggle }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const showComments = useSelector(
    (state) => state.managerFeedback.show_comments
  );

  const handleShowManagerFeedback = () => {
    if (showComments.size_comments_screen === 0) {
      dispatch(show_comments());
    } else {
      dispatch(hide_comments());
    }
  };

  return (
    <>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <ProjectSelect />
            </Grid>
            <Grid item xs />
            <Grid item>
              <Tooltip
                disableFocusListener
                title={t("Afficher les commentaires du professeur")}
              >
                <Toolbar>
                  <ToolbarTitle>
                    <IconButton
                      size="medium"
                      color="white"
                      onClick={handleShowManagerFeedback}
                    >
                      <Comment fontSizeSmall="small" />
                    </IconButton>
                  </ToolbarTitle>
                </Toolbar>
              </Tooltip>
            </Grid>
            <Grid item>
              <UserMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default withTheme(Header);
