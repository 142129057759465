import React, { useState } from "react";
import styled from "styled-components";

import {
  Typography,
  makeStyles,
  Grid,
  Card as MuiCard,
  CardContent,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { spacing } from "@material-ui/system";
import { useSelector } from "react-redux";
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import NewVariableCostsRate from "./NewVariableCostsRate";

import FieldWithSuffix from "../ElementsField/FieldWithSuffix";
import {
  handleChangeFixedCosts,
  handleChangeLoanRepaymentAdjustedImt,
  handleChangeLoanRepaymentProfitabilityThreshold,
  handleChangeLoanRepaymentSerCoeff,
  handleChangeLoanRepaymentSerForecast,
  handleChangeLoanRepaymentsForSerGrowthTurnover,
  handleChangeNetSurplusForSerGrowthTurnover,
  handleChangePercentageOfChangeInRevenues,
  handleChangePercentageOfVariableLoadRateForSerForecast,
} from "../../services/cser/cserInteractions";

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  componentSimulationTable: {
    "& input": {
      textAlign: "center",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  margin: {
    marginTop: "24px",
    marginBottom: "24px",
  },
  tableEqualWidth: {
    "& th": {},
  },
  disableField: {
    "& td div": {
      color: "black!important",
    },
  },
  inline: {
    "& div": {
      display: "inline-grid",
    },
  },
  noPaddingThTd: {
    "& th": {
      padding: "5px",
    },
    "& td": {
      padding: "5px",
    },
    ".MuiInput-underline:before": {
      borderBottom: "none!important",
    },
  },
  width100: {
    width: "100%",
  },
  width80: {
    width: "80%",
  },
  borderRight: {
    borderRight: "solid 2px #1876d2",
  },
  taxTierComponent: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
  },
  variableCost: {
    position: "relative",
  },
  settingsIcons: {
    width: "20px!important",
  },
}));

const SimulationTable = () => {
  const { register, control, errors } = useForm();
  const { t } = useTranslation();
  const adjusted_imt = useSelector(
    (state) => state.cser.computed_cser.adjusted_imt
  );
  const [openModal, setOpenModal] = useState(false);

  const profitability_threshold = useSelector(
    (state) => state.cser.computed_cser.profitability_threshold
  );
  const ser_growth_turnover = useSelector(
    (state) => state.cser.computed_cser.ser_growth_turnover
  );
  const { ser_forecast } = useSelector((state) => state.cser.computed_cser);
  const ser_coeff = useSelector((state) => state.cser.computed_cser.ser_coeff);
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Card mb={12} className={classes.componentSimulationTable}>
            <CardContent className={classes.card}>
              <TableContainer component={Paper}>
                <Table
                  className={
                    ([classes.table, classes.tableEqualWidth],
                    classes.disableField,
                    classes.noPaddingThTd)
                  }
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.borderRight}
                      />
                      <TableCell align="center">
                        <Trans i18nKey="SIG retraité">SIG retraité</Trans>
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <Trans i18nKey="%">%</Trans>
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <Trans i18nKey="Seuil de rentabilité (en €)">
                          Seuil de rentabilité (en €)
                        </Trans>
                      </TableCell>
                      <Tooltip
                        disableFocusListener
                        title={t("Avec la seule croissance du CA HT")}
                      >
                        <TableCell
                          align="center"
                          className={classes.borderRight}
                        >
                          <Trans i18nKey="Simulation du Seuil d'Excédent Recherché (SER)">
                            Simulation du Seuil d&apos;Excédent Recherché (SER)
                          </Trans>
                        </TableCell>
                      </Tooltip>
                      <Tooltip
                        disableFocusListener
                        title={t(
                          "Variation du CA (en %), du Taux de CV (en %) et des Charges Fixes (en €)"
                        )}
                      >
                        <TableCell
                          align="center"
                          className={classes.borderRight}
                        >
                          <Trans i18nKey="Variations estimées">
                            Variations estimées
                          </Trans>
                        </TableCell>
                      </Tooltip>
                      <Tooltip
                        disableFocusListener
                        title={t(
                          "Seuil d'Excédent Recherché en fonction de la Variation du CA (en %), du Taux de CV (en %) et des Charges Fixes (en €)"
                        )}
                      >
                        <TableCell
                          align="center"
                          className={classes.borderRight}
                        >
                          <Trans i18nKey="Prévisionnel (SER)">
                            Prévisionnel (SER)
                          </Trans>
                        </TableCell>
                      </Tooltip>
                      <Tooltip
                        disableFocusListener
                        title={t(
                          "En fonction du coefficient multiplicateur estimé"
                        )}
                      >
                        <TableCell align="center">
                          <Trans i18nKey="Simulation SER">Simulation SER</Trans>
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.paddingThTd}>
                    <TableRow key="empty" />
                    <TableRow key="ca_ht">
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.borderRight}
                      >
                        <Typography variant="h9" gutterBottom display="inline">
                          <Trans i18nKey="CA HT">CA HT</Trans>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="turnover_before_tax_adjusted_imt"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={adjusted_imt.simulation_turnover_before_tax}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="turnover_before_tax_adjusted_imt_percentage"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={
                            adjusted_imt.simulation_turnover_before_tax_percentage
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="turnover_before_tax_profitability_threshold"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            profitability_threshold.simulation_turnover_before_tax
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell
                        className={[classes.inline, classes.borderRight]}
                        align="center"
                      >
                        <FieldWithSuffix
                          disabled
                          name="turnover_before_tax_ser_growth_turnover"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            ser_growth_turnover.simulation_turnover_before_tax
                          }
                          register={register}
                        />
                        <FieldWithSuffix
                          disabled
                          name="turnover_before_tax_percentage_ser_growth_turnover"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={
                            ser_growth_turnover.simulation_percentage_of_change_in_revenues
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          isEdit
                          name="turnover_before_tax_percentage_of_change_in_revenues"
                          control={control}
                          classesProps={classes.width100}
                          errors={errors}
                          suffix={t("%")}
                          value={
                            ser_forecast.simulation_percentage_of_change_in_revenues
                          }
                          onChangeField={
                            handleChangePercentageOfChangeInRevenues
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="turnover_before_tax_ser_forecast"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_forecast.simulation_turnover_before_tax}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="turnover_before_tax_ser_coeff"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_coeff.simulation_turnover_before_tax}
                          register={register}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="variable_expenses">
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.borderRight}
                      >
                        <Typography variant="h9" gutterBottom display="inline">
                          <Trans i18nKey="(-)Charges Variables">
                            (-)Charges Variables
                          </Trans>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="variable_costs_adjsuted_imt"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={adjusted_imt.simulation_variable_costs}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="variable_costs_adjsuted_imt_percentage"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={
                            adjusted_imt.simulation_variable_costs_percentage
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="variable_costs_profitability_threshold"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            profitability_threshold.simulation_variable_costs
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="variable_costs_ser_growth_turnover"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_growth_turnover.simulation_variable_costs}
                          register={register}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        className={[classes.variableCost, classes.borderRight]}
                      >
                        <Tooltip
                          disableFocusListener
                          title={t("Calculez votre taux de charge variable")}
                        >
                          <IconButton
                            className={classes.taxTierComponent}
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={() => setOpenModal(true)}
                          >
                            <SettingsIcon
                              fontSizeSmall="small"
                              className={classes.settingsIcons}
                            />
                          </IconButton>
                        </Tooltip>
                        <FieldWithSuffix
                          isEdit
                          name="variable_costs_percentage_of_variable_load_rate"
                          control={control}
                          classesProps={classes.width80}
                          errors={errors}
                          suffix={t("%")}
                          value={
                            ser_forecast.simulation_percentge_of_variable_load_rate
                          }
                          onChangeField={
                            handleChangePercentageOfVariableLoadRateForSerForecast
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="variable_costs_ser_forecast"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_forecast.simulation_variable_costs}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="variable_costs_ser_coeff"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_coeff.simulation_variable_costs}
                          register={register}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="margin_on_variable_expenses">
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.borderRight}
                      >
                        <Typography variant="h9" gutterBottom display="inline">
                          <Trans i18nKey="(=) Marge sur Charges Variables">
                            (=) Marge sur Charges Variables
                          </Trans>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="margin_on_variable_costs_adjsuted_imt"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            adjusted_imt.simulation_margin_on_variable_costs
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="margin_on_variable_costs_adjsuted_imt_percentage"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={
                            adjusted_imt.simulation_margin_on_variable_costs_percentage
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="margin_on_variable_costs_profitability_threshold"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            profitability_threshold.simulation_margin_on_variable_costs
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="margin_on_variable_costs_ser_growth_turnover"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            ser_growth_turnover.simulation_margin_on_variable_costs
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="margin_on_variable_costs_percentage"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={
                            ser_forecast.simulation_margin_on_variable_costs_percentage
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="margin_on_variable_costs_ser_forecast"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            ser_forecast.simulation_margin_on_variable_costs
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="margin_on_variable_costs_ser_coeff"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_coeff.simulation_margin_on_variable_costs}
                          register={register}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="gross_margin">
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.borderRight}
                      >
                        <Typography variant="h9" gutterBottom display="inline">
                          <Trans i18nKey="(-)Charges fixes">
                            (-)Charges fixes
                          </Trans>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="fixed_costs_adjsuted_imt"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={adjusted_imt.simulation_fixed_costs}
                          register={register}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.borderRight}
                      />
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="fixed_costs_profitability_threshold"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={profitability_threshold.simulation_fixed_costs}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="fixed_costs_ser_growth_turnover"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_growth_turnover.simulation_fixed_costs}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          isEdit
                          name="fixed_costs_variation_in_euros"
                          control={control}
                          classesProps={classes.width100}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            ser_forecast.simulation_fixed_costs_variation_in_euros
                          }
                          onChangeField={handleChangeFixedCosts}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="fixed_costs_ser_forecast"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_forecast.simulation_fixed_costs}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="fixed_costs_ser_coeff"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_coeff.simulation_fixed_costs}
                          register={register}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="current_income_before_taxes">
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.borderRight}
                      >
                        <Typography variant="h9" gutterBottom display="inline">
                          <Trans i18nKey="(=)current income before taxes">
                            (=)Résultat Courant Avant Impôt RCAI
                          </Trans>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="current_income_before_taxes_adjsuted_imt"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            adjusted_imt.simulation_current_income_before_taxes
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="current_income_before_taxes_adjsuted_imt_percentage"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={
                            adjusted_imt.simulation_current_income_before_taxes_percentage
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="current_income_before_taxes_profitability_threshold"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value="0"
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="current_income_before_taxes_ser_growth_turnover"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            ser_growth_turnover.simulation_current_income_before_taxes
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.borderRight}
                      />
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="current_income_before_taxes_ser_forecast"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            ser_forecast.simulation_current_income_before_taxes
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="current_income_before_taxes_ser_coeff"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            ser_coeff.simulation_current_income_before_taxes
                          }
                          register={register}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="corporate_taxes">
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.borderRight}
                      >
                        <Typography variant="h9" gutterBottom display="inline">
                          <Trans i18nKey="(-) Impôt sur les société IS">
                            (-) Impôt sur les société IS
                          </Trans>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="corporate_taxes_adjsuted_imt"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={adjusted_imt.simulation_corporate_taxes}
                          register={register}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.borderRight}
                      />
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="corporate_taxes_profitability_threshold"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value="0"
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="corporate_taxes_ser_growth_turnover"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_growth_turnover.simulation_corporate_taxes}
                          register={register}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.borderRight}
                      />
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="corporate_taxes_ser_forecast"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_forecast.simulation_corporate_taxes}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="corporate_taxes_ser_coeff"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_coeff.simulation_corporate_taxes}
                          register={register}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="net_profit_after_tax">
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.borderRight}
                      >
                        <Typography variant="h9" gutterBottom display="inline">
                          <Trans i18nKey="(=) Résultat Net après Impôt">
                            (=) Résultat Net après Impôt
                          </Trans>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="net_profit_after_tax_adjsuted_imt"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={adjusted_imt.simulation_net_profit_after_tax}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="net_profit_after_tax_adjsuted_imt_percentage"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={
                            adjusted_imt.simulation_net_profit_after_tax_percentage
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="net_profit_after_tax_profitability_threshold"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value="0"
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="net_profit_after_tax_ser_growth_turnover"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            ser_growth_turnover.simulation_net_profit_after_tax
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.borderRight}
                      />
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="net_profit_after_tax_ser_forecast"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_forecast.simulation_net_profit_after_tax}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="net_profit_after_tax_ser_coeff"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_coeff.simulation_net_profit_after_tax}
                          register={register}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="cash_flow">
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.borderRight}
                      >
                        <Typography variant="h9" gutterBottom display="inline">
                          <Trans i18nKey="(=) Capacité d'Autofinancement">
                            (=) Capacité d&apos;Autofinancement
                          </Trans>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="cash_flow_adjsuted_imt"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={adjusted_imt.simulation_cash_flow}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="cash_flow_adjsuted_imt_percentage"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={adjusted_imt.simulation_cash_flow_percentage}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="cash_flow_profitability_threshold"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={profitability_threshold.simulation_cash_flow}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="cash_flow_ser_growth_turnover"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_growth_turnover.simulation_cash_flow}
                          register={register}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.borderRight}
                      />
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="cash_flow_ser_forecast"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_forecast.simulation_cash_flow}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="cash_flow_ser_coeff"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_coeff.simulation_cash_flow}
                          register={register}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="loan_repayments">
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.borderRight}
                      >
                        <Typography variant="h9" gutterBottom display="inline">
                          <Trans i18nKey="(-) Remboursements d'emprunts">
                            (-) Remboursements d&apos;emprunts
                          </Trans>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          isEdit
                          name="loan_repayments_adjsuted_imt"
                          control={control}
                          classesProps={classes.width100}
                          errors={errors}
                          suffix={t("€")}
                          value={adjusted_imt.simulation_loan_repayments}
                          onChangeField={handleChangeLoanRepaymentAdjustedImt}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="loan_repayments_adjsuted_imt_percentage"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={
                            adjusted_imt.simulation_loan_repayments_percentage
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          isEdit
                          name="loan_repayments_profitability_threshold"
                          control={control}
                          classesProps={classes.width100}
                          errors={errors}
                          suffix={t("€")}
                          value={
                            profitability_threshold.simulation_loan_repayments
                          }
                          onChangeField={
                            handleChangeLoanRepaymentProfitabilityThreshold
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          isEdit
                          name="loan_repayments_ser_growth_turnover"
                          control={control}
                          classesProps={classes.width100}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_growth_turnover.simulation_loan_repayments}
                          onChangeField={
                            handleChangeLoanRepaymentsForSerGrowthTurnover
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.borderRight}
                      />
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          isEdit
                          name="loan_repayments_ser_forecast"
                          control={control}
                          classesProps={classes.width100}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_forecast.simulation_loan_repayments}
                          onChangeField={handleChangeLoanRepaymentSerForecast}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          isEdit
                          name="loan_repayments_ser_coeff"
                          control={control}
                          classesProps={classes.width100}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_coeff.simulation_loan_repayments}
                          onChangeField={handleChangeLoanRepaymentSerCoeff}
                          register={register}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key="net_surplus">
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.borderRight}
                      >
                        <Typography variant="h9" gutterBottom display="inline">
                          <Trans i18nKey="(=) Excédent net">
                            (=) Excédent net
                          </Trans>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="net_surplus_adjsuted_imt"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={adjusted_imt.simulation_net_surplus}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="net_surplus_adjsuted_imt_percentage"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={adjusted_imt.simulation_net_surplus_percentage}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="net_surplus_profitability_threshold"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={profitability_threshold.simulation_net_surplus}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          isEdit
                          name="net_surplus_ser_growth_turnover"
                          control={control}
                          errors={errors}
                          classesProps={classes.width100}
                          suffix={t("€")}
                          value={ser_growth_turnover.simulation_net_surplus}
                          onChangeField={
                            handleChangeNetSurplusForSerGrowthTurnover
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.borderRight}
                      />
                      <TableCell align="center" className={classes.borderRight}>
                        <FieldWithSuffix
                          disabled
                          name="net_surplus_ser_forecast"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_forecast.simulation_net_surplus}
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FieldWithSuffix
                          disabled
                          name="net_surplus_ser_coeff"
                          control={control}
                          errors={errors}
                          suffix={t("€")}
                          value={ser_coeff.simulation_net_surplus}
                          register={register}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
        <NewVariableCostsRate
          setOpenModal={setOpenModal}
          openModal={openModal}
        />
      </Grid>
    </>
  );
};

export default SimulationTable;
