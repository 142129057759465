import React from "react";
/* -- Theme Material -- */
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
/* -- Views -- */
import DashboardRoute from "./components/DashboardRoute/DashboardRoute";

import maTheme from "./theme";

const MainRouter = () => {
  return (
    <StylesProvider injectFirst>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={maTheme[0]}>
          <ThemeProvider theme={maTheme[0]}>
            <DashboardRoute />
          </ThemeProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  );
};

export default MainRouter;
