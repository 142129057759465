import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Grid } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";
import { fetchRangeProducts } from "../../services/api/rangeProductAPI";

const useStyles = makeStyles(() => ({
  inputList: {
    width: "100%",
    border: "none",
    backgroundColor: "transparent",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
    borderTopLeftRadius: "0",
    borderTopRightRadius: "0",
    boxShadow: "none",
  },
  width100: {
    width: "200px",
  },
}));

const FieldPanelAutoCompleted = ({
  nameColumn,
  value,
  listObject,
  updateValue,
  onChangeField,
  tableMeta,
}) => {
  const classes = useStyles();
  const defaultProps = {
    options: listObject,
    getOptionLabel: (option) => option.name,
  };
  const [listRangeProduct, setListRangeProduct] = useState([]);
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;

  const loadListRangeProduct = async (range) => {
    const result = fetchRangeProducts(range.id, user.accessToken);
    return result;
  };
  const handleChangeAutoComplete = (event, newValue) => {
    updateValue(newValue);
    onChangeField(tableMeta, newValue);
    loadListRangeProduct(newValue).then((result) => {
      setListRangeProduct(result.results);
    });
  };
  useEffect(() => {
    if (value !== "undefined") {
      if (value) {
        if (value.id !== 0) {
          loadListRangeProduct(value).then((result) => {
            setListRangeProduct(result.results);
          });
        }
      }
    }
  }, [value]);

  return (
    <>
      {value ? (
        <div className={classes.width100}>
          {value.name ? (
            <Accordion className={classes.inputList}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                name={nameColumn}
              >
                <Typography className={classes.heading}>
                  {value.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {listRangeProduct.map((rangeProduct) => {
                    return (
                      <>
                        <Grid item xs={12} key={rangeProduct.uuid}>
                          <Typography>
                            {"- "}
                            {rangeProduct.product.name}
                          </Typography>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ) : (
            <Autocomplete
              {...defaultProps}
              className={classes.inputAutoComplete}
              id="autoComplete"
              onChange={(event, newValue) => {
                handleChangeAutoComplete(event, newValue);
              }}
              autoComplete
              value={value}
              includeInputInList
              renderInput={(params) => <TextField {...params} margin="dense" />}
            />
          )}
        </div>
      ) : (
        <Autocomplete
          {...defaultProps}
          className={classes.inputAutoComplete}
          id="autoComplete"
          onChange={(event, newValue) => {
            handleChangeAutoComplete(event, newValue);
          }}
          autoComplete
          value={value}
          includeInputInList
          renderInput={(params) => <TextField {...params} margin="dense" />}
        />
      )}
    </>
  );
};
FieldPanelAutoCompleted.propTypes = {
  nameColumn: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  listObject: PropTypes.array.isRequired,
  updateValue: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tableMeta: PropTypes.object.isRequired,
};

export default FieldPanelAutoCompleted;
