export const ADD_OFFER_RANGES_SUCCESS = "ADD_OFFER_RANGES_SUCCESS";
export const ADD_OFFER_RANGES_ERROR = "ADD_OFFER_RANGES_ERROR";
export const REGISTER_OFFER_RANGES_SUCCESS = "REGISTER_OFFER_RANGES_SUCCESS";
export const FETCH_OFFER_RANGES = "FETCH_OFFER_RANGES";
export const FETCH_OFFER_RANGES_ERROR = "FETCH_OFFER_RANGES_ERROR";
export const DELETE_OFFER_RANGES = "DELETE_OFFER_RANGES";
export const DELETE_OFFER_RANGES_ERROR = "DELETE_OFFER_RANGES_ERROR";
export const UPDATE_TOTAL_OFFER_RANGES = "UPDATE_TOTAL_OFFER_RANGES";

export const CLEAN_OFFER_RANGES = "CLEAN_OFFER_RANGES";
