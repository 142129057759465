import { OPEN_SNACKBAR, CLEAR_SNACKBAR } from "../types/snackbarActionTypes";

const initialState = {
  messageSnackbar: "",
  typeMessageSnackbar: "",
  isOpen: false,
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        messageSnackbar: action.messageSnackbar,
        typeMessageSnackbar: action.typeMessageSnackbar,
        isOpen: true,
      };
    case CLEAR_SNACKBAR:
      return {
        ...state,
        messageSnackbar: "",
        typeMessageSnackbar: "",
        isOpen: false,
      };
    default:
      return state;
  }
};

export default snackbarReducer;
