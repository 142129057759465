import { getParsedFloatNumber } from "./RangeService";

export const getListUnitGrossMargin = (RangeProducts) => {
  const listRangeProducts = [];

  RangeProducts.forEach((element) => {
    listRangeProducts.push(
      getParsedFloatNumber(element.unit_gross_margin_vat_exluded)
    );
  });

  return listRangeProducts;
};

export const getListTotalGrossMargin = (RangeProducts) => {
  const listRangeProducts = [];

  RangeProducts.forEach((element) => {
    listRangeProducts.push(element.total_unit_gross_margin_vat_exluded);
  });

  return listRangeProducts;
};

export const getListDisplayNumber = (RangeProducts) => {
  const listRangeProducts = [];

  RangeProducts.forEach((element) => {
    listRangeProducts.push(
      getParsedFloatNumber(element.number_of_days_of_presentation)
    );
  });

  return listRangeProducts;
};

export const getListTurnoverTTC = (RangeProducts) => {
  const listTurnoverTTC = [];

  RangeProducts.forEach((element) => {
    listTurnoverTTC.push(element.selling_price_vat_incl);
  });

  return listTurnoverTTC;
};

export const getListTurnoverHT = (RangeProducts) => {
  const listTurnoverHT = [];

  RangeProducts.forEach((element) => {
    listTurnoverHT.push(element.selling_price_vat_excl);
  });

  return listTurnoverHT;
};

export const getListMaterialConsumptionHT = (RangeProducts) => {
  const listMaterialConsumptionHT = [];

  RangeProducts.forEach((element) => {
    listMaterialConsumptionHT.push(element.raw_material_cost_vat_excl);
  });

  return listMaterialConsumptionHT;
};

export const getListProductPrices = (rangeProducts) => {
  const litProductPrices = [];

  rangeProducts.forEach((rangeProduct) => {
    litProductPrices.push(parseFloat(rangeProduct.selling_price_vat_incl));
  });

  return litProductPrices;
};

export const getListQuantitySold = (rangeProducts) => {
  const listQuantitySold = [];

  rangeProducts.forEach((rangeProduct) => {
    listQuantitySold.push(parseFloat(rangeProduct.quantity_sold));
  });

  return listQuantitySold;
};

export const getListPollQuantity = (rangeProducts) => {
  const listPollQuantity = [];

  rangeProducts.forEach((rangeProduct) => {
    listPollQuantity.push(parseFloat(rangeProduct.poll_quantity));
  });

  return listPollQuantity;
};

export default getListUnitGrossMargin;
