import {
  ADD_OFFER_RANGES_SUCCESS,
  ADD_OFFER_RANGES_ERROR,
  FETCH_OFFER_RANGES,
  FETCH_OFFER_RANGES_ERROR,
  DELETE_OFFER_RANGES,
  DELETE_OFFER_RANGES_ERROR,
  REGISTER_OFFER_RANGES_SUCCESS,
  UPDATE_TOTAL_OFFER_RANGES,
  CLEAN_OFFER_RANGES,
} from "../types/offerRangeActionTypes";

const initialState = {
  offerRanges: [],
  totalOfferRanges: {
    range: {
      id: 0,
      name: "Total Gamme étudiées",
      period: null,
      number_custom_by_year_number_check_total: 0,
      number_day_by_year: 0,
      sales_revenue_HT: 0,
      material_consumption_HT: 0,
      gross_margin: 0,
      range_multiplier_coefficient: 0,
      average_material_ratio: 0,
    },
  },
  loading: false,
  error: {},
};

const offerRangesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_OFFER_RANGES_SUCCESS:
      return {
        ...state,
        offerRanges: state.offerRanges.concat(action.payload),
        loading: false,
      };
    case REGISTER_OFFER_RANGES_SUCCESS:
      return {
        ...state,
        offerRanges: [...state.offerRanges, action.payload],
        loading: false,
      };
    case ADD_OFFER_RANGES_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case FETCH_OFFER_RANGES:
      return {
        ...state,
        offerRanges: action.payload,
        loading: false,
      };
    case FETCH_OFFER_RANGES_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case DELETE_OFFER_RANGES:
      return {
        ...state,
        offerRanges: action.payload,
        loading: false,
      };
    case DELETE_OFFER_RANGES_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UPDATE_TOTAL_OFFER_RANGES:
      return {
        ...state,
        totalOfferRanges: action.payload,
        loading: false,
      };
    case CLEAN_OFFER_RANGES:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default offerRangesReducer;
