import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";

import Helmet from "react-helmet";
import {
  Divider as MuiDivider,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";

import { spacing } from "@material-ui/system";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "../../services/api/apiClient";
import AddRangeDetail from "../../components/AddRange/AddRangeDetail";

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: "24px",
    marginBottom: "24px",
  },
}));

function AddRange() {
  const { t } = useTranslation();
  const classes = useStyles();
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const currentProject = useSelector((state) => state.projects.currentProject);
  const history = useHistory();
  const { handleSubmit, register, errors, control } = useForm();
  const submitData = async (data) => {
    const headers = {
      "content-type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    };

    const range = {
      name: data.name,
      project: currentProject.id,
      tva_rate: parseFloat(data.tva_rate),
      real_mode: !data.rangeForecast,
    };

    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_POPSER_API_URL}/range/`,
        data: range,
        headers,
      });
      if (res.status === 201) {
        history.push(`/range/${res.data.id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Helmet>
        <title>{t("Ajouter une Gamme")}</title>
      </Helmet>

      <Typography variant="h3" gutterBottom display="inline">
        <Trans i18nKey="add range">Créer une gamme</Trans>
      </Typography>

      <Divider my={6} mr={24} className={classes.margin} />
      <form onSubmit={handleSubmit(submitData)}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <AddRangeDetail
              register={register}
              errors={errors}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} m={5}>
          <Button type="submit" fullWidth variant="contained" color="primary">
            <Trans i18nKey="add">Créer</Trans>
          </Button>
        </Grid>
      </form>
    </>
  );
}

export default AddRange;
