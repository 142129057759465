import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA4f8oIVysp6eeENMvS6Wb1wOROPmgsEwQ",
  authDomain: "popser.firebaseapp.com",
  databaseURL: "https://popser.firebaseio.com",
  projectId: "popser",
  storageBucket: "popser.appspot.com",
  messagingSenderId: "846459697994",
  appId: "1:846459697994:web:b8a9d7287edb9c90c21fb3",
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();

export const getLogin = async (userAuth) => {
  if (userAuth == null) {
    return null;
  }
  if (!userAuth.emailVerified) {
    return null;
  }
  const token = (await userAuth.getIdToken()) || null;
  const user = auth.currentUser;
  const userProfile = {
    uid: user.uid,
    email: user.email,
    emailVerified: user.emailVerified,
    token,
  };
  return userProfile;
};

export { firebase };
