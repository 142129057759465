import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import Edit from "@material-ui/icons/Edit";

import {
  Card as MuiCard,
  CardContent,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import RangeModel from "../../models/RangeModel";
import { updateRangeAction } from "../../redux/actions/rangeActions";
import { openSnackbar } from "../../redux/actions/snackbarActions";
import useDebounce from "../../services/useDebounce";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);

const useStyles = makeStyles(() => ({
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  disableField: {
    "& td div": {
      color: "black!important",
    },
  },
}));
const RangeDetail = ({ range }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const [rangeState, setRangeState] = useState({
    name: range.name,
    tva_rate: range.tva_rate,
  });
  const debouncedRangeState = useDebounce(rangeState, 1000);

  const onSubmit = async (data) => {
    await dispatch(updateRangeAction(data, range.id, user.accessToken));
    await dispatch(openSnackbar("Gamme éditée avec succès", "success"));
  };

  useEffect(() => {
    const rangeData = { name: range.name, tva_rate: range.tva_rate };
    if (JSON.stringify(rangeData) !== JSON.stringify(debouncedRangeState)) {
      onSubmit(debouncedRangeState);
    }
  }, [debouncedRangeState]);

  const flexContainer = {
    display: "flex",
    flexDirection: "row",
  };

  useEffect(() => {
    setRangeState({
      name: range.name,
      tva_rate: range.tva_rate,
    });
  }, [range]);

  const handleTvaRateChange = (tvaRate) => {
    const parsedTvaRate = parseFloat(tvaRate);
    if (parsedTvaRate.isNaN) {
      return 0;
    }
    return parsedTvaRate;
  };

  return (
    <Card mb={6}>
      <CardContent>
        <form style={flexContainer}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                fullWidth
                id="name"
                value={rangeState.name}
                label={<Trans i18nKey="range name">Nom de la gamme</Trans>}
                name="name"
                autoFocus
                // eslint-disable-next-line prettier/prettier
                onChange={(e) => setRangeState({ ...rangeState, "name": e.target.value })}
                InputProps={{
                  endAdornment: (
                    <Edit className={classes.iconEdit} onClick={() => 0}>
                      x
                    </Edit>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                fullWidth
                value={rangeState.tva_rate}
                type="number"
                id="tva_rate"
                label={<Trans i18nKey="tva_rate">Taux de TVA(%)</Trans>}
                // eslint-disable-next-line prettier/prettier
                onChange={(e) => setRangeState({ ...rangeState, "tva_rate": handleTvaRateChange(e.target.value) })}
                name="tva_rate"
                InputProps={{
                  endAdornment: (
                    <Edit className={classes.iconEdit} onClick={() => 0}>
                      x
                    </Edit>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

RangeDetail.propTypes = {
  range: PropTypes.objectOf(RangeModel).isRequired,
};
export default RangeDetail;
