import axios from "axios";
import history from "../../history";
import { auth } from "../firebase";
import { store } from "../../redux/store";
import { openSnackbar } from "../../redux/actions/snackbarActions";

axios.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    return auth.signOut().then(() => {
      store.dispatch(
        openSnackbar(
          "Votre session a expiré, merci de vous reconnecter",
          "error"
        )
      );
      history.push("/auth/sign-in");
    });
    /*
      // Try request again with new token
      return TokenStorage.getNewToken()
        .then((token) => {
          // New request with new token
          const { config } = error;
          config.headers.Authorization = `Bearer ${token}`;

          return new Promise((resolve, reject) => {
            axios
              .request(config)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          });
        })
        .catch((error) => {
          Promise.reject(error);
        });

        */
  }
);

export default axios;
