import axios from "./apiClient";

export const addProduct = async (user, data, img, currentProject) => {
  let result;
  const headers = {
    "content-type": "multipart/form-data",
    Authorization: `Bearer ${user.accessToken}`,
  };

  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("project", currentProject.id);
  formData.append("seasonnality", data.seasonnality);
  formData.append("number_produced", data.number);

  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_POPSER_API_URL}/product/`,
      data: formData,
      headers,
    });
    if (res.status === 201) {
      result = res.data;
    }
  } catch (error) {
    result = { error };
  }
  return result;
};

export const updateProduct = async (
  user,
  data,
  img,
  productId,
  currentProject
) => {
  let result;
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${user.accessToken}`,
  };
  try {
    const res = await axios({
      method: "patch",
      url: `${process.env.REACT_APP_POPSER_API_URL}/product/${productId}`,
      data: {
        name: data.name ? data.name : undefined,
        project: currentProject.id,
        number_produced: data.number ? data.number : undefined,
        ranges: data.line ? data.line : undefined,
        seasonnality: data.seasonnality ? data.seasonnality : undefined,
        coefficient:
          data.coefficient && Number.isNaN(data.coefficient)
            ? data.coefficient
            : undefined,
        tva_rate:
          data.tva_rate && Number.isNaN(data.tva_rate)
            ? data.tva_rate
            : undefined,
        ttc_price:
          data.ttc_price && Number.isNaN(data.ttc_price)
            ? data.ttc_price
            : undefined,
        selling_price_ht:
          data.selling_price_ht && Number.isNaN(data.selling_price_ht)
            ? data.selling_price_ht
            : undefined,
        per_unit_price:
          data.per_unit_price && Number.isNaN(data.per_unit_price)
            ? data.per_unit_price
            : undefined,
        total_raw_material_cost:
          data.total_raw_material_cost &&
          Number.isNaN(data.total_raw_material_cost)
            ? data.total_raw_material_cost
            : undefined,
        sales_coefficient:
          data.sales_coefficient && Number.isNaN(data.sales_coefficient)
            ? data.sales_coefficient
            : undefined,
        tva: data.tva && Number.isNaN(data.tva) ? data.tva : undefined,
        extra_charge:
          data.extra_charge && Number.isNaN(data.extra_charge)
            ? data.extra_charge
            : undefined,
        per_unit_energy:
          data.per_unit_energy && Number.isNaN(data.per_unit_energy)
            ? data.per_unit_energysales_coefficient
            : undefined,
        raw_material_cost:
          data.raw_material_cost && Number.isNaN(data.raw_material_cost)
            ? data.raw_material_cost
            : undefined,
        production_techniques: data.production_techniques
          ? data.production_techniques
          : undefined,
      },
      headers,
    });
    if (res.status === 200) {
      result = res.data;
    }
  } catch (error) {
    result = error;
  }

  return result;
};
