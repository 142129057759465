export const FETCH_SIG = "FETCH_SIG";
export const FETCH_SIG_ERROR = "FETCH_SIG_ERROR";
export const DELETE_SIG = "DELETE_SIG";
export const DELETE_SIG_ERROR = "DELETE_SIG_ERROR";
export const UPDATE_IMT = "UPDATE_IMT";
export const UPDATE_IMT_ERROR = "UPDATE_IMT_ERROR";
export const EDITING_IMT = "EDITING_IMT";
export const FETCH_EXTERNAL_COSTS = "FETCH_EXTERNAL_COSTS";
export const ADD_EXTERNAL_COST = "ADD_EXTERNAL_COST";
export const REMOVE_EXTERNAL_COST = "REMOVE_EXTERNAL_COST";
export const FETCH_EXTERNAL_COSTS_ERROR = "FETCH_EXTERNAL_COSTS_ERROR";
export const POST_EXTERNAl_COST = "POST_EXTERNAl_COST";
export const POST_EXTERNAl_COST_ERROR = "POST_EXTERNAl_COST_ERROR";
export const DELETE_EXTERNAL_COST = "DELETE_EXTERNAL_COST";
export const DELETE_EXTERNAL_COST_ERROR = "DELETE_EXTERNAL_COST_ERROR";
export const PUT_EXTERNAL_COST = "PUT_EXTERNAL_COST";
export const PUT_EXTERNAL_COST_ERROR = "PUT_EXTERNAL_COST_ERROR";
export const UPDATE_EXTERNAL_COSTS_SUMMARY = "UPDATE_EXTERNAL_COSTS_SUMMARY";
export const UPDATE_WORKFORCE_COSTS_SUMMARY = "UPDATE_WORKFORCE_COSTS_SUMMARY";
export const FETCH_WORKFORCE_COSTS = "FETCH_WORKFORCE_COSTS";
export const FETCH_WORKFORCE_COSTS_ERROR = "FETCH_WORKFORCE_COSTS_ERROR";
export const POST_WORKFORCE_COST = "POST_WORKFORCE_COST";
export const POST_WORKFORCE_COST_ERROR = "POST_WORKFORCE_COST_ERROR";
export const DELETE_WORKFORCE_COST = "DELETE_WORKFORCE_COST";
export const DELETE_WORKFORCE_COST_ERROR = "DELETE_WORKFORCE_COST_ERROR";
export const PUT_WORKFORCE_COST = "PUT_WORKFORCE_COST";
export const PUT_WORKFORCE_COST_ERROR = "PUT_WORKFORCE_COST_ERROR";

export const CLEAN_SIG = "CLEAN_SIG";
